<template>
  <div
      :id="'alert-' + randomId"
      :class="
      $attrs.class ||
      twMerge([containerClasses, borderAccentClasses], appendClass)
    "
      ref="alertRef"
      :data-testid="props.dataTestid"
      role="alert"
  >
    <div :class="twMerge('flex items-center' , props.rootClass)" :data-testid="props.dataTestid + '-div'">
      <div
          @click="emit('onAlertIconClick', icons, alert)"
          :data-testid="props.dataTestid + '-div'"
      >
        <slot name="alert-icon" :alert="alert">
          <!-- Icons to display (optional) -->
          <TsIcon
              v-if="hasIcon"
              :name="icons"
              size="22"
              class="shrink-0 mr-1 mb-0.5"
              aria-hidden="true"
              :data-testid="props.dataTestid + '-icon'"
          />
        </slot>
      </div>
      <slot>
        <div
            :class="twMerge('font-medium leading-5',props.alertClass)"
            :data-testid="props.dataTestid + '-div'"
        >
          <slot name="alert-msg" :alert="alert">
            <!-- Screen Readers Specific -->
            <span class="sr-only">{{ variant || "Default" }} alert!</span>
            <TsTypography append-class="m-0 text-info leading-4 text-idel-black" as="h3">{{ alertMsg }}</TsTypography>
          </slot>
        </div>
      </slot>
      <!-- Dismissing Icon -->
      <button
          v-if="dismissable"
          type="button"
          class="ms-auto hover:bg-blue-100 transition-colors dark:hover:bg-natural-dark-grey rounded-md focus:ring-1 p-1.5 inline-flex items-center justify-center"
          @click.prevent="hideAlert"
          aria-label="Close"
          :data-testid="props.dataTestid + '-button'"
      >
        <!-- Screen Readers Specific -->
        <span class="sr-only">Dismiss Alert</span>
        <Icon
            name="uiw:close"
            aria-hidden="true"
            class="w-4 h-4 font-semibold"
            :data-testid="props.dataTestid + '-icon'"
        />
      </button>
    </div>
    <!-- Descriptive Alert Content -->
    <div
        v-if="$slots['alert-content'] || actionBtn || dismissBtn || props.content"
        class="mt-1.5"
        :data-testid="props.dataTestid + '-div'"
    >
      <slot name="alert-content" :alert="alert">
        <div v-html="props.content" ></div>
      </slot>
      <TsButton
          v-if="actionBtn"
          :label="actionBtnText"
          :variant="variant"
          size="xs"
          append-class="inline-block mt-1.5 me-2.5"
          :data-testid="props.dataTestid + '-tsbutton'"
          @click.prevent="emit('onViewAction', alert)"
      />
      <TsButton
          v-if="dismissBtn"
          :label="dismissBtnText"
          :variant="variant"
          size="xs"
          outlined
          append-class="inline-block mt-1.5"
          @click.prevent="hideAlert"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {twJoin, twMerge} from "tailwind-merge";
import {ref, computed, onMounted} from "vue";
import {useRandomUUID} from "../../composables/useRandomUUID";
import {useCapitalize} from "../../composables/useCapitalize";
import {
  Dismiss,
  type DismissInterface,
  type DismissOptions,
  type InstanceOptions,
} from "flowbite";

// available variants
const VARIANTS = ["info", "danger", "success", "warning"] as const;

// props
type Props = {
  variant?: (typeof VARIANTS)[number];
  alertMsg?: string;
  dismissable?: boolean;
  borderTop?: boolean;
  outlined?: boolean;
  hasIcon?: boolean;
  rootClass?: string;
  appendClass?: string;
  actionBtn?: boolean | string;
  dismissBtn?: boolean | string;
  duration?: string | number;
  transition?: string;
  dataTestid?: string;
  contentClass?: string;
  content?: string;
  borderLeft?: boolean;
  alertClass?:string;
};

const props = withDefaults(defineProps<Props>(), {
  variant: undefined,
  alertMsg: "Lorem ipsum dolor sit amet",
  dismissable: false,
  borderTop: false,
  outlined: false,
  hasIcon: false,
  rootClass: "",
  appendClass: "",
  actionBtn: false,
  dismissBtn: false,
  duration: 250,
  transition: "opacity",
  dataTestid: "molecule-alert",
});

const alert = ref<DismissInterface | null>(null);
const alertRef = ref<HTMLElement | null>(null);

// button texts
const actionBtnText = computed(() =>
    typeof props.actionBtn === "string" ? props.actionBtn : "View more"
);
const dismissBtnText = computed(() =>
    typeof props.dismissBtn === "string" ? props.dismissBtn : "Dismiss"
);

// emits
const emit = defineEmits<{
  onAlertIconClick: [icons: string, alert: DismissInterface | null];
  onViewAction: [alert: DismissInterface | null];
  onDismiss: [alert: DismissInterface | null];
}>();

function hideAlert() {
  alert.value?.hide();
}

// CSS classes
const baseClasses = computed(() =>
    twJoin(
        "text-base px-5 py-4 text-natural-grey dark:text-natural-light-grey bg-natural-soft-white dark:bg-natural-black",
        props.borderTop && !props.outlined ? "" : "rounded-md",
        props.outlined && "border border-natual-light-grey dark:border-natural-grey",
        props.borderLeft && 'font-medium bg-info/10 border-l-[8px] border-primary py-2 px-3'
    )
);

const containerClasses = computed(() =>
    twMerge(
        baseClasses.value,
        props.variant === "info" && "text-info dark:text-info-dark",
        props.variant === "danger" && "text-danger dark:text-danger-dark",
        props.variant === "success" && "text-success dark:text-success-dark",
        props.variant === "warning" && "text-warning dark:text-warning-dark"
    )
);

const borderAccentClasses = computed(() =>
    props.borderTop
        ? twJoin(
            "border-t-2",
            props.variant === "info" && "border-info dark:border-info-dark",
            props.variant === "danger" && "border-danger dark:border-danger-dark",
            props.variant === "success" &&
            "border-success dark:border-success-dark",
            props.variant === "warning" && "border-warning dark:border-warning-dark"
        )
        : ""
);

// Icons based on variants
const icons = computed<string>(() => {
  const icon = {
    info: "ic:outline-info",
    danger: "ic:outline-error-outline",
    success: "mdi:check-circle-outline",
    warning: "ion:warning-outline",
  };

  return props.variant ? icon[props.variant] : "ic:outline-info";
});

// random ID for each Vue Instance
const randomId = ref<string>("");

onMounted(() => {
  randomId.value = useRandomUUID();

  // options object
  const options: DismissOptions = {
    transition: "transition-" + props.transition,
    duration: Number(props.duration),
    timing: "ease-out",
    onHide(dismiss, targetEl) {
      emit("onDismiss", dismiss);
      alert.value?.destroy();
    },
  };

  // instance options object
  const instanceOptions: InstanceOptions = {
    id: "alert-" + randomId.value,
    override: true,
  };

  alert.value = new Dismiss(alertRef.value, null, options, instanceOptions);
});
</script>
