import { EcomService } from "~/services/ecom.service";
import type { Branch, NearestBranchRequest } from "~/types/ecom/branch.type";
import type {
  Product,
  ProductStockDetails,
} from "~/types/ecom/product/product.type";
import type {
  BranchWithStock,
  ProductStockResponse,
} from "~/types/ecom/product/stock.type";
import type { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";

export const useStockStore = defineStore("stockStore", {
  state: () => ({
    branches_with_stock_data: <BranchWithStock[]>[],
    nearby_stock_search_branch_choice: <BranchWithStock | null>null,
    stock_finder_modal_visible: false,
    is_nearby_stock_search_loading : false,
    productResponse : null
  }),
  getters: {},
  actions: {
    /* Products with stock data injected */
    async getStockForProducts(products: string[]): Promise<Product[]> {
      if (!products.length) {
        throw new Error("failed to get stock - product codes array is empty");
      }

      // Fetch product details from the products API
      const productsResponse = await this.getAllProducts(products);

      // Fetch stock data from ECOM

      const updatedProducts = await this.fetchStockProduct(products,productsResponse);


      return updatedProducts;
    },
    /* Availability evaluation */
    getProductAvailablity(
      stockDetails: Product["stockDetails"],
      isBranchSet?: boolean
    ) {
      const availability = {
        outOfStockForDelivery: false,
        outOfStockForCollection: false,
      };

      // set delivery stock status
      if (!stockDetails?.delivery) availability.outOfStockForDelivery = true;

      // set collection stock status
      if (!stockDetails?.collection && isBranchSet)
        availability.outOfStockForCollection = true;

      return availability;
    },
    /* Availability evaluation */
    async getAllProducts(products: string[]) {
      const { data: productsResponse } = await EcomService.fetchAllProducts({
        products,
      });

      return productsResponse;
    },
    /* Availability evaluation */
    async fetchStockProduct(products: string[], productsResponse: Product[]) {

      let sites: string[] = ["WW"];
      const branchStore = useBranchStore();
      const selectedBranchId = branchStore.lastSavedBranch?.id;

      // Set branch details to fetch collection stock
      if (branchStore.is_branch_set) sites.push(selectedBranchId || "");

      const { data: stockResponse } = await EcomService.fetchStockProduct({
        products,
        sites,
      });

      // Convert the stockResponse into a map for mapping to product codes
      const stockMap = stockResponse.reduce(
          (acc: any, stockItem: ProductStockResponse) => {
            const productCode = stockItem.product_code;

            // Initialize the object for the product code if it doesn't exist
            if (!acc[productCode]) {
              acc[productCode] = {
                delivery: 0,
                collection: 0,
              };
            }

            // Map delivery stock
            if (stockItem.site_id === "WW") {
              acc[productCode].delivery = parseInt(stockItem.stock_qty, 10);
            }

            // Map collection stock
            if (selectedBranchId && stockItem.site_id === selectedBranchId) {
              acc[productCode].collection = parseInt(stockItem.stock_qty, 10);
            }

            return acc;
          },
          {}
      );

      // Inject the stock data to the corresponding product in the productsResponse
      const updatedProducts = productsResponse.map((product) => {
        const stockDetails: ProductStockDetails = stockMap[product.code] || {
          delivery: 0,
          collection: 0,
        };

        const { outOfStockForDelivery, outOfStockForCollection } =
            this.getProductAvailablity(stockDetails, branchStore.is_branch_set);

        return {
          ...product,
          stockDetails,
          outOfStockForDelivery,
          outOfStockForCollection,
        };
      });

      return updatedProducts;
    },

    /* Find stock nearby feature */
    async getNearByStock(
      product_code: Product["code"],
      required_quantity: number,
      nearest_request: NearestBranchRequest
    ) {
      this.branches_with_stock_data = [];
      try {
        // get nearest branches from the current branch and filter them
        const ecomService = new EcomService;

        const response = await ecomService.fetchNearestBranches(nearest_request);
        if (!response || !response.data || !response.data.length){
          throw new Error('no nearest branch found with requested stock')
        };

        // Get all branch IDs
        const allBranchIds = response.data.map(
          (branch) => branch.id
        );

        // Fetch stock data from ECOM
        const { data: stockResponse } = await EcomService.fetchStockProduct({
          products: [product_code],
          sites: allBranchIds,
        });

        if (!stockResponse) throw new Error("Unable to fetch nearby stock");

        // Filter the response based on the required quantity
        const filteredStock = stockResponse.filter(
          (data) => parseInt(data.stock_qty, 10) >= required_quantity
        );

        // Map stock data into branches
        this.branches_with_stock_data = response.data
          .map((branch) => {
            const branchStockData = filteredStock.find(
              (stock) => stock.site_id === branch.id
            );
            if (!branchStockData) return null;
            return {
              branch,
              entered_quantity: required_quantity,
              available_stock: parseInt(branchStockData.stock_qty, 10),
            };
          })
          .filter((branch) => branch !== null); // Remove branches with no stock
      } catch (err) {
        useErrorHandler(err, "medium");
        return err;
      }
    },

    /* Decide eligibility of next day cc */
    isEligibleForNextDayCollection(product: Product) {
      const branchStore = useBranchStore();
      if (!branchStore.is_branch_set) return false;
      return (
        product.next_business_day_collection &&
        product.outOfStockForCollection &&
        !product.outOfStockForDelivery
      );
    },

    isTrolleyItemOutOfStock(options: {
      product: Product;
      channel: keyof typeof TrolleyChannel;
      quantity: number;
    }) {
      switch (options.channel) {
        case "Collection":
          return options.product.stockDetails!.collection! < options.quantity;
        default:
          return options.product.stockDetails!.delivery! < options.quantity;
      }
    },

    isProductOutOfStock(product: Product) {
      const branchStore = useBranchStore();

      return branchStore.is_branch_set
        ? product.outOfStockForCollection && product.outOfStockForDelivery
        : product.outOfStockForDelivery;
    },
  },
});

/* Pinia hot reload */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStockStore, import.meta.hot));
}
