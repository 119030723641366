export default {
  account: () => import("/vercel/path0/layouts/account.vue").then(m => m.default || m),
  app: () => import("/vercel/path0/layouts/app.vue").then(m => m.default || m),
  auth: () => import("/vercel/path0/layouts/auth.vue").then(m => m.default || m),
  "brand-builder": () => import("/vercel/path0/layouts/brandBuilder.vue").then(m => m.default || m),
  "brand-plp": () => import("/vercel/path0/layouts/brandPlp.vue").then(m => m.default || m),
  brands: () => import("/vercel/path0/layouts/brands.vue").then(m => m.default || m),
  checkout: () => import("/vercel/path0/layouts/checkout.vue").then(m => m.default || m),
  default: () => import("/vercel/path0/layouts/default.vue").then(m => m.default || m),
  home: () => import("/vercel/path0/layouts/home.vue").then(m => m.default || m),
  "home-layout": () => import("/vercel/path0/layouts/homeLayout.vue").then(m => m.default || m),
  none: () => import("/vercel/path0/layouts/none.vue").then(m => m.default || m),
  product: () => import("/vercel/path0/layouts/product.vue").then(m => m.default || m),
  public: () => import("/vercel/path0/layouts/public.vue").then(m => m.default || m),
  "trolley-new-flow": () => import("/vercel/path0/layouts/trolleyNewFlow.vue").then(m => m.default || m),
  ui: () => import("/vercel/path0/layouts/ui.vue").then(m => m.default || m)
}