import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_tMGwffvjBc from "/vercel/path0/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_BqLkNUef68 from "/vercel/path0/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_ghbUAjaD3n from "/vercel/path0/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_Jozdw60ZsE from "/vercel/path0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_client_RZVNejKJBe from "/vercel/path0/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_AUP22rrBAc from "/vercel/path0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _03_auth_1Ew6OcGSPq from "/vercel/path0/plugins/03.auth.js";
import _05_keycloak_client_nOCB4xlsxe from "/vercel/path0/plugins/05.keycloak.client.js";
import auto_reset_cache_client_s1Ca7Ksvo7 from "/vercel/path0/plugins/auto-reset-cache.client.js";
import datadog_log_client_ahAQeMWFsG from "/vercel/path0/plugins/datadog-log.client.js";
import datadog_client_4VQQkl3wSE from "/vercel/path0/plugins/datadog.client.js";
import error_handler_kEP5FliEXj from "/vercel/path0/plugins/error-handler.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_tMGwffvjBc,
  plugin_BqLkNUef68,
  i18n_yfWm7jX06p,
  plugin_ghbUAjaD3n,
  plugin_Jozdw60ZsE,
  plugin_client_RZVNejKJBe,
  plugin_AUP22rrBAc,
  _03_auth_1Ew6OcGSPq,
  _05_keycloak_client_nOCB4xlsxe,
  auto_reset_cache_client_s1Ca7Ksvo7,
  datadog_log_client_ahAQeMWFsG,
  datadog_client_4VQQkl3wSE,
  error_handler_kEP5FliEXj
]