import { acceptHMRUpdate, defineStore } from "pinia";
import { useErrorHandler } from "../composables/useErrorHandler";
import { EcomService } from "~/services/ecom.service";
import type { APIState } from "~/types/api-state.type";
import type { Categories, MenuItem } from "~/types/ecom/categories/categories.type";
import type { Brand } from "~/types/ecom/brands/brands.type";

export const useMenuStore = defineStore("menuStore", {
  state: () => ({
    show_category_dropdown: false,
    show_top_brand_modal: false,
    top_brands: <Brand>({}),
    top_brands_state: <APIState>{
      status: 'idle',
      message: 'State is idle'
    },
    categories: <Categories>({}),
    current_menu: "main-menu",
    show_mobile_menu_drawer: false,
    category: <MenuItem>({}),
    categories_state: <APIState>{
      status: 'idle',
      message: 'State is idle'
    },
    category_state: <APIState>{
      status: 'idle',
      message: 'State is idle'
    },

    current_active_sub_category: {
      category_index: <number>0,
      sub_category_index: <number>0
    }
  }),
  actions: {
    openShowTopModal() {
      this.show_top_brand_modal = true;
    },
    hideShowTopModal() {
      this.show_top_brand_modal = false;
    },
    async getTopBrands() {
      const builderStore = useBuilderStore();
      try {
        if(builderStore.topBrands.length === 0) {
          builderStore.fetchTopBrands();
        }

      } catch (err) {
        await useErrorHandler(err);
      }
    },
    parseData(response: any) {
      let filteredData: any = [];
      let data = Object.entries(response.meta).filter((curr) => {
        return typeof (curr[1]) !== "string"
      });

      Object.entries(data).filter((curr) => {
        filteredData.push(curr[1][1]);
      });


      this.top_brands = filteredData;
    },
    async getCategories(cache = false) {
      try {
        useStateModifier(this.categories_state, 'loading', 'Fetching Categories');
        const response: Categories = await EcomService.getCategoriesData(cache);
        if (response.success === false || response.data.length <= 0) {
          useStateModifier(this.categories_state, 'failed', 'Fetching Failed');
          return response;
        }

        useStateModifier(this.categories_state, 'success', 'Fetching Complete');
        // this.categories = response.data;
        this.categories = response;
        return response;
      } catch (err) {
        useStateModifier(this.categories_state, 'failed', 'Fetching Failed');
        await useErrorHandler(err);
      }
    },

    async getCategoryById(taxonomyId: string) {
      try {
        useStateModifier(this.category_state, 'loading', 'Fetching Categories');

        const response = await EcomService.getCategoryDetailsById(taxonomyId);
        if (!response.data) {
          useStateModifier(this.category_state, 'failed', 'Fetching Failed');
          setTimeout(() => {
            this.getCategoryById(taxonomyId);
          }, 3000);

          return response;
        }
        useStateModifier(this.category_state, 'success', 'Fetching Complete');

        this.category = response.data;

        return response;

      } catch (err) {
        useStateModifier(this.category_state, 'failed', 'Fetching Failed');
        await useErrorHandler(err);
      }
    },
  },

});

// Pinia hot reload
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMenuStore, import.meta.hot));
}
