<template>
  <div :data-testid="dataTestid" class="bg-white">
    <form
      :data-testid="dataTestid + '_select-branch-form'"
      class="flex flex-col"
      @submit.prevent="handleSave"
    >
      <div>
        <TsRow append-class="m-0 py-2 items-center gap-4 px-4">
          <NuxtImg src="/images/logos/store.svg" width="48" height="36" />

          <TsTypography append-class="mb-0" variant="h4">
            {{
              isStockNearby
                ? useTranslation("findStock", "Vind voorraad in de buurt")
                : useTranslation(
                    "setYourNearestBranch",
                    "Stel uw dichtstbijzijnde vestiging in"
                  )
            }}
          </TsTypography>

          <TsIcon
            @click="emit('close')"
            name="bitcoin-icons:cross-filled"
            class="absolute top-3 right-3 text-idle-black hover:bg-info/10 rounded transition-colors cursor-pointer h-6 w-6"
          />
        </TsRow>

        <TsDivider append-class="w-full p-0 mb-3 mt-0" />

        <TsColumn append-class="p-0 mb-4 px-4">
          <TsAutoCompleteLocality
            data-testid="locality-autocomplete"
            :placeholder="useTranslation('enterPostalCodeOrCityName', 'Enter postal code or city name')"
            search-label="description"
            v-model:input="branchStore.localitySearchInput"
            v-model:selected="branchStore.selectedLocality"
            @input="branchStore.delayedAutoCompleteSearch()"
            @select="handleSuggestionSelect"
            @enter="branchStore.getPostcodeBasedResults()"
            :suggestions="branchStore.autoCompleteSuggestions"
            :loading="branchStore.isProcessingLocalitySearch"
            :input-class="props.inputClass"
            :input-variant="
              branchStore.noSuggestionsAvailable ||
              branchStore.noNearestBranchAvailable
                ? 'danger'
                : 'default'
            "
            @clear="branchStore.autoCompleteSuggestions = []"
            autofocus
          />
          <!-- Suggestions Empty -->
          <TsTypography
            v-if="branchStore.noSuggestionsAvailable"
            append-class="text-danger ml-4 mt-0.5 text-xs"
          >
            {{
              useTranslation(
                "addressNotFound",
                "No such locality or address found."
              )
            }}
          </TsTypography>
        </TsColumn>

        <TsRow append-class="m-0 justify-between px-4">
          <TsTag
            :data-testid="dataTestid + '-current'"
            append-class="max-md:text-sm max-md:px-2 text-primary border-0 font-normal gap-1 justify-center bg-info/10 hover:bg-info/15 transition-colors rounded-md cursor-pointer"
            @click="onUseCurrentLocation"
          >
            <TsIcon name="fluent:location-28-regular" size="20" />
            {{ useTranslation("currentLocation", "Use my current location") }}
          </TsTag>

          <slot name="view">
            <TsButton
              outlined
              append-class="max-sm:px-1 cursor-pointer py-1.5 md:px-2"
              size="sm"
              :data-testid="
                dataTestid ? dataTestid + '_view-button' : '_view-button'
              "
              :disabled="
                branchStore.isProcessingLocalitySearch ||
                branchStore.isProcessingNearestBranchSearch
              "
              @click.prevent="changeView"
            >
              <TsIcon
                :name="
                  listViewOn
                    ? 'material-symbols-light:map-sharp'
                    : 'ic:round-list'
                "
                size="24"
              />
              <span>{{
                listViewOn
                  ? useTranslation("mapView", "Map view")
                  : useTranslation("listView", "List view")
              }}</span>
            </TsButton>
          </slot>
        </TsRow>
      </div>
      <!-- location permission blocked message -->
      <TsRow append-class="m-0" v-if="branchStore.locationBlockMessage">
        <TsNuxtCard
          appendClass="shadow-none border-none bg-warning/20 rounded-md mt-3 px-2 py-1.5 flex before:rounded-full before:mr-2 before:content-[''] before:block before:w-2 before:h-full before:bg-danger"
        >
          <div class="hidden md:block">
            <TsTypography append-class="text-danger">
              {{
                useTranslation(
                  "locationNotAvailable",
                  "Location not available. Please ensure location services are enabled on this device and that you have granted permission to use your location."
                )
              }}
            </TsTypography>
            <TsTypography append-class="text-danger mt-2">
              {{
                useTranslation(
                  "alternativelyEnterPostCode",
                  "Alternatively, please enter your postcode to search for your nearest store."
                )
              }}
            </TsTypography>
          </div>
          <div class="block md:hidden">
            <TsTypography append-class="text-danger leading-5">
              {{
                useTranslation(
                  "locationNotFound",
                  "Location not found. Please enable location services and grant permission."
                )
              }}
            </TsTypography>
            <TsTypography append-class="text-danger mt-2 leading-5">
              {{
                useTranslation(
                  "enterYourPostCode",
                  "Or, enter your postcode to find the nearest store."
                )
              }}
            </TsTypography>
          </div>
        </TsNuxtCard>
      </TsRow>

      <slot>
        <div role="list">
          <template v-if="branchStore.noResultState">
            <template v-if="branchStore.noNearestBranchAvailable">
              <NuxtImg
                src="/images/branch-not-found.png"
                alt="branch selector card result not found image"
                width="225"
                height="225"
                class="rounded-full max-w-[225px] mt-10 mx-auto px-4"
              />
              <TsTypography as="h2" append-class="text-xl my-8 text-center"
                >No nearest branch available. Try again
              </TsTypography>
            </template>

            <!-- List / Map view toggle button -->
            <template v-else>
              <NuxtImg
                v-if="listViewOn"
                src="/images/setBranchModal1.png"
                alt="branch selector card default image in list view"
                class="w-full mt-4 px-4"
              />

              <NuxtImg
                v-else
                src="/images/setBranchModal2.png"
                alt="branch selector card default image in map view"
                class="w-full mt-4 px-4"
              />

              <TsTypography
                align="center"
                append-class="text-base font-semibold lg:text-xl text-idle-black leading-6 mt-2 mb-6"
              >
                {{
                  useTranslation(
                    "storeDetailMessage",
                    "Which store are you shopping from today?"
                  )
                }}
              </TsTypography>
            </template>
          </template>

          <!----------------------- Loading state card------------------------------------------->
          <template v-else-if="branchStore.isProcessingNearestBranchSearch">
            <div role="status" class="space-y-8 px-4">
              <TsBranchTransition v-for="n in 3" :key="n" />
            </div>
          </template>

          <!-- Listing -->
          <template v-else>
            <div v-if="listViewOn">
              <TsTypography append-class="text-idle-black leading-5 mt-2 !px-4">
                {{ useTranslation("searchedResults", "Searched Results") }}
                ({{ branchStore.nearestBranches.length }})
              </TsTypography>

              <TsColumn
                append-class="overflow-y-auto p-0 pb-14 px-4"
                style="max-height: calc(100vh - 310px)"
              >
                <TsNuxtCard
                  v-for="branch in branchStore.nearestBranches"
                  :key="branch.name"
                  appendClass="shadow-none border-none bg-natural-soft-white rounded-md mt-3 cursor-pointer"
                >
                  <!-- <TsRow append-class="m-0 justify-between"> -->
                  <TsMedia>
                    <TsMediaContent :data-testid="dataTestid + '-li'">
                      <TsRadio
                        :data-testid="dataTestid + '-span'"
                        append-class="items-start"
                        v-model="branchStore.currentBranchChoice"
                        :value="branch"
                        labelClass="font-semibold cursor-pointer"
                      >
                        <template #label>
                          <TsTypography
                            as="h4"
                            append-class="text-idle-black mb-1 leading-tight"
                            >{{ branch.name }}</TsTypography
                          >
                          <TsRow append-class="m-0">
                            <TsTypography
                              :data-testid="dataTestid + '-para'"
                              as="h5"
                              append-class="text-natural-grey font-normal"
                              >{{ branch.address_text }}
                            </TsTypography>
                          </TsRow>
                          <TsRow append-class="m-0 gap-8 mt-2">
                            <TsTypography as="h5" append-class="text-natural-grey font-normal">
                              <TsIcon :data-testid="dataTestid + '-para'" size="17" class="mb-1" name="ic:outline-watch-later" /> {{useTranslation('openAt7','Open om 7 uur')}}
                            </TsTypography>
                            <TsTypography as="h5" append-class="text-natural-grey font-normal">
                              <TsIcon :data-testid="dataTestid + '-para'" size="17" class="mb-1" name="fluent:location-28-regular" /> {{ branch.distance?.toFixed(1) }} miles
                            </TsTypography>
                          </TsRow>
                        </template>
                      </TsRadio>
                      </TsMediaContent>
                      <TsMediaEnd @click="emit('close')">
                        <NuxtLinkWithLocale :to="`/branches/${branch.id}`" :data-testid="dataTestid + '-link'"
                          class="cursor-pointer text-primary font-semibold hover:underline">
                          {{ useTranslation('viewDetails', 'View details') }}
                          <TsIcon size="20" class="mb-1" name="mingcute:right-fill" />
                        </NuxtLinkWithLocale>
                      </TsMediaEnd>
                    </TsMedia>
                  <!-- </TsRow> -->
                </TsNuxtCard>
              </TsColumn>
            </div>

            <!-- Nearby Branches Map View -->
            <template v-else>
              <TsAllStoresMap
                data-testid="branch-selector"
                :stores="branchStore.nearestBranches"
                @save-store="(branch) => branchStore.onSaveBranch(branch)"
                append-class="my-4 h-[380px] lg:h-[380px]"
              />
            </template>
          </template>
        </div>

        <slot name="footer">
          <TsRow
            v-if="
              branchStore.nearestBranches.length &&
              !branchStore.isProcessingNearestBranchSearch &&
              listViewOn
            "
            append-class="m-0 lg:justify-center gap-2 lg:gap-6 py-4 flex absolute bottom-0 bg-white w-full"
          >
            <TsCheckbox
              v-if="authStore.is_authenticated"
              v-model="branchStore.isDefaultBranchSelected"
              :label="useTranslation('setAsDefaultBranch', 'Set as default branch')"
              :disabled="!branchStore.currentBranchChoice"
              id="default-selection"
              data-testid="default-selection"
            />
            <TsButton
              append-class="px-14 py-2 lg:px-24"
              type="submit"
              :label="useTranslation('save', 'Save')"
              size="lg"
              :disabled="!branchStore.currentBranchChoice"
              data-testid="branch-save"
            />
          </TsRow>
        </slot>
      </slot>
    </form>
  </div>
</template>

<script setup lang="ts">
import type { Branch } from "~/types/ecom/branch.type";
import type { WoosmapLocalitySuggestion } from "~/types/woosmap/locality.type";

//--------------------- PROPS
type Props = {
  dataTestid?: string;
  inputClass?: string;
  appendClass?: string;
  isStockNearby?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  inputClass: "w-full",
  appendClass: "",
  isStockNearby: false,
});

const emit = defineEmits<{
  save: any;
  close: any;
  select: [selectedLocality: WoosmapLocalitySuggestion | null];
  selectCurrentLocation: any;
}>();

//--------------------- STATES
const branchStore = useBranchStore();
const authStore = useAuthStore();
const listViewOn = ref(true);

//--------------------- METHODS
const changeView = () => {
  listViewOn.value = !listViewOn.value;
};

const handleSuggestionSelect = () => {
  branchStore.onSelectLocality();
  emit("select", branchStore.selectedLocality);
};

const onUseCurrentLocation = () => {
  branchStore.getNearestBranchByCurrentLocation();
  emit("selectCurrentLocation");
};

const handleSave = () => {
  emit("save", branchStore.currentBranchChoice as Branch);
  branchStore.onSaveBranch(branchStore.currentBranchChoice as Branch);
};
</script>
