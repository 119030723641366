<script setup lang="ts">
import {twMerge} from "tailwind-merge";
import {withDefaults, defineModel} from 'vue';
import {computed} from 'vue';

// Variant
const Variants = ["h1", "h2", "h3", "h4", "h5", "h6", "ul", "ol", "li", "md", "base", "sm", "xs", "xxs", "NuxtLink"] as const;

// Font Weights
const Weights = ["thin", "extralight", "normal", "semibold", "bold", "extrabold"] as const;

// NuxtLink Props Types
type NuxtLinkProps = {
  to?: string;
}

// All Props Types
type Props = NuxtLinkProps & {
  variant?: (typeof Variants)[number];
  as?: string;
  appendClass?: string;
  tsTitle?: boolean;
  text?: string;
  weight?: (typeof Weights)[number];
  size?: "md" | "base" | "sm" | "xs" | "xxs";
  align?: 'left' | 'center' | 'right' | 'justify';
  removeMargin?: boolean;
};

// All props
const props = withDefaults(defineProps<Props>(), {
  as: "p",
});

// Devices
const {isMobile, isTablet, isDesktop} = useDevice();
const device = ref("");

const is_heading = computed(() => {
  const heading_tags = ["h1", "h2", "h3", "h4", "h5", "h6"];

  return heading_tags.includes(props.as?.toLowerCase()) || heading_tags.includes(props.variant?.toLowerCase());
});

const is_variant = computed(() => {
  return Variants.includes(props.as?.toLowerCase());
})

if(isMobile) {
  device.value = "mobile";
} else if(isTablet) {
  device.value = "tablet";
} else {
  device.value = "desktop";
}

const custom_tag = computed(() => {
  if(is_heading.value) {
    return props.as?.toLowerCase();
  }

  // Allowed Tags
  const allowed_tags = ["small", "kbd", "span", "div", "label", "b", "strong", "i", "u", "ol", "ul", "li", "a", "em"];

  if(props.as === "NuxtLink") {
    return resolveComponent("NuxtLink");
  }

  return allowed_tags.includes(props.as?.toLowerCase()) ? props.as.toLowerCase() : 'p';
});

const responsive_classes = {
  mobile: {
    h1: "text-[24px] leading-[36px] mb-3",
    h2: "text-[20px] leading-[30px] mb-4",
    h3: "text-[18px] leading-[24px] mb-2",
    h4: "text-[16px] leading-[20px] mb-3",
    h5: "text-[14px] leading-[18px]",
    h6: "text-[12px] leading-[14px]",
    ol: "list-decimal ml-8  mb-6",
    ul: "list-disc ml-8  mb-6",
    li: "text-[16px] leading-[24px] mb-3",
    md: "text-[18px] leading-[28px]",
    base: "text-[16px] leading-[24px]",
    sm: "text-[14px] leading-[20px]",
    xs: "text-[12px] leading-[18px]",
    xxs: "text-[10px] leading-[14px]"
  },
  tablet: {
    h1: "text-[24px] leading-[36px] mb-3",
    h2: "text-[20px] leading-[30px] mb-4",
    h3: "text-[18px] leading-[24px] mb-2",
    h4: "text-[16px] leading-[20px] mb-3",
    h5: "text-[14px] leading-[18px]",
    h6: "text-[12px] leading-[14px]",
    ol: "list-decimal ml-8  mb-6",
    ul: "list-disc ml-8  mb-6",
    li: "text-[16px] leading-[24px] mb-3",
    md: "text-[18px] leading-[28px]",
    base: "text-[16px] leading-[24px]",
    sm: "text-[14px] leading-[20px]",
    xs: "text-[12px] leading-[18px]",
    xxs: "text-[10px] leading-[14px]"
  },
  desktop: {
    h1: "text-[28px] leading-[42px] mb-3",
    h2: "text-[24px] leading-[36px] mb-4",
    h3: "text-[20px] leading-[30px] mb-2",
    h4: "text-[18px] leading-[26px] mb-3",
    h5: "text-[16px] leading-[24px]",
    h6: "text-[14px] leading-[20px]",
    ol: "list-decimal ml-8  mb-6",
    ul: "list-disc ml-8  mb-6",
    li: "text-[16px] leading-[24px] mb-3",
    md: "text-[18px] leading-[28px]",
    base: "text-[16px] leading-[24px]",
    sm: "text-[14px] leading-[20px]",
    xs: "text-[12px] leading-[18px]",
    xxs: "text-[10px] leading-[14px]"
  }
};

const font_weights = {
  thin: "font-thin",
  extralight: "font-extralight",
  normal: "font-normal",
  semibold: "font-semibold",
  bold: "font-bold",
  extrabold: "font-extrabold",
}

const text_aligns = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
  justify: "text-justify"
}

const heading_classes = computed(() => {
  return `font-semibold text-natural-black ${is_heading.value ? (responsive_classes[device.value][props.variant] || responsive_classes[device.value][props.as]) : ''}`
});
const base_classes = computed(() => {
  return `font-normal text-natural-grey ${responsive_classes[device.value]["base"]}`
});

const typo_classes = computed(() => {
  return twMerge(
      is_heading.value ? heading_classes.value : base_classes.value,
      is_variant && responsive_classes[device.value][`${props.as}`],
      is_variant &&  responsive_classes[device.value][`${props.variant}`],
      responsive_classes[device.value][`${props.size}`],
      font_weights[props.weight],
      text_aligns[props.align],
      props.tsTitle && 'ts-title relative pl-3',
      props.removeMargin && 'm-0',
      props.appendClass
  );
})

</script>

<template>
  <component
      :is="custom_tag"
      :class="typo_classes"
      :to="props.to"
  >
    <slot>
      <ClientOnly>
        <div v-html="props.text"></div>
      </ClientOnly>
    </slot>
  </component>
</template>
