<template>
  <div
      :class="
      props.class || [
        twMerge(
          root_classes,
          focused && variant_classes.primary,
          !disabled && variant_classes[props.variant],
          props.appendClass
        ),
      ]
    "
  >
    <slot name="left"/>
    <div
        :class="[
        'flex-grow flex items-center gap-2',
        iconPosition === IconPositions.Left && 'flex-row-reverse'
      ]"
    >
      <div class="flex-grow flex items-center">
        <input
            :type="inputType"
            :id="props.id || `input-${random_id}`"
            v-bind="$attrs"
            v-model="model"
            @focus="handleFocus($event)"
            @blur="handleBlur($event)"
            @input="handleInput($event)"
            @paste="handlePaste($event)"
            @keydown="handleKeyDown($event)"
            :class="[twMerge(input_classes, props.inputClass)]"
            :data-testid="props.dataTestid"
        />

        <ClientOnly>
        <Icon
            v-if="props.withClear && model && model.length > 0"
            :class="
            twMerge(
              icon_classes,
              'cursor-pointer',
              focused && 'text-natural-grey dark:text-natural-light-grey',
              props.variant === Variants.Danger && 'text-danger dark:text-danger-dark',
              props.variant === Variants.Success && 'text-success dark:text-success-dark'
            )
          "
            @click="handleClear"
            name="ic:round-clear"
        />

        <Icon
            v-if="props.type === 'password'"
            data-testid="password-hide-button"
            :class="
            twMerge(
              icon_classes,
              'cursor-pointer',
              focused && 'text-natural-grey dark:text-natural-light-grey'
            )"
            @click="
            () => {
              inputType = inputType === 'password' ? 'text' : 'password';
              togglePasswordVisibility();
            }
          "
            :name="password_toggle_icon"
        />
        </ClientOnly>
      </div>

      <slot name="icon" :icon="props.icon" :variant="props.variant" :iconClasses="icon_classes" :focused="focused">
        <ClientOnly>
        <Icon
            v-if="props.icon"
            :class="
          twMerge(
            icon_classes,
            focused && 'text-natural-grey dark:text-natural-light-grey',
            props.variant === Variants.Danger && 'text-danger dark:text-danger-dark',
            props.variant === Variants.Success && 'text-success dark:text-success-dark',
            props.iconClass
          )
        "
            :name="props.icon"
            @click="handleIconClick"
        />
        </ClientOnly>
      </slot>
    </div>
    <slot name="right"/>
  </div>
</template>
<script setup lang="ts">
import {twMerge} from "tailwind-merge";
import type {ClassNameValue} from "tailwind-merge";
import {ref, computed, onMounted} from 'vue';
import {useAttrs} from '@vue/runtime-core';
import {useRandomUUID} from "../../composables/useRandomUUID";
// Props
type Props = {
  id?: string;
  type?: "text" | "password" | "email" | "number";
  modelValue?: string;
  class?: ClassNameValue;
  appendClass?: ClassNameValue;
  inputClass?: ClassNameValue;
  variant?: "default" | "primary" | "danger" | "success";
  size?: "xs" | "sm" | "md" | "lg";
  icon?: string;
  iconPosition?: "left" | "right";
  iconClass?: string;
  withClear?: boolean;
  withBackground?: boolean;
  underlined?: boolean;
  rounded?: boolean;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {
  type: "text",
  variant: "default",
  size: "md",
  dataTestid: 'atom-input'
});

// Variables definition
const [model] = defineModel<string>();
const focused = ref(false);
const random_id = ref("");
const attrs = useAttrs();
const inputType = ref(props.type);
const disabled = computed(() => {
  return (
      attrs.disabled === "" ||
      attrs.disabled === true ||
      attrs.disabled === "true"
  );
});

// manual attribute binding
defineOptions({
  inheritAttrs: false,
});

// Enums
enum Sizes {
  Xs = "xs",
  Sm = "sm",
  Base = "md",
  Lg = "lg",
}

enum IconPositions {
  Left = "left",
}

enum Variants {
  Primary = "primary",
  Danger = "danger",
  Success = "success",
}

let root_classes: ClassNameValue = [
  "flex items-center w-full gap-1 overflow-hidden font-medium border border-natural-light-grey dark:border-natural-grey min-w-max",
  props.withBackground && "bg-natural-soft-white dark:bg-natural-dark-grey",
  props.size === Sizes.Xs && "px-3 rounded",
  props.size === Sizes.Sm && "px-3 rounded-md",
  props.size === Sizes.Base && "px-3 rounded-lg",
  props.size === Sizes.Lg && "px-3 rounded-xl",
  disabled.value && "cursor-not-allowed",
  disabled.value && props.underlined && props.withBackground && "border-0",
  props.underlined &&
  !props.withBackground &&
  "border-0 border-b-2 px-0 rounded-none",
  props.underlined && props.withBackground && "border-0 border-b-2",
  props.rounded && '!rounded-full',
];

const variant_classes: Record<string, ClassNameValue> = {
  primary: "border-info dark:border-info-dark",
  success: "border-success dark:border-success-dark",
  danger: "border-danger dark:border-danger-dark",
};

let input_classes: ClassNameValue = [
  "m-0 p-0 flex-grow peer text-idle-black dark:text-idle-white border-0 focus:ring-0 bg-transparent font-medium placeholder:font-medium placeholder:text-natural-grey dark:placeholder:text-natural-silver-grey",
  props.size === Sizes.Xs && "py-2 text-xs placeholder:text-xs leading-3",
  props.size === Sizes.Sm && "py-2.5 text-sm placeholder:text-sm leading-5",
  props.size === Sizes.Base && "py-2.5 text-base placeholder:text-base leading-6",
  props.size === Sizes.Lg && "py-3 text-lg placeholder:text-lg leading-[26px]",
  disabled.value &&
  "cursor-not-allowed text-natural-silver-gray dark:text-natural-grey",
];

let icon_classes: ClassNameValue = [
  "text-natural-silver-grey",
  props.size === Sizes.Xs && "h-4 w-4",
  props.size === Sizes.Sm && "h-5 w-5",
  props.size === Sizes.Base && "h-6 w-6",
  props.size === Sizes.Lg && "h-7 w-7",
];

if (attrs.autofocus) {
  focused.value = true;
}

//------------ Password -----------

enum PasswordToggleIcons {
  Hide = "mdi:eye-off-outline",
  Show = "iconamoon:eye-light",
}

const password_toggle_icon = ref(PasswordToggleIcons.Hide);

// Methods
const togglePasswordVisibility = () => {
  if (inputType.value === "password") {
    password_toggle_icon.value = PasswordToggleIcons.Hide;
  } else if (inputType.value === "text") {
    password_toggle_icon.value = PasswordToggleIcons.Show;
  }
};
const emit = defineEmits<{
  focus: [e: Event, isFocused: boolean];
  blur: [e: Event];
  input: [e: Event];
  paste: [e: Event];
  keydown: [e: Event];
  iconClick: [e: Event];
  clear: any
}>();
const handleFocus = (e) => {
  focused.value = true;
  const isFocused = focused.value;

  emit("focus", e, isFocused);
};
const handleBlur = (e) => {
  focused.value = false;
  emit("blur", e);
};

const handleInput = (e) => {
  emit("input", e);
}

const handlePaste = (e) => {
  emit("paste", e);
}

const handleKeyDown = (e) => {
  emit("keydown", e);
}
const handleIconClick = (e) => {
  emit("iconClick", e);
}

const handleClear = () => {
  model.value = ""
  emit('clear')
}

onMounted(() => {
  random_id.value = useRandomUUID();
});
</script>
