<template>
  <ClientOnly>
    <Icon
      :data-testid="props.dataTestid"
      :name="name"
      :color="color"
      :size="size"
      v-bind="$attrs"
      :class="$attrs.class"
    />
  </ClientOnly>
</template>

<script setup lang="ts">
defineOptions({
  inheritAttrs: true,
});

type Props = {
  name?: string;
  color?: string;
  size?: string;
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {
  name: "uil:github",
  size: "2rem",
  dataTestid: "atom-icon",
});
</script>
