<template>
  <footer :id="random_id" :data-testid="props.dataTestid">
    <!--    <TsNewsletter-->
    <!--        v-model:email="newsletter_email"-->
    <!--        :heading="useTranslation('footerNewsletterHeading')"-->
    <!--        :description="useTranslation('footerNewsletterDesc')"-->
    <!--        :placeholder="useTranslation('footerNewsletterPlaceholder')"-->
    <!--        append-class="md:hidden py-6 px-4"-->
    <!--    />-->

    <ClientOnly>
      <TsNewsletter v-if="useRootStore().isAppHydrated" v-model:email="newsletter_email"
        :heading="useTranslation('newsLetterHeading', 'Altijd op de hoogte van onze acties')"
        :description="useTranslation('newsletterDescription', 'Schrijf je in voor de nieuwsbrief en mis nooit meer iets. Ontvang direct 10% korting na registratie.')"
        placeholder="Email" append-class="md:hidden py-6 px-4" />
    </ClientOnly>
    <div class="mx-auto max-w-screen-xl px-4 py-6 max-md:bg-[#F0F8FF]">
      <TsRow class="max-lg:flex-col justify-between lg:bg-white lg:rounded-md lg:border lg:border-[#DCEDFD] lg:my-4">
        <TsColumn dataTestid="column-cta">
          <TsCardCta data-testid="footer-cta"
            :content="useTranslation('downloadApp', 'Download de app en ontvang 5% KORTING')" append-class="md:py-2" />
        </TsColumn>

        <TsColumn v-if="isDesktop" class="grow-0" dataTestid="column-divider">
          <TsDivider layout="vertical" />
        </TsColumn>

        <TsColumn class="max-md:mt-2 flex items-center justify-center" dataTestid="column-secure-payment">
          <TsRow class="items-center max-sm:flex-col mx-0 my-auto">
            <TsTypography as="p" variant="base" data-testid="footer-para" class="max-sm:mb-3 max-sm:mr-0 mr-3 sm:mr-4"
              append-class="mb-0 shrink font-bold text-idle-black">
              {{ useTranslation("securePayment", "Veilige betaling") }}
            </TsTypography>

            <TsRow class="justify-start lg:justify-center gap-2 sm:gap-3.5 mx-0 overflow-auto no-scrollbar" isMobile>
              <template v-for="option in payment_options">
                <NuxtImg v-if="option && option.image" :key="option.alt" class="h-6 max-w-full" :src="option.image"
                  :alt="option.alt" :width="option.width" :height="option.height" />
              </template>

            </TsRow>
          </TsRow>
        </TsColumn>
      </TsRow>


    </div>

    <!-- Mobile -->
    <div class="md:hidden bg-primary py-6 px-4">
      <NuxtLink :to="localePath('/')" class="block max-w-max mx-auto">
        <NuxtImg
            src="https://cdn.toolstation.com/assets/toolstation-logo-halo.svg"
            alt="Toolstation"
            class="w-56 mx-auto mb-6 mt-2 cursor-pointer"
            width="224"
            height="55"
        />
      </NuxtLink>
      <div class="mb-8">
        <TsAccordion dataTestid="column-accordion" append-class="mb-0">
          <TsAccordionTab v-for="(tab, index) in footer_accordion_tabs.navigationTabs" :key="tab.heading"
            :heading="useTranslation(tab.heading, tab.heading)"
            :data-testid="`mobile-${props.dataTestId}-navigation-tab-${index}`"
            appendClass="w-full px-0 !text-idle-white border-0 border-t-0 border-b border-b-info first:border-t-0 hover:bg-transparent"
            headingClass="text-sm font-medium px-3 border-b-0">
            <ul v-if="tab.links.length > 0" class="px-4">
              <li v-for="(item, i) in tab.links" :key="item" class="py-2 text-natural-soft-white text-sm font-normal">
                <NuxtLink :to="item.link" :data-testid="'mobile-' + props.dataTestId + '-link-' + i" class="w-full">
                  {{ useTranslation(`${item.key}`, `${item.label}`) }}
                </NuxtLink>
              </li>
            </ul>
          </TsAccordionTab>
        </TsAccordion>
        <NuxtLink to="/blogs-news-advices" class="font-medium text-sm block py-4 text-white pl-3">
          {{ useTranslation("adviceBlogsNews", "Advies/Blogs/Nieuws") }}
        </NuxtLink>
        <NuxtLink to="/account/catalogue" class="font-medium text-sm block py-4 border-y border-info text-white pl-3">
          {{ useTranslation("requestCatalogue", "Catalogus aanvragen") }}
        </NuxtLink>
      </div>
      <TsTypography align="center" class="!text-idle-white text-lg mb-1">
        {{ useTranslation("inLoop", "Op de hoogte blijven?") }}
      </TsTypography>

      <TsRow append-class="justify-center gap-3 mt-1.5 !mx-0" isMobile>
        <NuxtLink v-for="(item, index) in social_links.mobile" target="_blank" :key="item" :to="item.link"
          :data-testid="'mobile-' + props.dataTestId + '-social-link-' + index">
          <NuxtImg class="size-12" v-if="item && item.image" :src="item.image" :alt="item.name" />
        </NuxtLink>
      </TsRow>
    </div>

    <!-- Desktop -->
    <div class="bg-primary px-4">
      <TsRow class="max-md:hidden py-8 mx-auto max-w-screen-xl min-h-max" isMobile>
        <TsColumn class="is-5 min-w-min" dataTestid="column-left">
          <NuxtLink :to="localePath('/')" class="block max-w-max cursor-pointer">
            <NuxtImg
                class="w-56 cursor-pointer"
                src="/images/logos/toolstation-logo-halo.svg"
                alt="Tool Box"
                width="224"
                height="55"
            />
          </NuxtLink>
          <TsNewsletter v-model:email="newsletter_email"
            :heading="useTranslation('newsLetterHeading', 'Altijd op de hoogte van onze acties')"
            :description="useTranslation('newsletterDescription', 'Schrijf je in voor de nieuwsbrief en mis nooit meer iets. Ontvang direct 10% korting na registratie.')"
            append-class="py-6 max-sm:px-4" placeholder="Email" />

          <TsTypography append-class="text-center sm:text-left mt-2 mb-2 text-idle-white">
            {{ useTranslation("inLoop", "Op de hoogte blijven?") }}
          </TsTypography>

          <TsRow append-class="max-sm:justify-center gap-2.5 mt-2 mx-0" isMobile>
            <NuxtLink v-for="(item, index) in social_links.mobile" target="_blank" :key="item" :to="item.link"
              :data-testid="'desktop-' + props.dataTestId + '-social-link-' + index
                ">
              <NuxtImg class="size-10" v-if="item && item.image" :src="item.image" :alt="item.name" />
            </NuxtLink>
          </TsRow>
        </TsColumn>

        <TsColumn class="grow-0" dataTestid="column-divider">
          <TsDivider v-if="isDesktop || isTablet" layout="vertical" append-class="before:border-[#4191DB]" />
        </TsColumn>

        <TsColumn dataTestid="column-right">
          <TsRow class="flex-row" isMultiline>
            <TsColumn dataTestid="column-one">
              <TsTypography append-class="font-bold !mb-4 !text-idle-white">
                <span v-html="useTranslation('Help & Contact', 'Hulp & Contact')"></span>
              </TsTypography>
              <ul v-if="
                footer_accordion_tabs.navigationTabs.helpAndContact.links
                  .length > 0
              ">
                <TsTypography v-for="(item, index) in footer_accordion_tabs.navigationTabs
                  .helpAndContact.links" :key="item" as="li" variant="sm" :append-class="twMerge(
                      'text-idle-white',
                      index !==
                      footer_accordion_tabs.navigationTabs.helpAndContact
                        .links.length -
                      1 && 'mb-2'
                    )
                      ">
                  <NuxtLink :to="localePath(item.link)" :data-testid="'desktop-' + props.dataTestId + '-helpAndContact-' + index
                    ">
                    {{ useTranslation(`${item.key}`, `${item.label}`) }}
                  </NuxtLink>
                </TsTypography>
              </ul>
            </TsColumn>
            <TsColumn dataTestid="column-two">
              <TsTypography as="p" variant="base" append-class="font-bold mb-4 text-idle-white">
                <span v-html="useTranslation('Company Information', 'Bedrijfsinformatie')"></span>
              </TsTypography>

              <ul v-if="
                footer_accordion_tabs.navigationTabs.companyInformation.links
                  .length > 0
              ">
                <TsTypography v-for="(item, index) in footer_accordion_tabs.navigationTabs
                  .companyInformation.links" :key="item" as="li" variant="sm" :append-class="twMerge(
                      'text-idle-white',
                      index !==
                      footer_accordion_tabs.navigationTabs.companyInformation
                        .links.length -
                      1 && 'mb-2'
                    )
                      ">
                  <NuxtLink :to="localePath(item.link)" :data-testid="'desktop-' +
                    props.dataTestId +
                    '-companyInformation-' +
                    index
                    ">
                    {{ useTranslation(item.key, item.label) }}
                  </NuxtLink>
                </TsTypography>
              </ul>
            </TsColumn>
            <TsColumn dataTestid="column-three">
              <TsTypography as="p" variant="base" append-class="font-bold mb-4 text-idle-white">
                <span v-html="useTranslation('Conditions', 'Voorwaarden')"></span>
              </TsTypography>

              <ul v-if="
                footer_accordion_tabs.navigationTabs.conditions.links.length >
                0
              ">
                <TsTypography v-for="(item, index) in footer_accordion_tabs.navigationTabs
                  .conditions.links" :key="item" as="li" variant="sm" :append-class="twMerge(
                      'text-idle-white',
                      index !==
                      footer_accordion_tabs.navigationTabs.conditions.links
                        .length -
                      1 && 'mb-2'
                    )
                      ">
                  <NuxtLink :to="localePath(item.link)" :data-testid="'desktop-' + props.dataTestId + '-conditions-' + index
                    ">
                    {{ useTranslation(item.key, item.label) }}
                  </NuxtLink>
                </TsTypography>
              </ul>

              <NuxtLink :to="localePath('/blogs-news-advices')" class="font-bold block mt-6 text-idle-white"
                :data-testid="'desktop-' + props.dataTestId + '-Advice/Blogs/News'
                  ">
                <span v-html="useTranslation('Blogs,News and Advices', 'Blogs, nieuws en adviezen')"></span>
              </NuxtLink>

              <NuxtLink :to="localePath('/account/catalogue')" class="font-bold block mt-4 text-idle-white"
                :data-testid="'desktop-' + props.dataTestId + '-FolderCatalogue'
                  ">
                <span v-html="useTranslation('Request Catalouge', 'Catalogus aanvragen')"></span>
              </NuxtLink>
            </TsColumn>
          </TsRow>
        </TsColumn>

      </TsRow>
    </div>

    <!-- Address & Policies -->
    <div class="bg-primary-hover">
      <TsRow
        class="flex-col items-center text-idle-white py-6 px-4 md:justify-between md:flex-row mx-auto max-w-screen-xl">
        <TsTypography align="center" append-class="mr-4 leading-relaxed text-idle-white mb-0">
          ©
          <NuxtLink :to="localePath('/')">Toolstation</NuxtLink>
          {{ currentYear }}. {{ useTranslation("chamberOfCommerce", "KvK-nummer:") }} 63449595
          <br />{{ useTranslation("vatNumber", "BTW-nummer") }}
          NL8552.40.854.B01

          <small>version:
            <span class="cursor-pointer underline hover:no-underline"
              @click="useRootStore().isReleaseModalVisible = true">
              {{ config.public.version }}
            </span>
          </small>
          <!--          <TsModalRelease />-->
        </TsTypography>

        <ul class="relative flex flex-wrap justify-center items-center shrink-0 max-md:mt-2">
          <li class="flex px-4 max-md:mt-2 max-md:border-r-2">
            <NuxtLink :to="localePath('/privacy-policy')"
              :data-testid="'desktop-' + props.dataTestId + '-privacy-policy'">
              <TsTypography align="center" append-class="text-idle-white mb-0">
                <span v-html="useTranslation('privacyPolicy', 'Privacybeleid')"></span>
              </TsTypography>
            </NuxtLink>
          </li>
          <li class="flex px-4 max-md:mt-2 sm:max-md:border-r-2">
            <NuxtLink :to="localePath('/cookie-policy')"
              :data-testid="'desktop-' + props.dataTestId + '-cookie-policy'">
              <TsTypography append-class="text-idle-white mb-0">
                <span v-html="useTranslation('cookiePolicy', 'Cookiebeleid')"></span>
              </TsTypography>
            </NuxtLink>
          </li>
          <!--          <li class="px-4 max-md:mt-2">-->
          <!--            <NuxtLink-->
          <!--                to="/"-->
          <!--                :data-testid="'desktop-' + props.dataTestId + '-cookie-setting'"-->
          <!--            >-->
          <!--              <TsTypography append-class="flex items-center text-idle-white mb-0">-->
          <!--                <NuxtImg-->
          <!--                    class="size-10 mr-2"-->
          <!--                    src="/icons/toogle-cookie.svg"-->
          <!--                    alt="Toogle Cookie"-->
          <!--                />-->
          <!--                &lt;!&ndash;                {{ useTranslation("cookieSettings") }}&ndash;&gt;-->
          <!--                Cookie Policy-->
          <!--              </TsTypography>-->
          <!--            </NuxtLink>-->
          <!--          </li>-->
        </ul>
      </TsRow>
    </div>
  </footer>
</template>

<script setup lang="ts">

const localePath = useLocalePath()

import { onMounted, ref } from "vue";
import { useRandomUUID } from "../composables/useRandomUUID";
import { twMerge } from "tailwind-merge";

const config = useRuntimeConfig()
// const cardCta=useTranslation("downloadApp","Download de app en ontvang 5% KORTING");
// const isReleaseModal = ref(false)

const currentYear = new Date().getFullYear();

const random_id = ref("");
const newsletter_email = ref("");
const { isDesktop, isTablet } = useDevice();
// const newsLetterHeading=useTranslation("newsLetterHeading","Altijd op de hoogte van onze acties")
// const newsletterDescription=useTranslation("newsletterDescription","Schrijf je in voor de nieuwsbrief en mis nooit meer iets. Ontvang direct 10% korting na registratie.")
// const { t } = useI18n({
//   useScope: "local",
// });

onMounted(() => {
  random_id.value = useRandomUUID();
});

type Props = {
  dataTestid?: string;
};

const props = defineProps<Props>();

const suggestions = [
  { text: "Electric Utility", link: "" },
  { text: "Electricity", link: "" },
  { text: "Hand Tool", link: "" },
  { text: "Tool Accessories", link: "" },
  { text: "Screws & Fasteners", link: "" },
];

const social_links = {
  mobile: [
    {
      link: "https://www.facebook.com/ToolstationNederland",
      image: "/icons/mobile-facebook.svg",
      name: "Facebook",
    },
    {
      link: "https://www.instagram.com/toolstation_nl/",
      image: "/icons/mobile-instagram.svg",
      name: "Instagram",
    },
    {
      link: "http://www.youtube.com/channel/UCT5P5fy539NUKmHX8gOOpAQ",
      image: "/icons/mobile-youtube.svg",
      name: "YouTube",
    },
    {
      link: "https://www.linkedin.com/company/toolstation-nl",
      image: "/icons/mobile-linkedin.svg",
      name: "LinkedIn",
    },
  ],
  desktop: [
    {
      link: "/",
      image: "/icons/desktop-instagram.svg",
      name: "Instagram",
    },
    {
      link: "/",
      image: "/icons/desktop-facebook.svg",
      name: "Facebook",
    },
    {
      link: "/",
      image: "/icons/desktop-twitter.svg",
      name: "Twitter",
    },
    {
      link: "/",
      image: "/icons/desktop-youtube.svg",
      name: "YouTube",
    },
    {
      link: "/",
      image: "/icons/desktop-linkedin.svg",
      name: "LinkedIn",
    },
  ],
};

const customer_section_cards = [
  {
    image: "/images/customer-support-track-order.png",
    alt: "Track Order",
    label: "Track Order",
  },
  {
    image: "/images/customer-support-pro-card.png",
    alt: "Pro Card",
    label: "Pro Card",
  },
  {
    image: "/images/customer-support-store-nearby.png",
    alt: "Store Nearby",
    label: "Store Nearby",
  },
  {
    image: "/images/customer-support-bulk-enquiry.png",
    alt: "Bulk Enquiry",
    label: "Bulk Enquiry",
  },
];


const footer_accordion_tabs = {
  navigationTabs: {
    helpAndContact: {
      heading: "Help & Contact",
      links: [
        {
          link: "/faq",
          key: "faq",
          label: "FAQ"
        },
        {
          link: "/contact-us",
          key: "contact",
          label: "Contact"
        },
        {
          link: "/payment-methods",
          key: "paymentMethods",
          label: "Betaalmethoden"
        },
        {
          link: "/delivery",
          key: "delivery",
          label: "Levering"
        },
        {
          link: "/click-and-collect",
          key: "clickCollect",
          label: "Klik en verzamel"
        },
        {
          link: "/returns-exchange",
          key: "returnsExchange",
          label: "Ruilen en retourneren"
        },
        {
          link: "/site-map",
          key: "siteMap",
          label: "Sitemap"
        },
        {
          link: "/guarantee",
          key: "guarantee",
          label: "Garantie"
        },
      ],
    },
    companyInformation: {
      heading: 'Company Information',
      links: [
        {
          link: "/about-us",
          key: "aboutUs",
          label: "Over ons"
        },
        {
          link: "/pro-card",
          key: "proCard",
          label: "PRO-kaart"
        },
        {
          link: "/toolstation-app",
          key: "toolstationApp",
          label: "Toolstation-app"
        },
        // {
        //   link: "#",
        //   label: "Trade Fair Event Calender",
        // },
        {
          link: "/branches",
          key: "branches",
          label: "Takken"
        },
        {
          link: "/vacancies",
          key: "vacancies",
          label: "Vacatures"
        },
      ],
    },
    conditions: {
      heading: 'Conditions',
      links: [
        {
          link: "/terms-conditions",
          key: "termsConditions",
          label: "Algemene voorwaarden"
        },
        {
          link: "/privacy-policy",
          key: "privacyPolicy",
          label: "Privacybeleid"
        },
        {
          link: "/cookie-policy",
          key: "cookiePolicy",
          label: "Cookiebeleid"
        },
        {
          link: "/reviews",
          key: "reviews",
          label: "Recensies"
        },
        {
          link: "/weee",
          key: "weee",
          label: "Weee"
        },
        {
          link: "/modern-slavery-statement",
          key: "modernSlaveryStatement",
          label: "Verklaring van moderne slavernij"
        },
      ],
    },
  },
  discoverTabs: {
    bathroomsTaps: {
      heading: "Bathroom Taps",
      column: 1,
      links: [
        {
          link: "/",
          label: "Basin Pillar Taps",
        },
        {
          link: "/",
          label: "Basin Mixer Taps",
        },
        {
          link: "/",
          label: "Bath Pillar Taps",
        },
        {
          link: "/",
          label: "Bath Filler Taps",
        },
        {
          link: "/",
          label: "Bath Shower Mixer Taps",
        },
        {
          link: "/",
          label: "Cloakroom Basin Taps",
        },
      ],
    },
    showers: {
      heading: "Showers",
      column: 2,
      links: [
        {
          link: "/",
          label: "Mixer Showers",
        },
        {
          link: "/",
          label: "Electric Showers",
        },
        {
          link: "/",
          label: "Power Showers",
        },
        {
          link: "/",
          label: "Digital Showers",
        },
      ],
    },
    bathroomFittings: {
      heading: "Bathroom Fittings",
      column: 3,
      links: [
        {
          link: "/",
          label: "Bathroom Suites",
        },
        {
          link: "/",
          label: "Baths",
        },
        {
          link: "/",
          label: "Bathroom Sinks & Basins",
        },
        {
          link: "/",
          label: "Toilets",
        },
        {
          link: "/",
          label: "Urinals",
        },
      ],
    },
    showerEnclosures: {
      heading: "Shower Enclosures",
      column: 4,
      links: [
        {
          link: "/",
          label: "Bathroom Wall Panels",
        },
        {
          link: "/",
          label: "Bath Shower Screens",
        },
        {
          link: "/",
          label: "Shower Trays",
        },
      ],
    },
    showerAccessories: {
      heading: "Shower Accessories",
      column: 5,
      links: [
        {
          link: "/",
          label: "Shower Heads",
        },
        {
          link: "/",
          label: "Shower Hoses",
        },
        {
          link: "/",
          label: "Shower Riser Rails & Kits",
        },
        {
          link: "/",
          label: "Shower Valves",
        },
      ],
    },
  },
};

const payment_options = [
  {
    image: "/icons/ic_outline-apple.svg",
    alt: "apple pay",
    width: '24',
    height: '24'
  },
  {
    image: "/icons/paypal.svg",
    alt: "paypal",
    width: '21',
    height: '24'
  },
  {
    image: "/icons/american-express.svg",
    alt: "american express",
    width: '42',
    height: '24'
  },
  {
    image: "/icons/i-deal.svg",
    alt: "iDeal",
    width: '25',
    height: '24'
  },
  {
    image: "/icons/master-card.svg",
    alt: "master card",
    width: '29',
    height: '24'
  },
  {
    image: "/icons/google-pay.svg",
    alt: "google pay",
    width: '25',
    height: '24'
  },
  {
    image: "/icons/image-1.svg",
    alt: "pro card",
    width: '37',
    height: '24'
  },
  {
    image: "/icons/visa.svg",
    alt: "visa",
    width: '40',
    height: '24'
  },
];
</script>
