import { useAjax } from "~/composables/useAjax";
import { EcomService } from "~/services/ecom.service";
import { AppCookieNames } from "../assets/constants/ecomApi";
import { useNuxtApp } from "#app";

export const useAjaxEcom = async <T>(
  url: string,
  options?: {
    params?: object;
    method?: "get" | "post" | "put" | "patch" | "delete";
    query?: object;
    headers?: object;
    show_notifications?: boolean;
    show_loading_indicator?: boolean;
    severity?: string;
    debug?: boolean;
    cache?: boolean;
  },
  callback = null
) => {
  try {
    if (!options) {
      options = {};
    }
    const rootStore = useRootStore();

    let key = url;
    if(options.query){
      key = key+JSON.stringify(options.query);
    }else if(options.params){
      key = key+JSON.stringify(options.params);
    }

    if(options.cache){

      if(rootStore.cache && rootStore.cache[key]){
        return rootStore.cache[key];
      }

    }

    if (!("show_notifications" in options)) {
      options.show_notifications = false;
    }

    if (!("debug" in options)) {
      options.debug = true;
    }

    if (!options.headers) {
      options.headers = {};
    }

    if(options.cache){
      options.headers.cache = true;
      options.headers.cache_key = key;
    }

    const jwt_token = useCookie(AppCookieNames.CustomerJWT);
    const authStore = useAuthStore();

    if (jwt_token.value && authStore.is_authenticated) {
      options.headers["X-Toolstation-Customer-Id"] = jwt_token.value;
    }else if(authStore.user && authStore.user.token){
      options.headers["X-Toolstation-Customer-Id"] = useAuthStore().user.token;
    }else if(authStore.guest && authStore.guest.token){
      options.headers["X-Toolstation-Customer-Id"] = useAuthStore().guest.token;
    }

    const i18n = { global: useNuxtApp().$i18n };

    options.headers['Accept-Language'] = i18n.global.locale.value;

    const public_config = useRuntimeConfig().public;

    let base_url = public_config.featureFlags.ecomServerRequestEnable ?
        '/api/ecom' : public_config.defaultEcomApiBaseUrl;

    let ecom_url = base_url + url;
    const access_token = useCookie<string>("ecom_access_token");

    let min = 200;
    let max = 1000;

    const random = Math.floor(Math.random() * (max - min) + min);

    await new Promise((resolve) => setTimeout(resolve, random));

    /* Fall back call to refresh the token - should never be required since token is refreshed continually */
    if (!access_token.value) {
      access_token.value = await EcomService.refreshToken();
    }

    options.headers.authorization = `Bearer ${access_token.value}`;

    let response =  await useAjax<T>(ecom_url, options, callback);

    if(rootStore.is_ecom_failed_modal_visible === false &&
        rootStore.is_ecom_failed_modal_force_closed === false &&
        response && response.success === false){

      if(response.data && response.data.error){

        if(response.data.error.code &&
            (response.data.error.code.includes('401.')
                || response.data.error.code.includes('422.')
                || response.data.error.code.includes('403.')
                || response.data.error.code.includes('404.'))){
          return response;
        }

        rootStore.ecom_error = response.data.error;
      }else{
        rootStore.ecom_error = {};
        rootStore.ecom_error.code = 'Possibly CORS error';
        rootStore.ecom_error.message = response.errors && response.errors.length > 0
            ?response.errors[0]:'Failed to fetch';
      }

      rootStore.ecom_error.method = options.method ?? 'get';
      rootStore.ecom_error.url =  useRuntimeConfig().public.defaultEcomApiBaseUrl + url;
      rootStore.is_ecom_failed_modal_visible = true;
      rootStore.is_ecom_failed_modal_force_closed = true;
    }

    if(options.cache && response && !("success" in response) ){
      rootStore.cache[key] = response;
    }

    return response;
  } catch (error) {

    let error_message = ["Something went wrong."];
    if (options?.debug && error instanceof Error) {
      error_message = [error.message];
    }

    return {
      success: false,
      errors: error_message,
    };
  }
};