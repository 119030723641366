export default {
  // Access token time is set by apigee
  ECOM_API_COOKIE_NAME: "ecomApiAccessToken",
  NEWSLETTER_SIGNUP: "/newsletters/signup",
  BRANCHES_NEAREST: "/branches/nearest",
  CATALOGUE_CONFIG: "/vcat/config",
  CUSTOMERS_CATALOGUE_REQUESTS: (userId: string) =>
    `/customers/${userId}/catalogue-requests`,
  CUSTOMERS_LAST_CATALOGUE_REQUEST: (userId: string) =>
    `/customers/${userId}/last-catalogue-request`,
  PROCARD_COMPANY_SEARCH: "/procard/company-search",
  PROCARD_BANKS: "/procard/banks",
  PROCARD_MATCH_PRINCIPLE: "/procard/match-principle",
  PROCARD_AGREEMENT: "/procard/agreement",
  PROCARD_APPLICATION_SAVE: "/procard/application/save",
  PROCARD_APPLICATION_DRAFT: "/procard/application/draft",
  PROCARD_COMPANY_DATA: (dunsNumber: string) =>
    `/procard/company-data/${dunsNumber}`,
};

/**
 * These are specific cookies coming from Laravel that are used in a few places
 */
export enum AppCookieNames {
  GuestCheckoutSession = "guest-checkout-session", // Comes encrypted from Laravel
  Trolley = "trolley", // Comes encrypted from Laravel
  CustomerJWT = "customer-jwt",
  GuestCustomerJWT = "guest-customer-jwt", // Users with guest account only
  TsSessionId = "ts-session-id",
  RecaptchaToken = "grecaptcha-token",
}

export enum DeliveryMethodCodes {
  Collection = "00006",
  Directship = "00033",
  Delivery = "00004",
  DeliveryUnder = "00005",
  Remote = "00014",
  RemoteUnder = "00015",
  SameDayDelivery = "00035",
  NextDayCollection = "00037",
}
