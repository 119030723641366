<template>
  <ClientOnly>
    <TsModal v-model:visible="rootStore.isReleaseModalVisible"
             append-class="no-scrollbar text-left max-w-screen-xl w-[95%] lg:w-[75%] overflow-y-auto max-h-[90%] lg:max-h-[88%] h-full bg-white lg:overflow-y-hidden"
             @hide="rootStore.setVersion()">
      <template #modal-content>

        <div class="px-4 lg:px-8 lg:h-full">
          <div class="relative  max-lg:sticky max-lg:top-0 bg-white z-20">
            <ol
                class="items-center w-full overflow-x-auto no-scrollbar flex space-x-4 sm:space-x-8 sm:space-y-0 rtl:space-x-reverse lg:justify-center pt-8 pb-6 border-b pr-24">
              <li @click="handleStepper(1)" :class="selectedStep === 1 ? 'text-primary' : 'text-natural-grey'"
                  class="flex items-center min-w-max space-x-2.5 rtl:space-x-reverse cursor-pointer">
                <span
                    :class="selectedStep === 1 ? 'border-primary' : 'border-natural-grey'"
                    class="flex items-center justify-center w-6 h-6 lg:w-9 lg:h-9 border rounded-full shrink-0 font-semibold">
                  1
                </span>
                <span>
                  <h3 class="font-semibold leading-tight max-lg:text-xs max-lg:text-sm max-lg:text-sm">Release Notes
                  </h3>
                  <p
                      class="text-sm font-normal max-lg:text-[8px] max-lg:leading-[12px] max-lg:text-[8px] max-lg:leading-[12px] max-lg:text-[8px]">
                    What's New</p>
                </span>
              </li>
              <li @click="handleStepper(2)" :class="selectedStep === 2 ? 'text-primary' : 'text-natural-grey'"
                  class="flex items-center min-w-max text-gray-500 space-x-2.5 rtl:space-x-reverse cursor-pointer">
                <span
                    :class="selectedStep === 2 ? 'border-primary' : 'border-natural-grey'"
                    class="flex items-center justify-center w-6 h-6 lg:w-9 lg:h-9 border rounded-full shrink-0 font-semibold">
                  2
                </span>
                <span>
                  <h3 class="font-semibold leading-tight max-lg:text-xs max-lg:text-sm max-lg:text-sm">Requirement Table
                  </h3>
                  <p
                      class="text-sm font-normal max-lg:text-[8px] max-lg:leading-[12px] max-lg:text-[8px] max-lg:leading-[12px] max-lg:text-[8px]">
                    System Needs </p>
                </span>
              </li>
              <li @click="handleStepper(3)" :class="selectedStep === 3 ? 'text-primary' : 'text-natural-grey'"
                  class="flex items-center min-w-max text-gray-500 space-x-2.5 rtl:space-x-reverse cursor-pointer">
                <span
                    :class="selectedStep === 3 ? 'border-primary' : 'border-natural-grey'"
                    class="flex items-center justify-center w-6 h-6 lg:w-9 lg:h-9 border rounded-full shrink-0 font-semibold">
                  3
                </span>
                <span>
                  <h3 class="font-semibold leading-tight max-lg:text-xs max-lg:text-sm max-lg:text-sm">Change Log</h3>
                  <p
                      class="text-sm font-normal max-lg:text-[8px] max-lg:leading-[12px] max-lg:text-[8px] max-lg:leading-[12px] max-lg:text-[8px]">
                    Recent Updates </p>
                </span>
              </li>
              <li @click="handleStepper(4)" :class="selectedStep === 4 ? 'text-primary' : 'text-natural-grey'"
                  class="flex items-center min-w-max text-gray-500 space-x-2.5 rtl:space-x-reverse cursor-pointer">
                <span
                    :class="selectedStep === 4 ? 'border-primary' : 'border-natural-grey'"
                    class="flex items-center justify-center w-6 h-6 lg:w-9 lg:h-9 border rounded-full shrink-0 font-semibold ">
                  4
                </span>
                <span>
                  <h3 class="font-semibold leading-tight max-lg:text-xs max-lg:text-sm max-lg:text-sm">Known Issue</h3>
                  <p
                      class="text-sm font-normal max-lg:text-[8px] max-lg:leading-[12px] max-lg:text-[8px] max-lg:leading-[12px] max-lg:text-[8px]">
                    Current Bugs </p>
                </span>
              </li>
            </ol>
            <div
                class="absolute -top-1 h-full -right-1 bg-[linear-gradient(269.74deg,_#FFFFFF_80.4%,_rgba(255,_255,_255,_0)_121.01%)] pt-8 w-12 pl-4">
              <TsIcon @click="handleReleaseModal"
                      class="cursor-pointer p-1 hover:bg-gray-200 rounded-md shrink-0 "
                      name="bitcoin-icons:cross-filled"/>
            </div>
          </div>


          <div v-if="selectedStep === 1"
               class="lg:max-h-[calc(100%-195px)] lg:overflow-y-auto max-lg:no-scrollbar release-scroll-container pb-20 mt-6 flex flex-col">
            <TsTypography append-class="font-semibold text-[20px] lg:text-[24px] leading-[26px] mb-0 text-idle-black">
              <p>
                <span>Release Notes </span> <br/>
                <span class="font-normal text-sm">See what's new at Toolstation!</span>
              </p>
            </TsTypography>

            <div class="mt-6">
              <div>
                <div class="flex items-center w-full border-b pb-2.5">
                  <TsTypography
                      append-class="max-lg:text-sm font-semibold text-idle-black w-1/2 lg:w-[30%] border-r pl-2 lg:pl-5 mr-2 lg:mr-5 whitespace-nowrap">
                    Release Type
                  </TsTypography>
                  <TsTag append-class="font-semibold text-sm px-3" size="sm" rounded variant="info">Beta
                  </TsTag>
                </div>
                <div class="flex items-center w-full border-b py-2.5">
                  <TsTypography
                      append-class="max-lg:text-sm font-semibold text-idle-black w-1/2 lg:w-[30%] pl-2 lg:pl-5 whitespace-nowrap">
                    Version
                  </TsTypography>
                  <TsTypography append-class="pl-2 lg:pl-5 max-lg:w-1/2 border-l" size="sm" rounded variant="info">{{
                      useRuntimeConfig().public.version
                    }}
                  </TsTypography>
                </div>
                <div class="flex items-center w-full border-b py-2.5">
                  <TsTypography
                      append-class="max-lg:text-sm font-semibold text-idle-black w-1/2 lg:w-[30%] pl-2 lg:pl-5 whitespace-nowrap">
                    Environment
                  </TsTypography>
                  <TsTypography append-class="max-lg:text-sm pl-2 lg:pl-5 capitalize max-lg:w-1/2 border-l">
                    {{ useRuntimeConfig().public.appEnv }}
                  </TsTypography>
                </div>
                <div class="flex w-full border-b py-2.5">
                  <TsTypography
                      append-class="max-lg:text-sm font-semibold text-idle-black w-1/2 lg:w-[30%] pl-2 lg:pl-5 whitespace-nowrap my-auto">
                    Builder.io Environment
                  </TsTypography>
                  <TsTypography
                      append-class="pl-2 lg:pl-5 flex flex-wrap items-center max-lg:gap-y-2 max-lg:gap-x-1 lg:gap-3 max-lg:w-1/2 border-l">
                    <TsTag size="sm" rounded append-class="font-semibold text-sm px-3">Toolstation Europe BV</TsTag>
                    >
                    <TsTag size="sm" rounded append-class="font-semibold text-sm px-3">Toolstation Europe</TsTag>
                    >
                    <TsTag size="sm" rounded append-class="font-semibold text-sm px-3">Toolstation Staging</TsTag>
                  </TsTypography>
                </div>
                <div class="flex items-center w-full border-b py-2.5">
                  <TsTypography
                      append-class="max-lg:text-sm font-semibold text-idle-black max-lg:w-1/2 lg:w-[30%] pl-2 lg:pl-5 whitespace-nowrap">
                    Date
                  </TsTypography>
                  <TsTypography append-class="max-lg:text-sm pl-2 lg:pl-5 w-1/2 border-l">15th November 2024
                  </TsTypography>
                </div>
              </div>

              <TsAlert variant="info" append-class="bg-info/10 px-5 py-4 text-sm mt-6">
                <div>

                  <p class="font-semibold mb-2 text-base">What is Beta Release?</p>

                  A nearly complete version of a software product that's shared with a select group of external users
                  for feedback and further testing. Beta releases are used to identify any remaining issues before the
                  final release. Beta testing helps reduce the risk of product failure and increase the quality of the
                  product
                  <!--                  An <b>alpha release</b> of a software product is an early version made available to a limited group of-->
                  <!--                  internal-->
                  <!--                  testers, developers, or trusted users. This version is typically the first to include the full set of-->
                  <!--                  core-->
                  <!--                  features, but <b>it is not yet fully complete or stable</b>. The primary goals of an alpha release are-->
                  <!--                  to-->
                  <!--                  identify-->
                  <!--                  and fix major bugs, test key functionalities, and gather feedback on the user experience. <br>-->

                </div>
              </TsAlert>
            </div>
          </div>

          <!----------Requirement table---------->
          <div v-if="selectedStep === 2"
               class="lg:max-h-[calc(100%-195px)] h-full lg:overflow-y-auto max-lg:no-scrollbar release-scroll-container mt-6 flex flex-col">
            <TsTypography
                append-class="text-idle-black font-semibold text-[20px] lg:text-[24px] leading-[26px] mb-0">
              <p>
                <span>Requirement Table </span> <br/>
                <span class="font-normal text-sm">Dos & Don’ts: Your Quick Reference Guide</span>
              </p>
            </TsTypography>

            <div class="flex flex-col lg:flex-row justify-center gap-2 lg:gap-4 mt-6 h-[calc(100%-80px)]">
              <div class="w-full rounded-xl relative">
                <!-- Do's Table Header -->
                <div
                    class="p-2 font-semibold border bg-natural-soft-white absolute w-full mb-4 top-0 z-10 rounded-t-lg flex justify-center items-center gap-2">
                  <p class="border-x-natural-light-grey text-center text-success font-semibold text-lg">Do's</p>
                  <TsIcon name="mdi:thumbs-up-outline" class="text-success" size="20"></TsIcon>
                </div>
                <div
                    class="relative overflow-x-auto border w-full bg-idle-white rounded-b-lg max-h-[calc(100%-70px)] overflow-y-auto release-scroll-container mt-11">
                  <table class="w-full text-left text-sm rounded-lg">
                    <tbody>
                    <tr v-for="(item, index) in dos" :key="index" class="border-t">
                      <td class="py-3 pl-2 flex items-start space-x-2">
                        <TsIcon name="ic:outline-check-circle" size="22" class="text-success shrink-0 mt-0.5"/>
                        <div>
                          <TsTypography weight="bold" append-class="max-lg:text-sm text-black mb-1.5">{{
                              item.title
                            }}
                          </TsTypography>
                          <TsTypography append-class="max-lg:text-xs">{{ item.description }}</TsTypography>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="w-full rounded-xl relative max-lg:mt-4">
                <!-- Don'ts Table Header -->
                <div
                    class="p-2 font-semibold border border-b-0 bg-natural-soft-white absolute w-full top-0 z-10 rounded-t-lg flex items-center justify-center gap-2">
                  <p class="border-x-natural-light-grey text-center text-danger font-semibold text-lg">Don'ts</p>
                  <TsIcon name="mdi:thumbs-down-outline" size="20" class="text-danger"></TsIcon>
                </div>
                <div
                    class="border relative overflow-x-auto w-full bg-idle-white mt-11 rounded-b-lg max-h-[calc(100%-70px)] overflow-y-auto release-scroll-container">
                  <table class="w-full text-left text-sm rounded-lg">
                    <tbody>
                    <tr v-for="(item, index) in donts" :key="index" class="border-t">
                      <td class="py-3 pl-2 flex items-start space-x-2">
                        <TsIcon name="charm:circle-cross" size="22" class="text-danger/40 shrink-0 mt-0.5"/>
                        <div>
                          <TsTypography weight="bold" append-class="max-lg:text-sm text-black mb-1.5">{{
                              item.title
                            }}
                          </TsTypography>
                          <TsTypography append-class="max-lg:text-xs">{{ item.description }}</TsTypography>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!----------Change Log table---------->
          <div v-if="selectedStep === 3"
               class="lg:max-h-[calc(100%-195px)] lg:overflow-y-auto max-lg:no-scrollbar release-scroll-container pb-20 mt-6">
            <TsTypography
                append-class="text-idle-black font-semibold text-[20px] lg:text-[24px] leading-[26px] mb-0">
              <p>
                <span>Change Logs </span> <br/>
                <span class="font-normal text-sm">12th Nov 2024</span>
              </p>
            </TsTypography>
            <div class="mt-6">
              <ul class="flex flex-wrap">
                <li v-for="(item, index) in release_log" :key="index"
                    class="py-3 pl-2 lg:pl-2 lg:pl-5 text-[14px] w-full lg:w-1/2 border-b last:border-b-0 lg:*:odd:border-r">
                  <p class="w-full flex items-start ">
                    <TsTag v-if="item.tag" :variant="'success'" size="sm" rounded
                           class="font-semibold text-xs lg:text-sm mr-2 py-1 px-2 lg:px-3">
                      {{ item.tag }}
                    </TsTag>
                    <span class="font-semibold text-idle-black text-sm lg:text-base">{{ item.title }}</span>
                  </p>
                </li>
              </ul>

            </div>
          </div>

          <!----------Know Issue & Dependency table---------->
          <div v-if="selectedStep === 4"
               class="lg:max-h-[calc(100%-195px)] lg:overflow-y-auto max-lg:no-scrollbar release-scroll-container pb-20 mt-6">
            <TsTypography
                append-class="text-idle-black font-semibold text-[20px] lg:text-[24px] leading-[26px] mb-0">
              <p>
                <span>Know Issue & Dependencies</span> <br/>
                <span class="font-normal text-sm">12th Nov 2024</span>
              </p>
            </TsTypography>
            <div class="mt-6">
              <ul>
                <li v-for="(item, index) in knownIssueAndDependency" :key="index"
                    class="flex items-start py-3 pl-2 lg:pl-2 lg:pl-5  border-b last:border-b-0">
                  <TsTag v-if="item.tag" :variant="item.variant" size="sm" rounded
                         class="font-semibold text-xs lg:text-sm mr-2 py-1 px-2 lg:px-3">
                    {{ item.tag }}
                  </TsTag>
                  <NuxtLink :to="item.link" target="_blank" :class="item.linkClass" class="font-semibold text-idle-black text-sm lg:text-base">
                    {{ item.title }}
                  </NuxtLink>
                </li>
              </ul>
            </div>
            <TsTypography
                append-class="text-idle-black font-semibold text-[20px] lg:text-[24px] leading-[26px] pt-6 mb-0">
              <p>
                <span>Next Release</span> <br/>
                <span class="font-normal text-sm">19th Nov 2024</span>
              </p>
            </TsTypography>
            <div class="mt-4">
              <TsTag size="sm" variant="info" append-class="font-semibold text-xs lg:text-sm mr-2 py-1 px-2 lg:px-3"
                     rounded>v1.8.0-beta

              </TsTag>
            </div>
          </div>

        </div>
        <div
            class="sticky lg:absolute bottom-0 flex justify-between w-full bg-white z-20 py-4 shadow-[0px_0px_10px_0px_#0000001F] px-4 lg:px-8">
          <TsButton
              @click="handlePreviousBtn"
              :disabled="selectedStep === 1"
              :class="{ 'text-grey cursor-not-allowed': selectedStep === 1}"
              append-class="hover:bg-transparent bg-transparent hover:text-primary border-0 text-primary p-0 focus:ring-0">
            Previous
          </TsButton>
          <div v-if="selectedStep !== 4">
            <TsButton @click="handleReleaseModal"
                      append-class="hover:bg-transparent bg-transparent hover:text-primary border-0 text-primary p-0 mr-7 focus:ring-0">
              Skip
            </TsButton>
            <TsButton @click="handleNextBtn" append-class="px-10">Next</TsButton>
          </div>
          <div v-else>
            <TsButton @click="handleReleaseModal" append-class="px-10">Done</TsButton>
          </div>
        </div>
      </template>
    </TsModal>
  </ClientOnly>
</template>

<script setup>
const rootStore = useRootStore();

const dos = ref([
  {title: "Explore the Features:", description: "Feel free to explore the features to see what’s been built"},
  {title: "Focus on Key Areas:", description: "If possible, please focus only on the key areas of the features."},
  {
    title: "Wider Testing Base:",
    description: "Allow users from different functions to test the site (Business, Marketing, etc.)"
  },
  {title: "Test for Edge Cases:", description: "If possible, test for edge cases (like entering wrong information)"},
  {title: "Participate Actively:", description: "Try to invest as much time as possible in testing the major features"},
]);

const donts = ref([
  {
    title: "Don’t Expect SEO Implementations:",
    description: "Please avoid tests to check SEO, as SEO audit is not completed yet"
  },
  {title: "Don’t Test for Performance:", description: "Please refrain from doing performance testing"},
  {title: "Don’t Expect Complete Stability:", description: "Please don’t expect the product to be fully stable"},
  {title: "Don’t Use Live Data:", description: "Please refrain from using live data for testing"},
  {
    title: "Don’t Give Public Access:",
    description: "Please refrain from giving access to anyone outside the testing group"
  },
]);

const release_log = [
  {title: "Guest Checkout", tag: "New"},
  {title: "Procard Without Credit", tag: "New"},
  {title: "Message Center", tag: "New"},
  {title: "Promo Center", tag: "New"},
  {title: "Trolley Code", tag: "New"},
  {title: "String Translation", tag: "New"},
  {title: "Blogs News & Advice", tag: "New"},
  {title: "Events", tag: "New"},
  {title: "VAT Toggle", tag: "New"},
];

const knownIssueAndDependency = [
  {title: "Page loading speed is low", tag: "Known Issue", variant: "success"},
  {
    title: "Beta Know Issue Sheet",
    tag: "Known Issue",
    variant: "success",
    link: 'https://docs.google.com/spreadsheets/d/1bKDdKGs2M8W6mAshiGdf8wxkvV4zQCGyv3yNbXZ_BfA/edit?gid=1478194239#gid=1478194239',
    linkClass: 'underline text-info hover:no-underline'
  },
  {title: "Product translation will be done through ECOM", tag: "Dependency", variant: "danger"},
  {title: "Dependency - New Ecom API Endpoint\n", tag: "Dependency", variant: "danger"},
];

const selectedStep = ref(1);


const handleStepper = (step) => {
  selectedStep.value = step;
};

const handlePreviousBtn = () => {
  if (selectedStep.value !== 1) ;
  selectedStep.value = selectedStep.value - 1;
};

const handleNextBtn = () => {
  // if(selectedStep.value !== 4);
  selectedStep.value = selectedStep.value + 1;
};

const handleReleaseModal = () => {
  selectedStep.value = 1;
  rootStore.isReleaseModalVisible = false;
};

</script>

<style scoped>
.release-scroll-container {
  overflow-y: auto;
}

.release-scroll-container::-webkit-scrollbar {
  width: 10px;
}

.release-scroll-container::-webkit-scrollbar-track {
  background: #e3e3e3;
  /*border-radius: 1000px;*/
  padding-right: 8px;

}

.release-scroll-container::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: 3px solid #e3e3e3;

  /*border: 3px solid;*/
  /*border-radius: 1000px;*/
}

.release-scroll-container::-webkit-scrollbar-thumb:hover {
  background: #727272 !important;
  width: 40px;

}
</style>
