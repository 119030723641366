<template>
  <ClientOnly>
  <Teleport to="body">
    <template v-if="isDesktop">
      <TsModal
        v-bind="$attrs"
        append-class="bg-transparent overflow-hidden"
      >
        <template #modal-content>
          <TsCardBranchV1
            @save="emit('save')"
            data-testid="branch-selector-modal"
            @close="branchStore.branchSelectorModalVisible = false"
          />
        </template>
      </TsModal>
    </template>

    <template v-if="isMobile">
      <TsDrawer
        v-bind="$attrs"
        append-class="bg-transparent overflow-hidden"
        position="bottom"
      >
        <TsCardBranchV1
          @save="emit('save')"
          data-testid="branch-selector-modal"
          @close="branchStore.branchSelectorModalVisible = false"
        />
      </TsDrawer>
    </template>
  </Teleport>
  </ClientOnly>
</template>

<script lang="ts" setup>
defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits<{
  save: any;
}>();

const { isMobile, isDesktop } = useDevice();
const branchStore = useBranchStore();
</script>
