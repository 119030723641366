import { defineStore } from "pinia";
import { AppCookieNames } from "../assets/constants/ecomApi";
import VueJwtDecode from "vue-jwt-decode";

import EcomCustomerService from "../services/ecom/customer.service";
import { z } from "zod";
import { EcomService } from "../services/ecom.service";

const addressSchema = z.object({
  line_1: z.string().min(1, "House Number is required."),
  line_2: z.string().optional(),
  line_3: z.string().min(1, "Street is required."),
  town: z.string().min(1, "Place is required."),
  postcode: z.string().regex(/^[0-9]{4} ?[A-Z]{2}$/, "Postal Code is invalid."),
  country: z.string().optional(),
  country_id: z.number().int().positive(),
});

const signUpSchema = z.object({
  title: z.string().min(1, "Title is required."),
  first_name: z.string().min(1, "First name is required."),
  last_name: z.string().min(1, "Last name is required."),
  email: z.string().email("Email must be valid."),
  mobile: z.string().min(7, "Mobile Number must be between 7 and 15 digits.")
    .max(15, "Mobile Number must be between 7 and 15 digits.").min(1, "Mobile Number is required."),
  password: z
    .string()
    .min(8, "Password must be at least 8 characters long.")
    .regex(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
      "Password format is invalid."
    ),
  company: z.string().optional(),
  address: addressSchema,
  contact_preferences: z.object({
    email: z.boolean(),
    mobile: z.boolean(),
    sms: z.boolean(),
    post: z.boolean(),
  }),
  terms_accepted: z.boolean().refine((val) => val === true, {
    message: "You must accept the terms.",
  }),
});

const guestSchema = z.object({
  first_name: z
    .string()
    .trim()
    .refine((value) => value.length >= 2, {
      message: "First name must be at least 2 characters long.",
    })
    .refine((value) => value.length <= 40, {
      message: "First name must not exceed 40 characters.",
    })
    .refine((value) => /^[a-zA-Z\s]+$/.test(value), {
      message: "First name can only contain letters and spaces.",
    })
    .refine((value) => value.length > 0, {
      message: "First name is required.",
    }),

  last_name: z
    .string()
    .trim()
    .refine((value) => value.length >= 2, {
      message: "Last name must be at least 2 characters long.",
    })
    .refine((value) => value.length <= 40, {
      message: "Last name must not exceed 40 characters.",
    })
    .refine((value) => /^[a-zA-Z\s]+$/.test(value), {
      message: "Last name can only contain letters and spaces.",
    })
    .refine((value) => value.length > 0, {
      message: "Last name is required.",
    }),

  email: z.string().email("Email must be valid."),

  mobile: z
    .string()
    .refine((value) => value.replace(/[^0-9]/g, "").length >= 5, {
      message: "Mobile number must be at least 5 digits long.",
    })
    .refine((value) => value.replace(/[^0-9]/g, "").length <= 30, {
      message: "Mobile number must not exceed 30 digits.",
    })
    .refine((value) => value.trim().length > 0, {
      message: "Mobile number is required.",
    }),

  address: addressSchema,
});

const signInSchema = z.object({
  username: z
    .string()
    .min(1, "Email is required.")
    .email("Email must be valid."),
  password: z.string().min(1, "Password is required."),
});

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
    guest: null,
    error_message: <string | null>null,
    guest_error_message: <string | null>null,
    success_message: <string | null>null,
    is_guest_checkout: false,
    is_sign_in_button_loading: false,
    is_guest_sign_in_button_disabled: true,
    is_guest_sign_in_button_loading: false,
    is_forgot_password_button_loading: false,
    is_reset_password_button_loading: false,
    item: {
      username: "",
      password: "",
    },
    sign_in_form_error: {
      username: "",
      password: "",
    },
    forgot_password_item: {
      email: "",
    },
    guest_item: {
      email: "",
      first_name: "",
      last_name: "",
      mobile: "",
      terms_accepted: false,
      address: {
        type: 1,
        town: "",
        postcode: "",
        line_1: "",
        line_2: "",
        line_3: "",
        country: "",
        country_id: 8,
      },
    },
    guest_form_error: {
      email: "",
      first_name: "",
      last_name: "",
      mobile: "",
      terms_accepted: false,
      address: {
        type: 1,
        town: "",
        postcode: "",
        line_1: "",
        line_2: "",
        line_3: "",
        country: "",
        country_id: 8,
      },
    },
    reset_password_item: {
      token: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    active_form: "",
    sign_up_form: {
      title: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      password: "",
      company: "",
      address: {
        type: 1,
        town: "",
        postcode: "",
        line_1: "",
        line_2: "",
        line_3: "",
        country: "",
        country_id: 8,
      },
      contact_preferences: {
        email: false,
        mobile: false,
        sms: false,
        post: false,
      },
      terms_accepted: true,
    },
    is_sign_up_button_loading: false,
    is_sign_up_button_disabled: false,
    title_options: [
      { name: "Mr", code: "Mr" },
      { name: "Mrs", code: "Mrs" },
    ],
    sign_up_form_error_message: "",
    sign_up_form_error: {
      title: "",
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      password: "",
      company: "",
      address: {
        type: 1,
        town: "",
        postcode: "",
        line_1: "",
        line_2: "",
        line_3: "",
        country: "",
        country_id: 8,
      },
      contact_preferences: {
        email: false,
        mobile: false,
        sms: false,
        post: true,
      },
      terms_accepted: true,
    },
    is_keycloak_enabled: useRuntimeConfig().public.isKeycloakEnabled,
    disabled_toggler_items: ["sms", "email"],
  }),
  persist: [
    {
      enabled: true,
      key: null,
      pick: [
        "user.id",
        "user.email",
        "user.username",
        "user.token",
        "user.first_name",
        "user.last_name",
        "user.title",
        "user.mobile",
        "user.telephone",
        "user.company",
        "user.vat_number",
        "user.account_type",
        "guest.id",
        "guest.token",
        "guest_item.email",
        "is_guest_checkout",
      ],
    },
    {
      enabled: true,
      key: null,
      pick: ["guest"],
      storage: import.meta.client ? localStorage : undefined,
    },
  ],
  getters: {
    is_authenticated: (state): boolean => !(state.user == null),
    is_guest: (state): boolean => !(state.guest == null),
  },
  actions: {
    async setCustomerTokenInCookie(customer: any) {
      try {
        const access_token = useCookie(AppCookieNames.CustomerJWT, {
          maxAge: 60 * 60 * 24 * 3,
        });

        access_token.value = customer.token;
      } catch (e) {
        await useErrorHandler(e);
        return e;
      }
    },

    async setAuthUserByToken(
      show_notification = true,
      show_loading_indicator = true
    ) {
      try {
        const access_token = useCookie(AppCookieNames.CustomerJWT);

        if (!access_token.value) {
          this.user = null;
          return false;
        }

        const decoded = VueJwtDecode.decode(access_token.value);

        if (!decoded || !decoded.customer_id) {
          this.user = null;
          return false;
        }

        let customer_id = decoded.customer_id;

        let headers = {
          "X-Toolstation-Customer-Id": access_token.value,
        };

        let res = await EcomCustomerService.getCustomerById(
          customer_id,
          show_notification,
          show_loading_indicator,
          headers
        );

        this.user = null;
        if (res && res.data && res.data.id) {
          this.user = res.data;

          /* if default branch is found in customer resource and customer has not choosen any current branch*/
          if ((this.user as any).default_branch) {
            await this.setAuthUserDefaultBranch(
              (this.user as any).default_branch
            );
          }
        }
      } catch (e) {
        await useErrorHandler(e);
        return e;
      }
    },

    async setAuthUserDefaultBranch(defaultBranchId: string) {
      const branchStore = useBranchStore();
      const ecomService = new EcomService();
      try {
        const response = await ecomService.fetchBranchById(defaultBranchId);
        if (!response || !response.data) {
          throw new Error("could not get customer default branch details");
        }
        branchStore.defaultBranch = response.data;
        if (
          !branchStore.currentSavedBranch ||
          !branchStore.currentSavedBranch.id
        )
          branchStore.lastSavedBranch = response.data;
      } catch (err) {
        useErrorHandler(err, "critical");
      }
    },

    async postSignIn() {
      try {
        this.success_message = "";
        this.error_message = "";
        this.is_sign_in_button_loading = true;

        this.sign_in_form_error = this.getEmptySignInFields();

        const result = signInSchema.safeParse(this.item);

        if (result.success === false) {
          let current_path = "";

          result.error.errors.forEach((error) => {
            const path = error.path.join(".");
            const message = error.message;

            if (current_path !== path) {
              this.sign_in_form_error[path] = message;
              current_path = path;
            }
          });

          this.is_sign_in_button_loading = false;
          return;
        }

        let res = await EcomCustomerService.signIn(
          this.item.username,
          this.item.password
        );

        if (res && res.data && res.data.id) {
          this.user = res.data;
          this.setCustomerTokenInCookie(res.data);

          const trolleyStore = useTrolleyStore();

          /* Attempts to get the last active trolley of the user */
          await trolleyStore.setActiveCustomerTrolley(
            (this.user as any)?.id,
            (this.user as any)?.token
          );

          /* convert guest trolley to customer trolley only if local trolley exists and no active one is found */
          if (trolleyStore.trolley_id && !trolleyStore.last_active_trolley_id)
            await trolleyStore.convertToCustomer();

          useCheckoutStore().resetPersistAddresses();

          /* if default branch is found in customer resource set it accordingly */
          if ((this.user as any).default_branch) {
            await this.setAuthUserDefaultBranch(
              (this.user as any).default_branch
            );
          }

          const redirect_path = useCookie("after_auth_redirect_path");
          const localePath = useLocalePath();
          if (redirect_path.value && !redirect_path.value.includes("/auth/")) {
            navigateTo(localePath(redirect_path.value));
            redirect_path.value = null;
          } else {
            navigateTo(localePath("/"));
          }
          useRootStore().after_auth_intended_route = null;
        } else {
          this.is_sign_in_button_loading = false;
          this.error_message = "Invalid credentials.";
        }
      } catch (e) {
        this.is_sign_in_button_loading = false;
        await useErrorHandler(e);
        return e;
      }
    },

    async checkUsername() {
      try {
        this.guest_error_message = "";
        this.is_guest_sign_in_button_loading = true;

        let res = await EcomCustomerService.usernameCheck(
          this.guest_item.email
        );

        if (res && res.data && res.data.exists) {
          this.item.username = this.guest_item.email;
          this.item.password = "";
          this.guest_error_message =
            "This username already exists. Please enter your password.";
          this.sign_in_form_error.username = "";
          this.sign_in_form_error.password = "Enter your password.";
          this.is_guest_sign_in_button_disabled = true;
        } else {
          this.is_guest_checkout = true;
          const localePath = useLocalePath();
          navigateTo(localePath("/checkout"));
        }
        this.is_guest_sign_in_button_loading = false;
      } catch (e) {
        this.is_guest_sign_in_button_loading = false;
        await useErrorHandler(e);
        return e;
      }
    },
    resetSignIn() {
      this.item = this.getEmptySignInFields();
      this.item = this.getEmptySignInFields();

      this.error_message = "";
      this.is_sign_in_button_loading = false;
    },

    async sendForgotPasswordMail() {
      try {
        this.success_message = "";
        this.error_message = "";
        this.is_forgot_password_button_loading = true;

        let res = await EcomCustomerService.sendForgotPasswordMail(
          this.forgot_password_item.email
        );

        if (res && res.data && res.data.msg) {
          this.forgot_password_item.email = "";
          this.success_message = res.data.msg;
        } else {
          this.error_message = "Email is invalid.";
        }

        this.is_forgot_password_button_loading = false;
      } catch (e) {
        this.is_forgot_password_button_loading = false;
        await useErrorHandler(e);
        return e;
      }
    },

    async postResetPassword() {
      try {
        this.success_message = "";
        this.error_message = "";
        this.is_reset_password_button_loading = true;

        let res = await EcomCustomerService.resetPassword(
          this.reset_password_item
        );

        if (res && res.data) {
          if (
            res.data.error &&
            res.data.error.data &&
            res.data.error.data.message
          ) {
            this.reset_password_item.password_confirmation = "";
            this.error_message = res.data.error.data.message;
          } else if (res.data === "passwords.reset") {
            this.item.username = this.reset_password_item.email;
            this.item.password = this.reset_password_item.password;
            await this.postSignIn();
          }
        }

        this.is_reset_password_button_loading = false;
      } catch (e) {
        this.is_reset_password_button_loading = false;
        await useErrorHandler(e);
        return e;
      }
    },

    async signOut() {
      try {
        let jwt = useCookie(AppCookieNames.CustomerJWT);

        jwt.value = null;
        this.user = null;
        useTrolleyStore().destroyTrolley();
        useSavelistStore().destroyUserSavelist();
        useCheckoutStore().resetPersistAddresses();
        const localePath = useLocalePath();
        useBranchStore().resetPersistingBranches();
        navigateTo(localePath("/"));
      } catch (e) {
        await useErrorHandler(e);
        return e;
      }
    },

    async signUp() {
      try {
        this.sign_up_form_error_message = "";
        this.is_sign_up_button_loading = true;

        if (this.sign_up_form_error.password) {
          this.is_sign_up_button_loading = false;
          return;
        }

        this.sign_up_form.first_name = this.sign_up_form.first_name.trim();
        this.sign_up_form.last_name = this.sign_up_form.last_name.trim();


        // Reset error messages
        this.sign_up_form_error = {
          title: "",
          first_name: "",
          last_name: "",
          email: "",
          mobile: "",
          password: "",
          company: "",
          address: {
            type: 1,
            town: "",
            postcode: "",
            line_1: "",
            line_2: "",
            line_3: "",
            country: "",
            country_id: 8,
          },
          contact_preferences: {
            email: false,
            mobile: false,
            sms: false,
            post: true,
          },
          terms_accepted: true,
        };

        // Validate the form data using Zod
        const result = signUpSchema.safeParse(this.sign_up_form);

        if (result.success === false) {
          let scroll_id = "signup-address-fields";
          // If validation fails, map Zod errors to your error state
          result.error.errors.forEach((error) => {
            const path = error.path.join(".");
            const message = error.message;

            this.sign_up_form_error[path] = message;

            if (!error.path.includes("address")) {
              scroll_id = "signup-contact-fields";
            }
          });

          if (
            !this.sign_up_form.address.town.length ||
            !this.sign_up_form.address.postcode.length ||
            !this.sign_up_form.address.line_3.length ||
            !this.sign_up_form.address.line_1.length
          ) {
            this.sign_up_form_error.address.town =
              "Complete address is required.";
          }

          window.scrollTo({
            top: document.getElementById(scroll_id).offsetTop - 130,
            behavior: "smooth",
          });

          this.is_sign_up_button_loading = false;
          return;
        }

        // Proceed with the API call if validation passes
        let res = await EcomCustomerService.signUp(this.sign_up_form);

        if (res.success === false && res.data.error.code === "422.99") {
          this.is_sign_up_button_loading = false;
          let errors = res.data.error.data.errors;
          this.sign_up_form_error = res.data.error.data.errors;
          this.sign_up_form_error_message = res.data.error.data.message;

          window.scrollTo({
            top:
              document.getElementById("signup-contact-fields").offsetTop - 200,
            behavior: "smooth",
          });

          return;
        }

        if (res && res.data && res.data.id) {
          this.user = res.data;
          this.setCustomerTokenInCookie(res.data);
          const redirect_path = useCookie("after_auth_redirect_path");
          const localePath = useLocalePath();
          if (redirect_path.value) {
            navigateTo(localePath(redirect_path.value));
            redirect_path.value = null;
          } else {
            navigateTo(localePath("/"));
          }
          useRootStore().after_auth_intended_route = null;

          this.clearSignupForm();
          //-------- convert guest trolley to customer if exists
          const trolleyStore = useTrolleyStore();
          if (trolleyStore.trolley_id) await trolleyStore.convertToCustomer();
          this.is_sign_up_button_loading = false;
        }
      } catch (err) {
        this.is_sign_in_button_loading = false;
        useErrorHandler(err, "low");
      }
    },

    async postGuestSignUp() {
      try {
        this.guest_error_message = "";
        this.is_guest_sign_in_button_loading = true;

        this.guest_form_error = this.getEmptyGuestItem();

        const result = guestSchema.safeParse(this.guest_item);

        if (result.success === false) {
          let scroll_id = "guest-checkout-address-fields";
          // If validation fails, map Zod errors to your error state
          result.error.errors.forEach((error) => {
            const path = error.path.join(".");
            const message = error.message;

            this.guest_form_error[path] = message;

            if (!error.path.includes("address")) {
              scroll_id = "guest-contact-fields";
            }
          });

          if (
            !this.guest_item.address.town.length ||
            !this.guest_item.address.postcode.length ||
            !this.guest_item.address.line_3.length ||
            !this.guest_item.address.line_1.length
          ) {
            this.guest_form_error.address.town =
              "Complete address is required.";
          }

          window.scrollTo({
            top: document.getElementById(scroll_id).offsetTop - 130,
            behavior: "smooth",
          });

          this.is_guest_sign_in_button_loading = false;
          return {
            success: false,
          };
        }

        let res = await EcomCustomerService.postGuestSignUp(
          this.guest_item,
          useTrolleyStore().trolley_session_token
        );

        this.is_guest_sign_in_button_loading = false;

        if (res.success === false) {
          return res;
        } else {
          this.guest = res.data;
        }
        return res;
      } catch (err) {
        useErrorHandler(err, "low");
        this.is_guest_sign_in_button_loading = false;
        return error;
      }
    },

    clearSignupForm() {
      this.sign_up_form = this.getEmptySignUnFields();
      this.sign_up_form_error = this.getEmptySignUnFields();

      this.sign_up_form_error_message = "";
    },

    getEmptyGuestItem() {
      return {
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        mobile: "",
        terms_accepted: false,
        address: {
          type: 1,
          town: "",
          postcode: "",
          line_1: "",
          line_2: "",
          line_3: "",
          country: "",
          country_id: 8,
        },
      };
    },

    validatePassword(password: string) {
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
      return regex.test(password);
    },

    validateEmail(email: string) {
      // Regex to ensure at least one letter is present
      // const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const regex = /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return regex.test(email);
    },

    getEmptySignInFields() {
      return {
        username: "",
        password: "",
      };
    },

    getEmptySignUnFields() {
      return {
        title: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        password: "",
        company: "",
        address: {
          type: 1,
          town: "",
          postcode: "",
          line_1: "",
          line_2: "",
          line_3: "",
          country: "",
          country_id: 8,
        },
        contact_preferences: {
          email: false,
          mobile: false,
          sms: false,
          post: true,
        },
        terms_accepted: true,
      };
    },

    async getStockNotifications() {
      const customerId = (this.user as any)?.id;
      if (!customerId) return;
      await useAccountStore().fetchStockNotifications(customerId);
    },

    async onStockUnsubscribe(product_code: string) {
      const rootStore = useRootStore();
      try {
        await useProductStore().unSubscribeProduct(product_code);
        await useAuthStore().getStockNotifications();
        // rootStore.toastSuccess(["Successfully unsubscribed!"], "top-right");
      } catch (err) {
        useErrorHandler(err, "medium");
        rootStore.toastErrors(
          ["Failed to unsubscribe. Try again"],
          "top-right"
        );
      }
    },

    async fetchOneTimeQrCode(customerId: string) {
      try {
        const response = await EcomCustomerService.getOneTimeQrCodeUserDetails(customerId);
        if (response) {
          useAuthStore().user = response.data;
        }
      } catch (err) {
        useErrorHandler(err, "medium");
      }
    }
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
