import type { Branch, NearestBranchRequest } from "~/types/ecom/branch.type";
import { useAjaxEcom } from "../composables/useAjaxEcom";
import type { Product } from "~/types/ecom/product/product.type";
import type {
  ProductStockRequest,
  ProductStockResponse,
} from "~/types/ecom/product/stock.type";

// TODO: move to customer service
export const CUSTOMER_ENDPOINTS = {
  GUEST_TROLLEYS: `/customers/guest/trolleys`,
  CUSTOMER_TROLLEYS: (customerId: string) =>
    `/customers/${customerId}/trolleys`,
};

export const LOCALITY_ENDPOINTS = {
  AUTOCOMPLETE: `/location-search/localities/autocomplete`,
};

export const NEWSLETTER_ENDPOINTS = {
  SIGNUP: `/newsletters/signup`,
};

export class EcomError extends Error {
  public constructor(message: string) {
    super();
    this.message = message;
  }
}

export type TEcomService = {
  fetchAllBranches: () => Promise<{ data: Branch[] }>;
  fetchBranchById: (id: string) => Promise<{ data: Branch }>;
  fetchNearestBranches: (
    request: NearestBranchRequest
  ) => Promise<{ data: Branch[] }>;
  fetchAllProducts: (request: {
    products: string[];
  }) => Promise<{ data: Product[] }>;
  fetchStockProduct: (
    request: ProductStockRequest
  ) => Promise<{ data: ProductStockResponse[] }>;
  fetchEcomLocalityDetials: (
    input: string,
    countries?: string[]
  ) => Promise<any>;
  refreshToken: () => Promise<string>;
};

/* ECOM API SERVICE */
export class EcomService implements TEcomService {
  public static accessTokenExpiry: number;
  public static newTokenGeneratedAt: number = 0;
  public static accessTokenRequestBuffer: number = 300;

  /* Fetch all ts offline branches */
  async fetchAllBranches(): Promise<{ data: Branch[] }> {
    // @ts-ignore
    return await useAjaxEcom("/branches", {
      show_notifications: false,
      show_loading_indicator: false,
    });
  }

  /* Fetch a ts offline branch details based on its ID */
  async fetchBranchById(branchId: string): Promise<{ data: Branch }> {
    // @ts-ignore
    return await useAjaxEcom(`/branches/${branchId}`);
  }

  /* Fetch nearest branches based on postcode or latlang */
  async fetchNearestBranches(
    requestBody: NearestBranchRequest
  ): Promise<{ data: Branch[] }> {
    // @ts-ignore
    return await useAjaxEcom("/branches/nearest", {
      method: "post",
      params: requestBody,
      show_notifications: false,
      show_loading_indicator: false,
    });
  }

  /* Get products details from ECOM */
  static async fetchAllProducts(productRequest: {
    products: string[];
  }): Promise<{ data: Product[] }> {
    // @ts-ignore
    return await useAjaxEcom("/products/getProducts", {
      method: "post",
      params: productRequest,
      show_loading_indicator: false,
    });
  }

  /* Get product stock details from ECOM >>>> site = branch */
  static async fetchStockProduct(
    stockRequest: ProductStockRequest
  ): Promise<{ data: ProductStockResponse[] }> {
    // @ts-ignore
    return await useAjaxEcom("/stock/getStock", {
      method: "post",
      params: stockRequest,
      show_loading_indicator: false,
      show_notifications: false,
    });
  }

  /* Directly get suggestions with place latitude, longitude from ECOM - woosmap is used by backend */
  static async fetchEcomLocalityDetails(
    input: string,
    countries: string[] = ["nl"]
  ): Promise<any> {
    const components = countries
      .map((countryCode) => `country:${countryCode}`)
      .join("|");

    const localityQuery: any = {
      private_key: "89b69524-615b-42c1-b5ae-1aacaae3c145",
      input,
      types: "locality|postal_code",
      components,
    };

    //@ts-ignore
    return await useAjaxEcom(LOCALITY_ENDPOINTS.AUTOCOMPLETE, {
      query: localityQuery,
    });
  }

  /* Newsletter subscribe method */
  static async subscribeToNewsletter(options: {
    email_address: string;
    ip?: string;
  }) {
    const requestBody: any = {
      email_address: options.email_address,
    };

    if (options.ip) requestBody["ip"] = options.ip;

    

    //@ts-ignore
    return await useAjaxEcom(NEWSLETTER_ENDPOINTS.SIGNUP, {
      method: "post",
      params: requestBody,
      show_loading_indicator: false,
    });
  }

  /* Refresh the ecom_access_token in cookies once expired*/
  static async getTopBrandsData() {
    const res = await useAjaxEcom("/content/pages/top-brand-menu");
    return res;
  }

  static async getAllBrandsData() {
    const res = await useAjaxEcom("/content/brands");
    return res;
  }

  static async postContactFormData(options: {
        email: string;
        name: string;
        feedback: string;
        feedback_type: string;
        order_number: string;
  }) {
    return await useAjaxEcom("/contact/feedback", {
      method: "post",
      params: options,
    });
  }

  static async getCategoriesData(cache = false) {
    const res = await useAjaxEcom("/taxonomies",{
      show_notifications:false,
      show_loading_indicator:false,
      cache
    });
    return res;
  }

  static async getCategoryDetailsById(taxonomyId: string) {
    const res = await useAjaxEcom(`/taxonomies/${taxonomyId}`,{
      show_notifications:false,
      show_loading_indicator:false
    });
    return res;
  }

  /* Refresh the ecom_access_token in cookies once expired*/
  static async refreshToken() {
    try {
      const res = await $fetch<any>("/api/ecom/access-token");
      this.accessTokenExpiry = res.expires_in;
      this.newTokenGeneratedAt = Date.now() / 1000;
      return res.access_token;
    } catch (error) {
      useErrorHandler(error, "critical");
    }
  }
}
