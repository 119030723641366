<template>
  <TsCard id="trustpilot-review"
          data-testid="trustpilot-review"
          no-shadow
          append-class="mb-6 lg:row px-4 py-3"
          body-class="column mt-auto"
          footer-class="pt-0 column mt-auto"
  >
    <template #content>

      <TsRow  append-class="mx-0 items-center mb-4">
        <NuxtImg
            src="/icons/star-success.svg"
            class="mr-1"
            width="31"
            height="29"
        />

        <TsTypography
            as="h2"
            append-class="mb-0 font-semibold"
        >
          Trustpilot
        </TsTypography>
      </TsRow>

      <TsRow isMultiline no-gutter >
        <TsColumn class="lg:is-5 is-12 ">
          <TsTypography
              as="h3"
              append-class="mb-4 mt-1 w-full font-semibold"
          >
            Rate your recent experience
          </TsTypography>
        </TsColumn>

        <TsRow no-gutter appendClass="mx-auto">
          <TsColumn v-for="i in 5" :key="i" append-class="grow-0 mr-2">
            <div class="p-1 bg-natural-light-grey min-w-max">
              <NuxtImg
                  src="/icons/star.svg"
                  width="24"
                  height="22"
              />
            </div>
          </TsColumn>
        </TsRow>
      </TsRow>


    </template>
    <template #footer>
      <TsRow isMultiline no-gutter class="gap-2 lg:mt-0 mt-5">
        <TsColumn appendClass="lg:block hidden"></TsColumn>
        <TsColumn class="lg:is-4  max-sm:is-12">
          <TsButton
              label="Submit rating"
              disabled
              block
          />
        </TsColumn>
        <TsColumn class="lg:is-4 max-sm:is-12">
          <TsButton
              label="Write a review"
              variant="success"
              outlined
              block
          />
        </TsColumn>
      </TsRow>
    </template>
  </TsCard>



</template>
<script>
import TsColumn from "./TsColumn";
export default {
  components: {TsColumn}
}
</script>
