export default defineNuxtRouteMiddleware(async (to, from) => {
  const redirectRules = [
    {
      pattern: /^\/([^/]+)\/c(\d+)$/, // category pattern
      handler: ([, category, id]) => {
        const newCategory = category.replace('-', '&-');
        return `/categories/${newCategory}/${id}`;
      },
    },
    {
      pattern: /^\/([^/]+)\/([^/]+)\/c(\d+)$/, // Sub category pattern
      handler: ([, mainCategory, subCategory, id]) => {
        return `/categories/${subCategory}-${id}`;
      },
    },
    {
      pattern: /^\/([^/]+(?:-[^/]+)*)\/p(\d+)$/, // Product pattern
      handler: ([, productName, productId]) => {
        return `/product/${productName}-${productId}`;
      },
    },
  ];

  for (const { pattern, handler } of redirectRules) {
    const match = to.path.match(pattern);
    if (match) {
      const newPath = handler(match);
      return await navigateTo(newPath, { redirectCode: 301 });
    }
  }
});

