import { acceptHMRUpdate, defineStore } from 'pinia';
import { fetchEntries, fetchOneEntry } from '@builder.io/sdk-vue';
import { BuilderService } from "../services/builder.service";
import type { APIState } from '~/types/api-state.type';

export const useBuilderStore = defineStore({
  id: 'builder',
  state: () => ({

    admin_api_url: "https://cdn.builder.io/api/v2/admin",
    vaah_cms_url: useRuntimeConfig().public.mockApiBaseUrl + '/eu24web/builder/translation-strings/',
    auth_bearer_token: `Bearer ${process.env.NUXT_PUBLIC_BUILDER_PRIVATE_KEY_DEVELOP}`,
    settings: null,
    content: null,
    content_path: {},
    locales: null,
    builder_api_key: useRuntimeConfig().public.builderApiKey,
    builder_api_pvt_key: useRuntimeConfig().public.builderApiPvtKey,
    list: [] as Array<any>,
    item: {} as Object,
    currentDate: new Date().getTime(),
    query: {} as Object,
    month_array: [] as Array<string>,
    router: null,
    blogs: [] as Array<any>,
    news: [] as Array<any>,
    advices: [] as Array<any>,
    error_messages: [] as Array<any>,
    interested_count: 0,
    is_interested: false,
    builderObject: null as BuilderService | null,
    blogsCategories: [] as Array<any>,
    topBrands: [] as Array<any>,
    brands: [] as Array<any>,
    brands_state: <APIState>{
      status: 'idle',
      data: {},
      message: ''
    },

    blog_type_list: [
      {
        name: 'Blogs',
        slug: 'blogs'
      },
      {
        name: 'News',
        slug: 'news'
      },
      {
        name: 'Advices',
        slug: 'advices'
      },
      {
        name: 'All',
        slug: 'all'
      },
    ]
  }),
  getters: {},
  actions: {
    init() {
      this.builderObject = new BuilderService(this.builder_api_key);
    },

    async getSettings() {

      let options: any = {
        method: "post",
        show_notifications: false,
        params: {
          'query': "{settings}"
        },
        headers: {
          "Authorization": `${this.auth_bearer_token}`
        }
      }

      await useAjax(this.admin_api_url, options, this.getSettingsAfter)
    },
    async getSettingsAfter(data: any, res: any) {

      if (data && data.settings) {
        this.settings = data.settings;
        this.locales = this.settings.customTargetingAttributes?.locale?.descriptions;
      }

    },
    //---------------------------------------------------------------------
    async getTranslatedStrings(locale: string) {
      let options: any = {
        method: "get",
        show_notifications: false,
        show_loading_indicator: false,
        headers: {
          "Authorization": `${this.auth_bearer_token}`
        }
      }

      const res: any = await useAjax(`${this.vaah_cms_url}${locale}`, options);

      if (res && res.data && typeof res.data === 'object' && Object.keys(res.data).length > 0) {
        return res.data
      }

      return null;
    },

    isEmpty(obj: any) {
      for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
          return false;
        }
      }

      return true;
    },

    async getBlogList(model: string, query = {}, sort = {}, limit = 10, offset = 0) {
      try {
        this.blogs = await new BuilderService(this.builder_api_key).getList(model, query, sort, limit, offset);
        return this.blogs
      } catch (e) {
        await useErrorHandler(e);
        this.error_messages = [e.message];
        return e;
      }
    },
    async fetchBlogCategories() {
      const categories = await this.getBlogList('blogs');

      const extractUniqueCategories = () => {
        const uniqueBlogCategories = new Set();
        categories.forEach(item => {
          if (item.data.categories && item.data.categories.value && item.data.categories.value.data) {
            const categoryData = item.data.categories.value.data;
            uniqueBlogCategories.add(JSON.stringify({ name: useTranslation(categoryData.name,categoryData.name), slug: categoryData.slug }));
          }

        });
        return uniqueBlogCategories;
      };

      this.blogsCategories = Array.from(extractUniqueCategories()).map(item => JSON.parse(item));

      const staticCategory = { name: useTranslation("all","Alle"), slug: 'all' };
      this.blogsCategories.unshift(staticCategory);
    },

    async getBlogItem(model, query = {}, sort = {}) {
      try {
        if (!this.builderObject) {
          this.builderObject = new BuilderService(this.builder_api_key);
        }
        this.blog = await this.builderObject.getItem(model, query, sort);
      } catch (e) {
        await useErrorHandler(e);
        this.error_messages = [e.message];
        return e;
      }
    },

    async getNewsList(model: string, query = {}, sort = {}, limit: number = 100, offset: number = 0) {
      try {
        if (!this.builderObject) {
          this.builderObject = new BuilderService(this.builder_api_key);
        }
        this.news = await this.builderObject.getList(model, query, sort, limit, offset);
        return this.news;
      } catch (e) {
        await useErrorHandler(e);
        this.error_messages = [e.message];
        return e;
      }
    },

    async getNewsItem(model, query = {}, sort = {}) {
      try {
        if (!this.builderObject) {
          this.builderObject = new BuilderService(this.builder_api_key);
        }
        this.news = await this.builderObject.getItem(model, query, sort);
      } catch (e) {
        await useErrorHandler(e);
        this.error_messages = [e.message];
        return e;
      }
    },

    async getAdviceList(query = {}, sort = {}, limit: number = 100, offset: number = 0) {
      try {
        let model = 'advices';
        if (!this.builderObject) {
          this.builderObject = new BuilderService(this.builder_api_key);
        }
        this.advices = await this.builderObject.getList(model, query, sort, limit, offset);
      } catch (e) {
        await useErrorHandler(e);
        this.error_messages = [e.message];
        return e;
      }
    },

    async getAdviceItem(model, query = {}, sort = {}) {
      try {
        if (!this.builderObject) {
          this.builderObject = new BuilderService(this.builder_api_key);
        }
        this.advice = await this.builderObject.getItem(model, query, sort);
      } catch (e) {
        await useErrorHandler(e);
        this.error_messages = [e.message];
        return e;
      }
    },



    async fetchAllBrands(query = {}, sort = { createdDate: 1 }, limit: number = 100, offset: number = 0) {
      useStateModifier(this.brands_state, 'loading');
      try {
        if (!this.builderObject) {
          this.builderObject = new BuilderService(this.builder_api_key);
        }
        this.brands = await this.builderObject.getList('brands', query, sort, limit, offset) as any[];
        if (this.brands.length <= 0) {
          throw "Couldn't load brands, try ahain later"
        } else {
          useStateModifier(this.brands_state, 'success');

        }
      }
      catch (err) {
        useStateModifier(this.brands_state, 'failed');

      }
    },


    async fetchTopBrands() {
      try {
        if (this.brands.length <= 0) {
          await this.fetchAllBrands()
        }

        this.topBrands = this.brands.map(({ data }) => {
          return { ...data?.topBrand, title: data.title }
        })?.filter((item) => item?.isTop);
      } catch (e) {
        await useErrorHandler(e);
        this.error_messages = [e.message];
        return e;
      }
    },


    async fetchBrandBySlug(slug) {
      try {

        const builderObject = new BuilderService(this.builder_api_key);


        let userAttributes = {
          urlPath:slug
        }

        return await builderObject.getItem('brands', userAttributes);
      } catch (e) {
        await useErrorHandler(e);
        this.error_messages = [e.message];
        return e;
      }
    },

    async getItem(model = 'pages', userAttributes = {}) {
      let apiKey = this.builder_api_key;
      try {
        this.item = await fetchOneEntry({
          model,
          apiKey,
          userAttributes
        });

      } catch (error) {
        await useErrorHandler(error);
      }
    },
    async getList(model = 'pages', query = {},
      sort = {}, limit = 100, offset = 0) {
      let apiKey = this.builder_api_key;
      try {
        this.list = await fetchEntries({
          model,
          apiKey,
          query,
          sort,
          limit,
          offset
        });
      } catch (error) {
        await useErrorHandler(error);
      } finally {
        this.filtered_list = this.list;
        //initial month names
        this.createMonthArray();
      }
    },

    // -------------------------Events functions-------------------------------
    /**
     * Function for updating url query string as per requirements
     * @param filter
     * @param route
     * @param router
     */
    async updateUrlString(filter: string, route, router) {
      // Assigning query state to a local variable
      const query = { ...route.query };

      // Resetting relevant query parameters based on filter type
      if (filter === 'past' || filter === 'upcoming') {
        delete query.past;
        delete query.upcoming;
        query[filter] = true;
      } else if (filter === 'Online' || filter === 'Offline') {
        query.mode = filter.toLowerCase();
      } else {
        query.q = filter;
      }

      // Updating URL String
      await router.push({ path: route.path, query });
    },

    /**
     * Fetching past or upcoming events
     * @param route
     * @param mode
     */
    async fetchEvents(route, mode = null) {
      let query = {};
      if (route === 'past') {
        query = { 'data.dateAndTime': { $lt: this.currentDate } };
      } else if (route === 'upcoming') {
        query = { 'data.dateAndTime': { $gte: this.currentDate } };
      }
      if (mode) {
        query['data.eventmode'] = mode;
      }
      await this.getList('events', query, { 'data.dateAndTime': 1 });
    },
    handleMonthSelect(e) {
      if (e === "All") {
        this.filtered_list = this.list;
        return;
      }
      this.filtered_list = this.list.filter((item) => {
        const date = new Date(item.data.dateAndTime);
        const month = date.toLocaleString('default', { month: 'short' });
        return month === e;
      })
    },
    createMonthArray() {
      if (this.month_array.length > 0 || this.list.length <= 0) return;
      let monthSet = new Set();
      this.list.forEach((item) => {
        const date = new Date(item.data.dateAndTime);
        const month = date.toLocaleString('default', { month: 'short' });
        monthSet.add(month);
      });
      this.month_array = ['All', ...Array.from(monthSet)];
    },
    handleInterested(customer_id: number) {
      const interested_customers = this.item?.data?.interestedCustomers;

      if (typeof interested_customers === 'undefined') {
        this.item.data.interestedCustomers = [];
      }

      try {
        if (interested_customers?.includes(customer_id)) {
          this.item.data.interestedCount--;
          this.item.data.interestedCustomers = this.item?.data?.interestedCustomers.filter(id => id !== customer_id);
        } else {
          this.item.data.interestedCount++;
          this.item?.data?.interestedCustomers.push(customer_id);
        }

        this.updateInterestedCount();
      }
      catch (e) {
        this.is_interested = !this.is_interested;
      }
    },
    async updateInterestedCount() {
      try {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${this.builder_api_pvt_key}`);
        //@ts-ignore
        const response = await $fetch(`https://builder.io/api/v1/write/events/${this.item.id}`,
          {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify(this.item)
          });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        console.log('Response:', response);
      } catch (error) {
      }
    },
    /**
     * Function for adding infix in url
     * @param url
     * @param infix
     */
    addInfixToUrl(url: string, infix: string) {
      let urlArray = url.split('/');
      urlArray.splice(2, 0, infix);
      return urlArray.join('/');
    },
    /**
     * Function for removing added infix in url
     * @param url
     * @param infix
     */
    removeInfixFromUrl(url: string, infix: string) {
      let urlArray = url.split('/');
      const infixIndex = urlArray.indexOf(infix);
      if (infixIndex !== -1) {
        urlArray.splice(infixIndex, 1);
      }
      return urlArray.join('/');
    },
    // -------------------Events End------------------------



    //---------------------Blogs, New & Advice Begin-----------------------------------

    async routeUrl(filter: string, route, router) {
      // Assigning query state to a local variable
      const query = { ...route.query };

      query.q = filter;

      // Updating URL String
      await router.push({ path: route.path, query });
    },

    //-----------------------Redirect to blogs , news and advice ------------------------

    async redirectToBlogs() {
      const router = useRouter()
      router.push({ path: '/blogs' });
    },

    async redirectToAdvices() {
      const router = useRouter()
      router.push({ path: '/advices' });
    },

    async redirectToNews() {
      const router = useRouter()
      router.push({ path: '/news' });
    },


    //--------------------------Blogs-----------------------
    async redirectToBlogsWithCategory(category) {
      const router = useRouter()
      router.push({ path: '/blogs/search', query: { category } });
    },
    async redirectToBlogsWithTags(tag) {
      const router = useRouter()
      router.push({ path: '/blogs/search', query: { tag } });
    },
    async redirectToBlogsWithInputQuery(q) {
      const router = useRouter()
      router.push({ path: '/blogs/search', query: { q } });
    },
    async redirectToBlogsWithAuthor(author) {
      const router = useRouter()
      router.push({ path: `/blogs/author/${author}` });
    },
    //------------------------Advices-------------------------
    async redirectToAdvicesWithTags(tag) {
      const router = useRouter()
      router.push({ path: '/advices/search', query: { tag } });
    },
    async redirectToAdvicesWithInputQuery(q) {
      const router = useRouter()
      router.push({ path: '/advices/search', query: { q } });
    },
    async redirectToAdvicesWithCategory(category) {
      const router = useRouter()
      router.push({ path: '/advices/search', query: { category } });
    },
    async redirectToAdvicesWithAuthor(author) {
      const router = useRouter()
      router.push({ path: `/advices/author/${author}` });
    },
    //------------------------News-------------------------------
    async redirectToNewsWithTags(tag) {
      const router = useRouter()
      router.push({ path: '/news/search', query: { tag } });
    },
    async redirectToNewsWithCategory(category) {
      const router = useRouter()
      router.push({ path: '/news/search', query: { category } });
    },
    async redirectToNewsWithInputQuery(q) {
      const router = useRouter()
      router.push({ path: '/news/search', query: { q } });
    },
    async redirectToNewsWithAuthor(author) {
      const router = useRouter()
      router.push({ path: `/news/author/${author}` });
    },
    //----------------------BlogType-------------------------------
    async redirectToBlogType(item) {
      const router = useRouter();
      const routes = {
        news: '/news',
        advices: '/advices',
        blogs: '/blogs'
      };

      const path = routes[item.slug];
      if (path) {
        router.push({ path });
      }
    }
  }
});

// Pinia hot reload
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBuilderStore, import.meta.hot))
}

