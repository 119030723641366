<template>
  <TsAutocompleteV2
    v-model:visible="dropDownState"
    data-testid="header-search-input-field"
    :options="props.suggestions ?? []"
    :placeholder="props.placeholder"
    @focus="(q: any, dropdown: any) => handleFocus(q, dropdown)"
    @input="(q:any) => handleSearch(q)"
    @delete="(option:any) => handleDelete(option)"
    @enter="(searchQuery: string | undefined, dropdownVisible : boolean) => handleEnter(searchQuery, dropdownVisible)"
    dropdown-class="max-h-96"
  >
    <template #dropdown-parent="{ section }">
      <div v-for="(singleSection, s_key) in section">
        <div v-if="algoliaStore[singleSection.stateHandler].status !== 'idle'">
          <hr v-if="s_key > 0 && section[s_key - 1].options.length > 0" />
          <div
            class="section-header flex flex-row justify-between items-baseline p-1"
            v-if="singleSection.options.length > 0"
          >
            <TsTypography as="h3">
              {{ singleSection?.name }}
            </TsTypography>
            <TsTypography
              as="base"
              size="xs"
              class="hover:underline cursor-pointer"
              v-if="singleSection.canDelete"
              @click="algoliaStore.deleteEntireHistory()"
            >
              Clear all
            </TsTypography>
          </div>
          <div
            v-if="algoliaStore[singleSection.stateHandler].status === 'success'"
          >
            <div v-if="singleSection.options.length > 0">
              <div v-for="option in singleSection.options">
                <div
                  class="flex justify-between border items-center cursor-pointer hover:underline hover:bg-gray-100"
                  @click="
                    handleClick(
                      singleSection.name,
                      option.name,
                      option.slug,
                      option.productCode
                    )
                  "
                >
                  <NuxtImg
                    v-if="option.image"
                    :src="option.image"
                    class="w-16 p-1 border m-2"
                    placeholder="https://img.site/p/64/64/BDC3C8/solid-stripes?label=TS&label_color=080808&label_font_size=17"
                  ></NuxtImg>
                  <TsColumn class="flex flex-col items-start justify-evenly">
                    <span
                      v-if="option.name"
                      v-html="
                        useBoldMatchingText(
                          option.name,
                          props.lastSearchedKeyword
                        )
                      "
                    ></span>

                    <span>
                      <TsTypography
                        v-if="option.priceRange"
                        variant="base"
                        append-class="text-blue-800"
                      >
                        {{ option.priceRange }}
                      </TsTypography>
                    </span>
                  </TsColumn>
                  <ClientOnly>
                  <Icon
                    v-if="singleSection.canDelete"
                    name="material-symbols:delete"
                    size="12"
                    @click.stop="emit('delete', option)"
                    class="w-6 h-6 cursor-pointer hover:bg-gray-200 rounded-full p-1"
                    :data-testid="props.dataTestid + '-icon'"
                  />
                  </ClientOnly>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="algoliaStore[singleSection.stateHandler].status === 'loading'"
          >
            <TsSkeletonSearchResult></TsSkeletonSearchResult>
          </div>

          <div
            v-if="algoliaStore[singleSection.stateHandler].status === 'failed'"
          >
            <div class="card flex flex-col justify-center items-center p-4">
              <TsTypography as="h2">
                {{ algoliaStore[singleSection.stateHandler].message }}
              </TsTypography>
            </div>
          </div>
        </div>
        <div v-else-if="s_key === 1">
          <div class="card flex flex-col justify-center items-center p-4">
            <TsTypography as="h2">
              {{ handleIdleStateTypography() }}
            </TsTypography>
            <NuxtImg
              src="https://s3.amazonaws.com/ts-website-content/tsuk/DeWalt-Deals-Top-Banner-Desktop-V2.jpg"
              class="rounded w-full"
            >
            </NuxtImg>
          </div>
        </div>
      </div>
    </template>
  </TsAutocompleteV2>
</template>

<script setup lang="ts" generic="TAlgolia">
import type {
  ComputedSuggestions,
  options,
  Sections,
} from "~/types/algolia/algolia.type";

const dropDownState = ref<boolean>(false);

const algoliaStore = useAlgoliaStore();

const searchResultStore = useSearchResultStore();

onMounted(() => {});
type Props = {
  placeholder?: string;
  suggestions?: ComputedSuggestions[] | null;
  dataTestid?: string;
  lastSearchedKeyword: string;
};

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  input: [inputQuery: string];
  enter: any;
  delete: [option: options];
}>();

function handleSearch(query: string) {
  query = query.replace('"', '\\"').replace(/^\s+|\s+$/g, "");
  if (query !== "" && query.length > 0) {
    emit("input", query);
  }
}

const handleIdleStateTypography = (idleText?: string) => {
  return idleText ? idleText : "Buy something interesting today!";
};

const handleFocus = (q: any, dropdown: boolean) => {
  algoliaStore.currentDropdownStatus = dropdown;
};

function handleClick(
  sectionName: Sections,
  optionName: string,
  optionSlug: string,
  optionCode: number
) {
  if (
    sectionName === "Recently Searched" ||
    sectionName === "Popular Keywords"
  ) {
    algoliaStore.handleEnterClick(optionName, "direct-search", false);
  }
  if (sectionName === "Products") {
    const sanitizedOptionSlug = optionSlug.replace(/[!@#$%^&*()]/g, "-");
    navigateTo({
      path: `/product/${sanitizedOptionSlug}-${optionCode}`,
    });
  }
  if (sectionName === "Categories") {
    let slug_array = optionSlug.split("/");

    // navigateTo({
    //   path: `/categories/${slug_array[slug_array.length - 2]}-${optionCode}`,
    // });
    handleCategoryClick(slug_array[0], optionCode);
  }
}

function handleDelete(option: options) {
  emit("delete", option);
}

function handleEnter(keyword: string | undefined, dropdownVisible: boolean) {
  if (keyword && keyword.trim() !== "") {
    emit("enter", keyword, dropdownVisible);
  }
}

const handleCategoryClick = (
  subCategoryName: string,
  subCategoryId: number
) => {
  subCategoryName = subCategoryName.toLowerCase().replaceAll(" ", "-");
  algoliaStore.currentActiveCategoryFilter =
    bakeCategoryAsFilter(subCategoryId);

  algoliaStore.handleEnterClick(
    "",
    "category-search",
    false,
    [],
    "",
    bakeCategoryAsFilter(subCategoryId),
    subCategoryName,
    subCategoryId
  );
  // navigateTo(`/categories/${subCategoryName}/${subCategoryId}`);
};

const bakeCategoryAsFilter = (subCategoryId: number) => {
  return "categoryPageId:" + subCategoryId;
};
</script>
