import { DeliveryMethodCodes } from "~/assets/constants/ecomApi";
import EcomTrolleyService from "~/services/ecom.trolley.service";
import { PaymentService } from "~/services/payment.service.js";
import type { APIState } from "~/types/api-state.type";
import type {
  CustomerAddressItem,
  EcomPostNLAddressType,
} from "~/types/ecom/address.type";
import type { Branch } from "~/types/ecom/branch.type";
import { TrolleyStatus } from "~/types/ecom/trolley/trolley-map";
import type {
  TrolleyDeliveryMethodDetail,
  TrolleyLineRequest,
} from "~/types/ecom/trolley/trolley.type";
import type { PostNLPointResponse } from "~/types/postnl.type";

export enum PaymentMethod {
  CreditCard = "credit_card",
  Paypal = "paypal",
  GooglePay = "google_pay",
  LocalPay = "local_pay",
  ApplePay = "apple_pay",
}
export type BuyNowTrolleyResource = {
  id: string;
  session_id?: string;
  session_token: string;
  shipping_address: CustomerAddressItem;
  collection_address: Branch;
};

interface TransactionParams {
  trolley_id: string;
  order_total: number;
  billing_address_id: string;
  payment_type: string;
  payment_method: string;
  payment_nonce: string;
  payment_device_data: string;
  delivery_address_id?: string;
  collection_address_id?: string;
  next_day_collection_address_id?: string;
}

export const useCheckoutStore = defineStore("checkoutStore", {
  state: () => ({
    payment_service: null as PaymentService | null,
    buy_now_trolley: <BuyNowTrolleyResource>{},
    flash_payment_according_bg: false,
    order: {
      order_id: "",
      order_total: "",
      delivery_price: "",
      collection_price: "",
      products: [],
      collection_address_details: "",
      delivery_address_details: "",
    },
    payment_error: {
      gpay_error: "",
      hosted_field_error: "",
      paypal_error: "",
      ideal_error: "",
    },
    payment_success: "",
    po_number: "",
    clientToken: "",
    selected_ideal_bank: "",
    amount: useTrolleyStore().final_order_total_amount,
    user: useAuthStore().user,
    addresses: "",
    go_to_payment_state: <APIState>{
      status: "idle",
    },
    device_data: "",
    shipping_cost: 0,
    selected_collection_address: <Branch | null>null,
    selected_delivery_address: <CustomerAddressItem | null>null,

    currently_saved_post_nl_address_id: "",
    post_nl_address_ids: <string[]>[],

    billing_address: <CustomerAddressItem | null>null,
    is_billing_same_as_shipping: false,
    delivery_methods: <TrolleyDeliveryMethodDetail[]>[],
    cost_bearing_delivery_method: <
      TrolleyDeliveryMethodDetail | null | undefined
    >null,
    authorization_key: "",

    steps_completed: [],
    current_step: "address",

    is_branch_modal_visible: false,

    is_updating: false,
    is_editing_billing_address: false,
    is_ideal_loading: false,
    is_card_button_loading: false,
    is_card_button: false,
    is_order_loading: true,
    is_transaction_loading: false,
    ideal_bank_options: [
      {
        name: "Rabobank",
        code: "RABONL2U",
      },
      {
        name: "ABN AMRO",
        code: "ABNANL2A",
      },
      {
        name: "Van Lanschot Bankiers",
        code: "FVLBNL22",
      },
      {
        name: "Triodos Bank",
        code: "TRIONL2U",
      },
      {
        name: "ING Bank",
        code: "INGBNL2A",
      },
      {
        name: "SNS Bank",
        code: "SNSBNL2A",
      },
      {
        name: "ASN",
        code: "ASNBNL21",
      },
      {
        name: "RegioBank",
        code: "RBRBNL21",
      },
      {
        name: "Knab",
        code: "KNABNL2H",
      },
      {
        name: "Bunq",
        code: "BUNQNL2A",
      },
      {
        name: "Moneyou",
        code: "MOYONL21",
      },
      {
        name: "Handelsbanken",
        code: "HANDNL2A",
      },
    ],
  }),
  getters: {
    isAddresses() {
      if (
        (!this.selected_delivery_address &&
          !this.selected_collection_address) ||
        !this.billing_address?.id || this.is_editing_billing_address
      ) {
        return false;
      } else return true;
    },
  },
  persist: {
    enabled: true,
    key: null,
    storage: import.meta.client ? localStorage : undefined,
    pick: ["billing_address", "post_nl_address_ids"],
  },
  actions: {
    //-----------------------client token -------------------//

    async initialize() {
      try {
        await this.getPaymentToken();
        this.selected_ideal_bank = "";
        this.payment_service = new PaymentService();
        // Initialize all payment methods
        this.order = {
          order_id: "",
          order_total: "",
          delivery_price: "",
          collection_price: "",
          products: [],
          collection_address_details: "",
          delivery_address_details: "",
        };
        await this.initializePaymentMethods();
      } catch (error) {
        this.payment_error = "Failed to initialize checkout. Please try again.";
      }
    },

    async onCheckoutCollection(
      branch: Branch,
      productRequest: TrolleyLineRequest
    ) {
      try {
        // create buy now one time trolley
        const res = await EcomTrolleyService.createTrolley();
        if (!res || !res.data)
          throw new Error("unable to create new buy-now-collection trolley");
        this.buy_now_trolley.id = res.data.id;
        this.buy_now_trolley.session_token = res.data.session_token;
        this.buy_now_trolley.collection_address = branch;
        // add the product to the newly created trolley
        const addToTrolleyResponse = await EcomTrolleyService.addToTrolley(
          this.buy_now_trolley.id,
          productRequest,
          this.buy_now_trolley.session_token
        );
        // process response
        if (!addToTrolleyResponse || !addToTrolleyResponse.data)
          throw new Error(
            "unable to add the product in buy-now-collection trolley"
          );
        this.buy_now_trolley.session_token =
          addToTrolleyResponse.data.session_token;
        // mark the current trolleyStore trolley as "abandoned"
        await navigateTo("/checkout");
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      }
    },

    async getPaymentToken() {
      if (!this.authorization_key) {

        let id = '';

        const authStore = useAuthStore();

        if(authStore.user){
          id = authStore.user.id;
        }else if(authStore.guest){
          id = authStore.guest.id;
        }

        let response = await useAjaxEcom(
          `/payments/client-token/${id}`
        );

        if (response && response.data && response.data.token) {
          this.authorization_key = response.data.token;
        }
      }
    },

    //----------------initialize different payment methods-----------------//
    async initializePaymentMethods() {
      try {
        await this.initializePaymentMethod(PaymentMethod.CreditCard);
        await this.initializePaymentMethod(PaymentMethod.LocalPay);
        await this.initializePaymentMethod(PaymentMethod.GooglePay);
        await this.initializePaymentMethod(PaymentMethod.Paypal);
      } catch (error) {
        this.payment_error = "Failed to initialize payment methods.";
      }
    },

    async initializePaymentMethod(method: PaymentMethod) {
      try {
        if (!this.payment_service) {
          throw new Error("PaymentService is not initialized.");
        }

        await this.payment_service.initializeBraintree(this.authorization_key);
        await this.payment_service.setPaymentMethod(method);
        await this.payment_service.initializePayment();
      } catch (error) {
        this.payment_error = `Failed to initialize ${method}.`;
        console.error(error);
      }
    },

    async processPayment() {
      try {
        if (!this.payment_service) {
          throw new Error("PaymentService is not initialized.");
        }
        await this.payment_service.processPayment();
        this.payment_success = "Payment processed successfully.";
      } catch (error) {
        this.payment_error = "Failed to process payment. Please try again.";
        console.error(error);
      }
    },

    /* billing address setter */
    setBillingAddress(address: CustomerAddressItem | null) {
      this.billing_address = address;
    },

    /* shipping address setter */
    setShippingAddress(address: CustomerAddressItem | null) {
      this.selected_delivery_address = address;
      // set billing address if user selects a different address for delivery and toggle is on
      if (this.is_billing_same_as_shipping) this.setBillingAddress(address);
    },

    /* fetch shipping cost and delivery method details  for subsequent requests from ECOM */
    async getCheckoutDeliveryMethods(
      shippingAddress: CustomerAddressItem,
      collectionAddress?: Branch | null
    ) {
      this.setShippingAddress(shippingAddress);

      if (collectionAddress)
        this.selected_collection_address = collectionAddress;

      this.cost_bearing_delivery_method = null;

      const trolleyStore = useTrolleyStore();

      try {
        // should never come to this as a trolley must already exist in order to fetch delivery methods
        if (!trolleyStore.trolley_id) throw new Error("trolley not found");

        const { data: response } =
          await EcomTrolleyService.getCheckoutDeliveryMethods(
            trolleyStore.trolley_id,
              this.selected_delivery_address
                  ? this.selected_delivery_address.id
                  : undefined,
            // collection_address_id
            this.selected_collection_address
              ? this.selected_collection_address.address_id
              : undefined,
            trolleyStore.trolley_session_token
          );

        if (!response)
          throw new Error(
            "unable to fetch delivery methods for trolley during checkout"
          );

        this.delivery_methods = response;

        // TODO: Get the shipping cost bearing delivery method
        // this.cost_bearing_delivery_method = this.delivery_methods.find(
        //   (method) => method.code === DeliveryMethodCodes.DeliveryUnder
        // );

        this.shipping_cost = this.delivery_methods.reduce(
          (sum, currentMethod) => (sum += currentMethod.price),
          0
        );

        // Shipping cost needs to be associated
        this.delivery_methods.map(async (method) => {
          await this.calculateShippingCost(method.trolley_lines, method.code);
        });
      } catch (err) {
        useErrorHandler(err, "critical");
        return err;
      }
    },

    async calculateShippingCost(
      trolleyLines: number[],
      newDeliveryMethodCode: DeliveryMethodCodes
    ) {
      const trolleyStore = useTrolleyStore();
      for (const line of trolleyLines) {
        await EcomTrolleyService.updateTrolleyLineDeliveryMethod(
          line,
          {
            delivery_method_code: newDeliveryMethodCode,
          },
          trolleyStore.trolley_session_token
        );
      }
      await trolleyStore.setOrderTotals();
    },

    async handleCheckoutPostNL(savedPoint: PostNLPointResponse) {
      const postNLStore = usePostNLStore();

      postNLStore.post_nl_address_save_loading = true;

      try {
        // formatted post nl address
        const savedPostNLAddress: EcomPostNLAddressType = {
          line_1: `${savedPoint.Address.Street} ${savedPoint.Address.HouseNr}`,
          line_2: `${savedPoint.Name}`,
          postcode: savedPoint.Address.Zipcode,
          town: savedPoint.Address.City,
          geo_location: {
            latitude: savedPoint.Latitude,
            longitude: savedPoint.Longitude,
          },
          pickup_point_name: savedPoint.Name,
        };
        // append postnl store name if it's available in the remarks
        if(savedPoint.Address.Remark){
          const postNLAddressRemarkCategory = savedPoint.Address.Remark.split(".")[0].split(" ");
          const postNLStoreName = postNLAddressRemarkCategory[postNLAddressRemarkCategory.length - 1];
          savedPostNLAddress.line_3 = 'PostNL ' + postNLStoreName;
        }

        const trolleyStore = useTrolleyStore();

        if (!trolleyStore.trolley_id) throw new Error("trolley id not found");

        // dispatch checkout action via post nl address
        const res = await EcomTrolleyService.checkoutPostNL(
          trolleyStore.trolley_id,
          savedPostNLAddress,
          trolleyStore.trolley_session_token
        );

        if (!res || !res.data.address_id)
          throw new Error("post nl address checkout failed");

        this.currently_saved_post_nl_address_id = res.data.address_id;
        this.post_nl_address_ids.push(res.data.address_id);
      } catch (err) {
        useErrorHandler(err, "high");
        return err;
      } finally {
        postNLStore.post_nl_address_save_loading = false;
        postNLStore.post_nl_modal_visible = false;
      }
    },

    //-------------- create order after successful payment ------------------//
    async createTransaction(nonce: string, type: string, method = 'braintree'): Promise<void> {
   
      this.is_transaction_loading = true;
      const trolleyStore = useTrolleyStore();

      if(useAuthStore().is_guest && trolleyStore.trolley_session_token){
        await trolleyStore.convertToCustomer(false)
      }

      try {

        const params = this.buildTransactionParams(nonce, type,method);
        const options = {
          method: "post",
          show_notifications: false,
          params,
        };

        const response = await useAjaxEcom("/orders", options);

        if (response.success === false && response.data && response.data.error) {

          if(response.data.error.data && response.data.error.data.errors && response.data.error.data.errors.order){
            this.payment_error.gpay_error = response.data.error.data.errors.order[0];
          }else if(response.data.error.code === '409.99'
              && response.data.error.message === 'Duplicate Request'
              && method === 'account'){
            this.payment_error.gpay_error =
                "Please try with different PO number.";
          }else{
            this.payment_error.gpay_error =
                "Payment failed any amount deducted will be refunded";
          }

          useErrorHandler(response.data.error, "high");
          this.is_transaction_loading = false;
          return;
        }
        if (response.data.id) {
         trolleyStore.destroyTrolley();
         useMyOrderStore().processOrder(response);
      
         navigateTo(`/checkout/order-confirmation/${response.data.id}`);

        }
      } catch (err) {
        this.payment_error.gpay_error =
          "Payment failed any amount deducted will be refunded";
        this.is_transaction_loading = false;
        return error;
      }
    },

    //-------------- create order after successful payment ------------------//
    async handleProCardPayments() {

      this.createTransaction('__trade-payment-token__',null,'account');
    },

    buildTransactionParams(nonce: string, type: string, method = 'braintree') {
      const trolleyStore = useTrolleyStore();
      const params: TransactionParams = {
        trolley_id: trolleyStore.trolley_id,
        order_total: trolleyStore.final_order_total_amount,
        billing_address_id: this.billing_address.id,
        payment_type: type,
        payment_method: method,
        po_number: 'vikram test 1',
        payment_nonce: nonce,
        payment_device_data: "{\"correlation_id\":\"2cd01ec2-5fdd-4895-98e4-4d044e05\"}",
      };

      if (
        trolleyStore.trolley_line_items.Delivery ||
        trolleyStore.trolley_line_items.Directship
      ) {
        params.delivery_address_id = this.selected_delivery_address?.id;
      }
      if (trolleyStore.trolley_line_items.Collection.length) {
        params.collection_address_id =
          this.selected_collection_address?.address_id;
      }
      if (trolleyStore.trolley_line_items.NextDayCollection.length) {
        params.next_day_collection_address_id =
          this.selected_collection_address?.address_id;
      }
      if (method === 'account') {
        params.po_number = this.po_number;
      }

      return params;
    },

    async handlefallback() {
      const urlParams = new URLSearchParams(window.location.search);
      const btLpToken = urlParams.get("btLpToken");
      const route = useRoute();

      if (btLpToken) {
        // Check if btLpToken is not 'undefined'
        if (btLpToken !== "undefined") {
          let error_code = route.query["errorcode"] ?? null;
          if (error_code) {
            (this.error_messages =
              "Payment unsuccessful - please try a different payment method."),
              navigateTo({ path: "/checkout" });
          }
          this.payment_service = new PaymentService();
          await this.initializePaymentMethod(PaymentMethod.LocalPay);
        } else {
          navigateTo({ path: "/checkout" });
          this.payment_error.ideal_error =
            "Payment Failed btlp token undefined";
          useErrorHandler("btlp token undefined", "low");
        }
      }
    },

    resetError() {
      this.payment_error = {
        gpay_error: "",
        hosted_field_error: "",
        paypal_error: "",
        ideal_error: "",
      };
    },

    flashPaymentAccordingBackground() {
      this.flash_payment_according_bg = true;
      setTimeout(() => {
        this.flash_payment_according_bg = false;
      }, 1000);
    },

    resetPersistAddresses() {
      this.billing_address = null;
      this.post_nl_address_ids = [];
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCheckoutStore, import.meta.hot));
}
