let gtm = useGtm();

type EventOptions = {
    eventName: string,
    eventProperties: any,
    eventAction: string,
    eventLabel: string,
    eventInteraction: boolean,
    eventValue?: any,
}

/**
 * Event Options map differently as compared to what you might see in the GTM dash.
 * @param eventAction is "action"
 * @param eventProperties is "target"
 * @param eventInteraction is "interaction-type"
 * @param eventLabel is "event"
 * @param eventName is "target-properties"
 * @param eventValue is "value"
 * @
 */
export const useGtmEvent = (eventOptions: EventOptions) => {
    if (gtm === undefined) {
        gtm = useGtm();
    } else {
        gtm.trackEvent({
            event: eventOptions.eventName,
            category: eventOptions.eventProperties,
            action: eventOptions.eventAction,
            label: eventOptions.eventLabel,
            value: eventOptions.eventValue ?? "",
            noninteraction: eventOptions.eventInteraction,
        });
    }
}