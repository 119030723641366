import { acceptHMRUpdate, defineStore } from "pinia";
import { toast } from "vue3-toastify";

import "vue3-toastify/dist/index.css";
import { generateSessionID } from "~/utils/sessionId";

export const useRootStore = defineStore({

  id: "root",
  state: () => ({
    device:null,
    access_token:null,
    cache:{},
    cache_expired_at:null,
    authenticatedCustomer: null,
    device_id: null,
    session_id: null,
    access_token_interval_id: <ReturnType<typeof setInterval> | null>null,
    toast_ids: [],
    isAppHydrated: false,
    isIncVat: false,
    after_auth_intended_route: <string | null>null,
    ecom_error: {},
    is_ecom_failed_modal_visible: false,
    is_ecom_failed_modal_force_closed: false,
    ecom_failed_modal: false,
    page_background_color: "#FFFFFF",
    isReleaseModalVisible: false,
  }),
  persist: [
    {
      enabled: true,
      key: null,
      pick: [
        "isIncVat", "after_auth_intended_route"
      ],
    },
    {
      enabled: true,
      key: null,
      pick: ["cache","cache_expired_at"],
      storage: import.meta.client ? localStorage : undefined,
    },
  ],
  getters: {
    isAuthenticated: (state) => !!state.authenticatedCustomer,
    getDeviceId: (state) => state.device_id,
    getSessionId: (state) => state.session_id,
  },
  actions: {
    async processResponse(response) {
      if (response.errors || response.messages) {
        for (const toast_id of this.toast_ids) {
          await toast.remove(toast_id);
        }

        this.toast_ids = [];
      }

      if (response.errors && typeof response.errors[0] === "string") {
        await this.toastErrors(response.errors);
      }

      if (response.messages && typeof response.messages[0] === "string") {
        await this.toastSuccess(response.messages);
      }
    },
    async toastSuccess(messages, position = "top-center") {
      let message_html = this.getMessageHtml(messages);

      let message_duration = this.getMessageDuration(message_html);

      this.toast_ids[this.toast_ids.length] = toast(message_html, {
        containerClassName: "ts-toast",
        autoClose: message_duration,
        type: "success",
        position: position,
        dangerouslyHTMLString: true,
      }); // ToastOptions
    },
    async toastErrors(errors: unknown, position = "top-center") {
      let message_html = this.getMessageHtml(errors);

      let message_duration = this.getMessageDuration(message_html);

      this.toast_ids[this.toast_ids.length] = toast(message_html, {
        containerClassName: "ts-toast",
        autoClose: message_duration,
        type: "error",
        position,
        dangerouslyHTMLString: true,
      }); // ToastOptions
    },
    //----------------------------------------------------------
    // async initializeDeviceId() {
    //   if (!this.device_id) {
    //     this.device_id = await generateDeviceId();
    //
    //     console.log('device_id--->', this.device_id);
    //
    //   }
    // },
    setVersion() {
      const config = useRuntimeConfig();
      const release_version = useCookie('release_version');
      this.isReleaseModalVisible = false;
      release_version.value = config.public.version;
    },
    //----------------------------------------------------------
    async initializeSessionId() {
      if (!this.session_id) {
        this.session_id = await generateSessionID();
      }
    },
    //----------------------------------------------------------
    getMessageHtml(messages) {
      let i = 1;
      let list_html = "";

      if (Object.keys(messages).length > 1) {
        for (let k in messages) {
          list_html += i + ") " + messages[k] + "<br/>";
          i++;
        }
      } else {
        if (messages[0]) {
          list_html += messages[0];
        }
      }

      return list_html;
    },
    //----------------------------------------------------------
    getMessageDuration(message_html) {
      let duration = 3000;

      let chars = message_html.length;
      let readable = 10; // readable character per second.

      duration = duration * (chars / readable);

      return duration;
    },
    //----------------------------------------------------------
    clone: function (source) {
      return JSON.parse(JSON.stringify(source));
    },

    //---------------------------------------------------------------------

    //----------------------------------------------------------
    findInArrayByKey: function (array, key, value) {

      if(!Array.isArray(array))
      {
        return false;
      }

      let element = null;

      array.map(function(item, index) {

        if(item[key] == value)
        {
          element = item;
        }

      });

      return element;
    },

    //---------------------------------------------------------------------

    //----------------------------------------------------------
    isBuilderPreview: function (route = null) {

      if(!route){
         route = useRoute();
      }
      const isBuilderIoPreview = computed(() => route.query['builder.preview'] !== undefined)

      return isBuilderIoPreview.value;
    },
    resetCache: function() {
      this.cache = {};
      this.cache_expired_at = Date.now();
    },
    checkCacheExpiration() {
      if (this.cache_expired_at && (Date.now() - this.cache_expired_at) > 600000) {
        this.resetCache()
      }
    }

  },
});

// Pinia hot reload
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRootStore, import.meta.hot));
}
