<template>
  <div
      :id="props.id || 'card-' + randomId"
      :data-testid="
      props.dataTestid
        ? props.dataTestid + '-card-container'
        : '-card-container'
    "
      :class="
      twMerge(
        'shadow-md border rounded-xl overflow-hidden p-4 bg-natural-soft-white',
        props.heightFull && 'h-full',
        props.noShadow && 'shadow-none',
        props.noBorder && 'border-0',
        props.appendClass
      )
    "
  >
    <slot>
      <Blocks
      v-if="props.builderBlock"
          :parent="props.builderBlock?.id"
          :path="`component.options.blockContent`"
          :blocks="props.blockContent"
      />
      <div
          v-if="props.title || $slots.header"
          :class="
            twMerge(
              'pb-2',
              props.headerClass
            )
          "
          :data-testid="
            props.dataTestid
              ? props.dataTestid + '-card-header'
              : '-card-header'
          "
      >
        <slot name="header">
          <TsTypography as="h3" append-class="mb-0">
            {{ props.title }}
          </TsTypography>
        </slot>
      </div>
      <div
          v-if="props.content || $slots.content"
          :class="twMerge('py-2', props.bodyClass)"
          :data-testid="
            props.dataTestid
              ? props.dataTestid + '-card-content'
              : '-card-content'
          "
      >
        <slot name="content">
          <TsTypography
              as="p"
              append-class="mb-0"
          >
            {{ props.content }}
          </TsTypography>
        </slot>
      </div>
      <div
          v-if="props.footer || $slots.footer"
          :class="
            twMerge(
              'pt-2',
              props.footerClass
            )
          "
          :data-testid="
            props.dataTestid
              ? props.dataTestid + '-card-footer'
              : '-card-footer'
          "
      >
        <slot name="footer">
          {{ props.footer }}
        </slot>
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import {ref, onMounted} from "vue";
import {useRandomUUID} from "../../composables/useRandomUUID";
import {twMerge} from "tailwind-merge";
import { Blocks } from '@builder.io/sdk-vue';

type Props = {
  id?: string;
  builderBlock?: object;
  blockContent?: any[];
  title?: string;
  content?: string;
  footer?: string;
  appendClass?: string;
  headerClass?: string;
  bodyClass?: string;
  footerClass?: string;
  noShadow?: boolean;
  noBorder?: boolean;
  heightFull?: boolean;
  dataTestid?: string;
};

const props = defineProps<Props>();

const randomId = ref<string>("");
onMounted(() => {
  if (import.meta.client)
  randomId.value = useRandomUUID();
});
</script>
