<template>

  <TsCard type="vertical">
    <template class="text-left " #default>
 <img src="/images/cardImage.png">
    </template>

    <template v-slot:footer>
      <h5 class="mb-2 text-2xl font-bold text-gray-900 dark:text-white">Noteworthy technology acquisitions 2021</h5>

    </template>
  </TsCard>

</template>


<script>


</script>