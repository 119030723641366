<template>
  <div
      :data-testid="dataTestid"
      :class="
      $attrs.class ||
      twMerge(
        'flex flex-col max-sm:items-center max-md:bg-primary',
        appendClass
      )
    "
  >
    <TsTypography
        v-if="props.heading"
        :append-class="
        twMerge(
          'text-idle-white md:font-semibold max-sm:text-center mb-3 sm:mb-2 max-sm:!text-[24px]',
          props.headingClass
        )
      "
    >
      {{ props.heading || useTranslation("footerNewsletterHeading") }}
    </TsTypography>

    <TsTypography
        v-if="props.description"
        variant="sm"
        :append-class="
        twMerge(
          'text-idle-white md:font-normal max-sm:text-center mb-6 leading-relaxed',
          props.descriptionClass
        )
      "
    >
      {{ props.description || useTranslation("footerNewsletterDesc") }}
    </TsTypography>

    <form
        :data-testid="dataTestid + '-form'"
        role="form"
        @submit.prevent="handleNewsletterSubscribe"
        class="w-full sm:max-w-lg sm:flex"
    >
      <!-- Mobile -->
      <TsInput
          v-model="email"
          icon="lucide:mail"
          icon-position="left"
          input-class="text-idle-white placeholder:text-idle-white"
          icon-class="text-idle-white"
          :append-class="[
          'md:hidden sm:min-w-md',
          is_news_letter_input_focused && 'border-idle-white',
        ]"
          @focus="handleFocus"
          @blur="handleBlur"
          placeholder="Email"
          :data-testid="dataTestid + '-email-input'"
          required
      />

      <div class="md:hidden max-w-min mx-auto">
        <TsButton
          :data-testid="dataTestid + '-submit-button'"
          type="submit"
          :label="useTranslation('footerNewsletterButtonCTA','Abonneren')"
          append-class="bg-idle-white hover:bg-natural-soft-white text-primary border-0 font-medium max-sm:mt-5 sm:ml-4"
        />
      </div>

      <!-- Desktop -->
      <div class="flex flex-col w-full gap-1">
        <TsInputGroup append-class="max-md:hidden w-full">
          <TsInput
              v-model="email"
              @input="useStateModifier(newsletterSubscribeState, 'idle', '')"
              icon="lucide:mail"
              icon-position="left"
              icon-class="w-5 text-idle-white"
              input-class="text-idle-white placeholder:text-idle-white"
              :append-class="[
              'sm:min-w-md text-idle-white',
              is_news_letter_input_focused && 'border-idle-white',
            ]"
              @focus="handleFocus"
              @blur="handleBlur"
              :placeholder="placeholder"
              :data-testid="dataTestid + '-email-input'"
              required
          />
          <TsButton
            :data-testid="dataTestid + '-submit-button'"
            type="submit"
            :is-loading="newsletterSubscribeState.status === 'loading'"
            :label="useTranslation('footerNewsletterButtonCTA','Abonneren')"
            append-class="bg-idle-white hover:bg-natural-soft-white text-primary border-0 font-medium"
          />
        </TsInputGroup>
        <p
            v-if="newsletterSubscribeState.message"
            :class="
            twJoin(
              'text-sm font-semibold',
              newsletterSubscribeState.status === 'success'
                ? 'text-green-400'
                : newsletterSubscribeState.status === 'failed'
                ? 'text-red-300'
                : ''
            )
          "
        >
          {{ newsletterSubscribeState.message }}
        </p>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { twJoin, twMerge, type ClassNameValue } from "tailwind-merge";
import { ref } from "vue";
import * as z from "zod";
import { EcomService } from "../../services/ecom.service.js";
import type { APIState } from "../../types/api-state.type.js";
import { useStateModifier } from "../../composables/useStateModifier.js";

// const { t } = useI18n({
//   useScope: "local",
// });

const email = ref("");

const authStore = useAuthStore();
// const subscribeButton=useTranslation("footerNewsletterButtonCTA","Abonneren")
if (authStore.user) email.value = (authStore.user as any).email;

type Props = {
  subscribeIcon?: string;
  heading?: string;
  description?: string;
  placeholder?: string;
  descriptionClass?: ClassNameValue;
  headingClass?: ClassNameValue;
  appendClass?: ClassNameValue;
  dataTestid?: string;
  hasIcon?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  titleIcon: "lucide:tag",
  subscribeIcon: "bi:arrow-right",
  titleIconSize: "36",
  placeholder: "Email*",
  heading: "Always informed of our actions",
  description: "Keep me updated about products, news, tips and offers.",
  dataTestid: "organisms-newsletter",
  hasIcon: true,
});

const is_news_letter_input_focused = ref(false);

const handleFocus = (e: Event, isFocused: boolean) => {
  if (isFocused) is_news_letter_input_focused.value = true;
};

const handleBlur = (e: Event) => {
  is_news_letter_input_focused.value = false;
};

const newsletterSubscribeState = ref<APIState>({
  status: "idle",
});

async function handleNewsletterSubscribe() {
  useStateModifier(newsletterSubscribeState.value, "loading");

  try {
    const res = await EcomService.subscribeToNewsletter({
      email_address: email.value,
    });

    if (res.data && res.data.error) {
      useStateModifier(
          newsletterSubscribeState.value,
          "failed",
          res.data.error.data.errors.email_address[0]
      );
      throw new Error(res.data.error.data.errors.email_address[0]);
    } else {
      useStateModifier(
          newsletterSubscribeState.value,
          "success",
          "thank you for subscribing to our newsletter"
      );
    }
  } catch (err) {
    useErrorHandler(err, "low");
  }
}

const emit = defineEmits<{
  subscribe: any;
}>();
</script>
