import { ProductService } from "~/services/product.service";
import type {
  Product,
  ProductAttribute,
} from "~/types/ecom/product/product.type";
import type {
  ProductStockRequest,
  ProductStockResponse,
} from "~/types/ecom/product/stock.type";
import {
  getProductRating,
  getProductImages,
  getTopRightTagValues,
  getDetailedDescriptionHTML,
  getNumberOfReviews,
} from "~/utils/productAttributes";
import type { Branch } from "~/types/ecom/branch.type";
import { BuilderService } from "../services/builder.service";
import { toast } from "vue3-toastify";
interface VariationDropdown {
  name: string;
  code: number;
}

interface ProductImage {
  src: string;
  thumb: string;
  type: "image" | "video";
}

interface Taxonomy {
  text: string;
  link: string;
}

export const useProductStore = defineStore("product", {
  state: () => ({
    product_id: "" as string,
    product: {} as Product,
    variations_ids: [] as string[],
    product_stock: [] as ProductStockResponse[],
    products_data: [] as Product[] | any,
    compare_products_taxonomies_id: [] as any[],
    filtered_products_data: [] as any[],
    product_images: [] as ProductImage[],
    variation_dropdown: [] as VariationDropdown[] | null,
    builder_product_name: null as string | null,
    builder_product_images: [] as any[],
    is_product_loading: true as boolean,
    is_product_variation_loading: false as boolean,
    is_product_stock_loading: true as boolean,
    quantity_in_trolley: 0 as number,
    taxonomies: [] as Taxonomy[],
    builder_product: {} as any,
    is_confirmation_modal_visible: false as boolean,
    delivery_method: "" as string,
    notify_product_code: "" as string | undefined,
    notify_product_name: "" as string | undefined,
    notify_user_email: "" as string,
    is_stock_subscribe_loading:false,
    variation_slugs: [] as string[] | null,
    product_rating: null as any,
    promo_tags: null as any,
    product_description: null as any,
    product_review_count: null as any,
    is_variation_available: false as boolean,
    quantity_to_add: 1,
    delivery_stock: null as string | null,
    collection_stock: null as string | null,
    show_store_info: false as boolean,
    store_info: null as Branch | null,
    directship_stock: null as string | null,
    is_direct_ship: false as boolean,
    show_notify_me_cta: false as boolean,
    subscription_successful_modal_visible: false,
    subscribe_notification_modal_visible: false,
    subscribedProductDetails : [],
  }),

  getters: {
  },

  actions: {
    //----------------------------------------------------------------------------------------------
    async initialize(product_id?: string) {
      const promises = [];
      if (product_id) {
        this.product_id = product_id;
        this.is_confirmation_modal_visible = false;
      
      } else {
        const route = useRoute();
        this.setProductIdFromRoute(route.params.slug as string);
      }
      await Promise.all([this.fetchProductDetails(), this.fetchProductStocks()]);
    },

    //----------------------------------------------------------------------------------------------
    async fetchProductDetails() {
      if (!this.product_id) {
        throw new Error("Product ID is not set");
      }
      const route = useRoute();

      try {
        this.is_product_loading = true;
        await Promise.all([this.fetchProductData()]);

        if(this.product){
          await this.checkSlug(route.params.slug as string);
        }

      } catch (error) {
        if(!process.server){
          useErrorHandler(error, "critical");
        }
      } finally {
        this.is_product_loading = false;
      }
    },
    async fetchsubscribedProductsDetails()
    {
      const searchResultStore = useSearchResultStore();
      const accountStore = useAccountStore();
      let product_Service = new ProductService();
      if (accountStore.stock_notifications && accountStore.stock_notifications.length > 0) {
        const productIds = accountStore.stock_notifications?.map(notification=>Number(notification.product_id));
        if(productIds?.length>0)
      { 
        try{
          
         const response = await product_Service.getProducts(productIds)
         if(response)
         {
          this.subscribedProductDetails = accountStore.stock_notifications.map(notification=>{
          const productData = response.find(product=>product.code===notification.product_id);
          return {
            product_data:productData,
            created_at:notification.created_at,
          }
        })
        const productIdsString = accountStore.stock_notifications?.map(notification=>(notification.product_id));
        await searchResultStore.validateSavedProducts(productIdsString);
         }
         else{
         this.subscribedProductDetails=[];
          accountStore.stock_notifications = [];
         }}
         catch(err)
         {
          this.subscribedProductDetails = [];
          accountStore.stock_notifications = [];
          useErrorHandler(err);
         }
          
      }
      } 
    },
    //----------------------------------------------------------------------------------------------
    async getProductById(id?: number) {
      try {
        return await new ProductService().getProductByCode(id);
      } catch (err) {
        useErrorHandler(err);
        return err;
      }
    },

    //----------------------------------------------------------------------------------------------
    async setProductIdFromRoute(slug: string) {
      const parts = slug.split("-");
      this.product_id = parts.pop() || "";
      await this.getBuilderProduct(slug);
    },

    async checkSlug(slug: string) {
      if (this.getPartBeforeLastDash(slug) !== this.product.slug) {
        navigateTo(`/product/${this.product.slug}-${this.product.code}`);
      }
    },

    getPartBeforeLastDash(input) {
      // Find the position of the last dash
      const lastDashIndex = input.lastIndexOf("-");
      return input.substring(0, lastDashIndex);
    },
   

    //----------------------------------------------------------------------------------------------
    async getBuilderProduct(link: string) {
      const config = useRuntimeConfig();
      const api_key = config.public.builderApiKey;

      this.builder_product = await new BuilderService(api_key).getItem(
        "products",
        {
          urlPath: "/product/" + link,
        }
      );
      if (this.builder_product) {
        useHead({
          meta: [
            {
              name: "description",
              content:
                this.builder_product &&
                this.builder_product.data &&
                this.builder_product.data.seoMetaTags &&
                this.builder_product.data.seoMetaTags.description
                  ? this.builder_product.data.seoMetaTags.description
                  : this.product.name,
            },
          ],
        });

        this.processBuilderDetails();
      }
    },

    //----------------------------------------------------------------------------------------------
    processBuilderDetails() {
      if (this.builder_product.data.name)
        this.builder_product_name = this.builder_product.data.name;

      if (this.builder_product.data.images) {
        this.builder_product_images = this.builder_product.data.images.map(
          (image) => ({
            src: image.originalFile,
            thumb: image.thumbnail,
            type: "image" as const,
          })
        );
      }
      if (this.builder_product.data.videos.length) {
        let product_videos = this.builder_product.data.videos.map((video) => ({
          src: `https://www.youtube.com/embed/${this.extractYouTubeVideoId(
            video.originalFile
          )}`,
          thumb: video.thumbnail,
          type: "video" as const,
        }));

        this.builder_product_images = [
          ...this.builder_product_images,
          ...product_videos,
        ];
      }
    },

    //----------------------------------------------------------------------------------------------
    extractYouTubeVideoId(url: string) {
      // Regular expression to match YouTube video ID
      const regex = /[?&]v=([^&]+)/;
      const match = url.match(regex);
      return match ? match[1] : null;
    },

    //----------------------------------------------------------------------------------------------
    async fetchProductData(id?: number) {
      try {
        if (!id) id = this.product_id;
        this.product = await new ProductService().getProductByCode(id);
        await this.processProductData();
        this.is_product_loading = false;

        return this.product;
      } catch (err) {}
    },

    //----------------------------------------------------------------------------------------------
    async processProductData() {
      if (!this.product) {
        return;
      }

      this.is_variation_available = this.product.variations.length > 0;
      if (this.product.variations.length > 0) {
        this.variations_ids = this.product.variations;
        await this.fetchProductVariations();
      }
      this.setProductImages();
      this.createBreadcrumb();
      this.product_rating = getProductRating(this.product.other_attributes);
      this.promo_tags = getTopRightTagValues(this.product.other_attributes);
      this.product_description = getDetailedDescriptionHTML(
        this.product.other_attributes
      );
      this.product_review_count = getNumberOfReviews(
        this.product.other_attributes
      );
      this.is_variation_available = this.product.variations.length > 0;

      this.is_direct_ship = this.product.direct_ship;
    },

    //----------------------------------------------------------------------------------------------
    setProductImages() {
      const imageUrl = "https://cdn.toolstation.nl/images/140211-NL";
      const biggestImage = {
        src: this.product.images?.biggest,
        thumb: this.product.images?.med,
        type: "image" as const,
      };

      const productImageCodes = getProductImages(this.product.other_attributes);
      const otherImages = productImageCodes.map((code) => ({
        src: `${imageUrl}/800/${code}.jpg`,
        thumb: `${imageUrl}/250/${code}.jpg`,
        type: "image" as const,
      }));

      const productVideos =
        this.product.videos?.map((item) => ({
          src: `https://www.youtube.com/embed/${item.videoId}`,
          thumb: item.thumbnail,
          type: "video" as const,
          title: item.title,
        })) || [];

      this.product_images = [biggestImage, ...productVideos, ...otherImages];
    },

    //----------------------------------------------------------------------------------------------
    async fetchProductVariations() {
      try {
        this.is_product_variation_loading = true;
        let product_Service = new ProductService();
        const response = await product_Service.getProducts(this.variations_ids);
        this.variation_dropdown = null;
        this.variation_slugs = null;

        if (response) {
          this.variation_dropdown = response.map((variation: Product) => ({
            name: variation.full_name,
            code: variation.code,
          }));
          this.variation_slugs = response.map((variation: Product) => ({
            slug: variation.slug,
            code: variation.code,
          }));
          this.is_product_variation_loading = false;
        }
      } catch (error) {
        useErrorHandler(error, "high");

        this.is_product_variation_loading = false;
      }
    },

    //----------------------------------------------------------------------------------------------
    async fetchProductStocks() {
      try {
        this.is_product_stock_loading = true;
        this.show_notify_me_cta = false;
        const branchStore = useBranchStore();
        const branchId =
          branchStore.lastSavedBranch?.id || branchStore.defaultBranch?.id;
        this.product_stock = await new ProductService().getDeliveryDetails(
          this.product_id,
          branchId
        );
        this.delivery_stock = this.product_stock?.channels?.delivery?.stock;
        this.collection_stock = this.product_stock?.channels?.collection?.stock;
        this.directship_stock = this.product_stock?.channels?.directship?.stock;

        if(branchStore.is_branch_set && this.delivery_stock === 0 && this.collection_stock === 0){
            this.show_notify_me_cta = true;
        } 
        if(this.is_direct_ship && this.directship_stock ===0)
        {
          this.show_notify_me_cta = true;
        }
        if(!branchStore.is_branch_set && this.delivery_stock===0)
        {
          this.show_notify_me_cta = true;
        }
      } catch (error) {
        useErrorHandler(error, "high");
      } finally {
        this.is_product_stock_loading = false;
      }
    },

    //----------------------------------------------------------------------------------------------
    updateQuantityInTrolley() {
      const trolleyStore = useTrolleyStore();
      const product = trolleyStore.trolley_line_items?.Collection.find(
        (item) => item.product_code === this.product.code.toString()
      );
      this.quantity_in_trolley = product?.quantity || 0;
    },

    //----------------------------------------------------------------------------------------------
    createBreadcrumb() {
      this.taxonomies = this.product.taxonomies[0].map((item) => ({
        text: item.name,
        link:
          item.parent_id === 0
            ? `/categories/${item.name.toLowerCase().replaceAll(" ", "-")}/${
                item.id
              }`
            : `/categories/${item.name.toLowerCase().replaceAll(" ", "-")}-${
                item.parent_id
              }`,
      }));
      this.compare_products_taxonomies_id = this.product.taxonomies[0];
    },

    //----------------------------------------------------------------------------------------------
    async navigateToProductVariation(id: string, name: string) {
    
      const slug = this.variation_slugs.find(
        (variation) => variation.code === id
      )?.slug;

      navigateTo(`/product/${slug}-${id}`);
    },

    //----------------------------------------------------------------------------------------------
    async showAdscenceStore(id: string) {
      this.show_store_info = true;
      const branchStore = useBranchStore();
      if (id.length !== 2) {
        this.store_info = branchStore.lastSavedBranch;
        return;
      }
      //if its a valid store code doing further operations else showing last saved branch from branchStore
      let response: Branch | null = await branchStore.getBranchById(id);

      if (response?.address) {
        this.store_info = response;
        branchStore.currentBranchChoice = response;
        branchStore.currentSavedBranch = response;
        branchStore.lastSavedBranch = response;
        localStorage.setItem(
          "branchStore",
          JSON.stringify({ lastSavedBranch: response })
        );
      } else {
        this.store_info = branchStore.lastSavedBranch;
      }
    },

    async unSubscribeProduct(productId: string | undefined, showToast: boolean = true) {
      const authStore = useAuthStore();
      try {
        const customerId = authStore.user?.id;
        let response = await new ProductService().unSubscribeProduct(
          customerId,
          productId
        );
        if (showToast) {
          toast("Successfully unsubscribed", {
            autoClose: true,
            type: "success",
          });
        }

        if (response && response.success == false) {
          throw "Failed to unsubscribe the product.";
        }
        return true;
      } catch (error) {
        await useErrorHandler(error);
        return false;
      }
    },

    async subscribeProduct(productCode?: string) {
      const authStore = useAuthStore();
      const accountStore = useAccountStore();
      this.is_stock_subscribe_loading = true;

      let product_code: string = "";
      if (!productCode && !this.notify_product_code) {
        return;
      }
      product_code = productCode || this.notify_product_code;

      let alreadySubscribed = await this.getAllSubscribedProducts();
      if (
        alreadySubscribed.data.some((item:any) => item.product_id === product_code)
      ) {
        this.subscribe_notification_modal_visible = false;
        return;
      }

      try {
        const customerId = authStore.user?.id;
        let response =await new ProductService().subscribeProduct(
          customerId,
          product_code
        );
        if (response && response?.success === false) {
          throw new Error("Failed to subscribe to product stock");
        }

        return response;
      } catch (error) {
        await useErrorHandler(error);
        return false;
      }
      finally {
        this.is_stock_subscribe_loading = false;
      }
    },

    async getAllSubscribedProducts() {
      const authStore = useAuthStore();
      try {
        const customerId = authStore.user?.id;
        if(!customerId) return
        let response = await new ProductService().getsubscribedProducts(customerId);
        return response;
      } catch (error) {
        await useErrorHandler(error);
        return false;
      }
    },
    setProductCodeforNotification(code: string | undefined, name: string | undefined) {
      this.notify_product_code = code;
      this.notify_product_name = name;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProductStore, import.meta.hot));
}
