

/**
 * Algolia Service Class. 
 * POC: Anirudh Rath
 * Initial Author: Anirudh Rath
 */

import type { AlgoliaRequestOptions, AlgoliaSearchResponse, Filters, Hits, options, ProductDetails } from "~/types/algolia/algolia.type";

/**
 * AlgoliaService Class.
 * This is a global class and can only be initialized once. 
 * Use AlgoliaService.getInstance() to access this class and it's methods. 
 */
export class AlgoliaService {

    // create a constructor for default config options for Algolia

    /**
     * @param keyword @typeof string 
     * This method calls makes an API call to algolia to fetch autocomplete 
     * results based on searched keyword
    */
    async getSuggestions(
        keyword: string,
        requestOptions?: AlgoliaRequestOptions,
        searchIndex?: string,
    ): Promise<options[] | any> {
        try {
            /**
             * Getting data from Algolia
             */

            const algolia = useAlgoliaRef();
            const config = useRuntimeConfig();

            let queries = [
                {
                    indexName: config.public.algoliaIndexQuerySuggestions,
                    query: keyword,
                    params: requestOptions
                },
                {
                    indexName: config.public.algoliaIndexCategories,
                    query: keyword,
                    params: requestOptions
                },
                {
                    indexName: config.public.algoliaIndexProducts,
                    query: keyword,
                    params: requestOptions
                }
            ]

            const response = await algolia.multipleQueries(queries)

            let result_response = [];

            for(let i = 0; i<3 ; i++){
                result_response[i] = response.results[i].hits.map(item => ({
                    name: item.name??item.query,
                    image: !item.image ? null: this.isValidUrl(item.image)?item.image:'https:'+item.image, // map product image
                    priceRange: item.priceRange, // map price range
                    /**
                     * Slug and productCode are both used for navigation
                     */
                    slug: item.slug,
                    productCode: item.objectID,
                }));
            }

            return result_response;


        } catch (error: any) {
            /**
             * Handle Algolia error here
             */
            useErrorHandler(error, "low");
            throw error; // Optionally rethrow the error for handling in components or higher-level functions
        }
    }

    async getProductDetailsOnEnter(
        keyword: string,
        requestOptions?: AlgoliaRequestOptions ,
        searchIndex?: string,
    
    ): Promise<ProductDetails> {
        try {
            /**
             * Getting data from Algolia
             */
            const { search, result } = useAlgoliaSearch(searchIndex);
            await search({
                query: keyword,
                requestOptions: requestOptions,
            });

            const results = result.value;

            /**
             * Extract product_ids from the response
             */
            const productIDs = this.extractProductIds(result.value.hits);

            /**
             * Extract filters from the response
             */
            const filters = this.extractFilters(results);

            /**
             * Extract total number of results
             */
            const nbHits = this.extractNbHits(results);


            const nbPages = this.extractNbPages(results);

            const page = this.extractPage(results);

            const offset = this.extractOffset(results);



            return {
                productIDs,
                filters,
                nbHits,
                nbPages,
                page,
                offset
            }


        } catch (error: any) {
            /**
             * Handle Algolia error here
             */
            useErrorHandler(error, "low");
            throw error; // Optionally rethrow the error for handling in components or higher-level functions
        }
    }


    async getProductDetails(queries): Promise<ProductDetails> {
        try {
            /**
             * Getting data from Algolia
             */
            const algolia = useAlgoliaRef();
            const data = await algolia.multipleQueries(queries)

            const results = data.results[0];

            data.results.forEach(function (item,key) {
                if(key !== 0){
                    for (const [f_key, f_value] of Object.entries(item.facets)) {
                        results.facets[f_key] = f_value;
                    }
                }

            })

            /**
             * Extract product_ids from the response
             */
            const productIDs = this.extractProductIds(results.hits);

            /**
             * Extract filters from the response
             */
            const filters = this.extractFilters(results);

            /**
             * Extract total number of results
             */
            const nbHits = this.extractNbHits(results);


            const nbPages = this.extractNbPages(results);

            const page = this.extractPage(results);

            const offset = this.extractOffset(results);



            return {
                productIDs,
                filters,
                nbHits,
                nbPages,
                page,
                offset
            }


        } catch (error: any) {
            /**
             * Handle Algolia error here
             */
            useErrorHandler(error, "low");
            throw error; // Optionally rethrow the error for handling in components or higher-level functions
        }
    }

    async getCategoryDetailsOnEnter(
        requestOptions?: AlgoliaRequestOptions,
        searchIndex?: string,
    ): Promise<ProductDetails> {
        try {
            /**
             * Getting data from Algolia
             */
            const { search, result } = useAlgoliaSearch(searchIndex);
            await search({
                query: '',
                requestOptions: requestOptions,
            });

            const results = result.value;

            /**
             * Extract product_ids from the response
             */
            const productIDs = this.extractProductIds(result.value.hits);

            /**
             * Extract filters from the response
             */
            const filters = this.extractFilters(results);

            /**
             * Extract total number of results
             */
            const nbHits = this.extractNbHits(results);


            const nbPages = this.extractNbPages(results);

            const page = this.extractPage(results);

            const offset = this.extractOffset(results);



            return {
                productIDs,
                filters,
                nbHits,
                nbPages,
                page,
                offset
            }


        } catch (error: any) {
            /**
             * Handle Algolia error here
             */
            useErrorHandler(error, "low");
            throw error; // Optionally rethrow the error for handling in components or higher-level functions
        }
    }

    extractProductIds(algoliaHits: Hits): string[] {
        const productIDs = [];
        for (let index = 0; index < algoliaHits.length; index++) {
            productIDs.push(algoliaHits[index].objectID);
        }
        return productIDs;
    }

    extractFilters(algoliaSearchResponse: AlgoliaSearchResponse): Filters {
        return algoliaSearchResponse.facets;
    }

    extractNbHits(algoliaSearchResponse: AlgoliaSearchResponse): number {
        return algoliaSearchResponse.nbHits;
    }

    extractNbPages(algoliaSearchResponse: AlgoliaSearchResponse): number {
        return algoliaSearchResponse.nbPages;
    }

    extractPage(algoliaSearchResponse: AlgoliaSearchResponse): number {
        return algoliaSearchResponse.page;
    }

    extractOffset(algoliaSearchResponse: AlgoliaSearchResponse): number | undefined {
        return algoliaSearchResponse.offset;
    }

    isValidUrl(string) {
        let url_pattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
            '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
        return !!url_pattern.test(string);
    }
}





const algoliaIndexBuilder = () => {
    return 'TSNL_NEW_en_products'
}

