<template>
  <TsGrid isMobile>
    <TsColumn>
      <h2 class="title">
        We’d love to hear from you! <br/>
        Let’s get in touch
      </h2>
      <p class="sub-title">
        Lorem ipsum dolor sit amet consectetur. Lobortis hac mauris risus elementum erat facilisis. Viverra in
        et
        pellentesque a. Vel ut amet egestas id blandit id consectetur.
      </p>
    </TsColumn>

    <!-- -------   Form     ------ -->

    <TsRow>
      <TsColumn>
        <TsInput placeholder="First Name" required/>
      </TsColumn>
      <TsColumn>
        <TsInput placeholder="Last Name" required/>
      </TsColumn>
    </TsRow>

    <TsRow>
      <TsColumn>
        <TsInput placeholder="Phone number" type="number" icon='ph:phone' iconPosition="left" required/>
      </TsColumn>
      <TsColumn>
        <TsInput placeholder="Your mail" icon='fluent:mail-28-regular' iconPosition="left" type="email"
                 required/>
      </TsColumn>
    </TsRow>

    <TsRow>
      <TsColumn>
        <TsInput icon='mingcute:location-line' iconPosition="left" required placeholder="Address"/>
      </TsColumn>
    </TsRow>

    <TsRow>
      <TsColumn>
        <TsTextarea placeholder="How can we help?" required size="lg"/>
      </TsColumn>
    </TsRow>

    <TsColumn>
      <TsButton label="Submit" type="submit"/>
    </TsColumn>

    <TsColumn>
      <img src="/images/FormImage.png" alt="">
    </TsColumn>

  </TsGrid>
</template>
