<template>
  <div :id="`cta-card-${randomId}`"
       :data-testid="dataTestid"
       :class="props.class || twMerge(
      'flex items-center justify-center gap-2 flex-col md:flex-row',
      appendClass
  )">
    <slot name="content">
      <TsTypography v-if="props.content"
                    append-class="text-center lg:text-left mb-0 font-bold shrink max-md:mb-2 md:mr-2 text-idle-black"
      >
        {{ content }}
      </TsTypography>
    </slot>
    <div class="flex gap-2 min-w-max">
      <slot name="cta-buttons">
        <NuxtLink :data-testid="dataTestid+'-download-apple-app'" :to="props.appleStoreLink || 'https://apps.apple.com/nl/app/toolstation-bouwen-klussen/id1619948462'"
                  class="cursor-pointer flex items-center gap-0.5 border rounded-sm border-gray-500 p-2 bg-idle-black text-idle-white">
          <ClientOnly>
          <Icon name="ic:sharp-apple"
                class="size-6 mr-1"/>
          </ClientOnly>
          <div class="text-left">
            <p class="leading-none text-[8px]">{{useTranslation("downloadOn","Downloaden op de")}}</p>
            <p class="leading-tight tracking-wider text-sm font-semibold">App Store</p>
          </div>
        </NuxtLink>
        <NuxtLink :data-testid="dataTestid+'-download-google-play-app'" :to="props.googlePlayStoreLink || 'https://play.google.com/store/apps/details?id=com.toolstation.mobile_app.nl'"
                  class="cursor-pointer flex items-center gap-0.5 border rounded-sm border-gray-500 p-2 bg-idle-black text-idle-white">
          <NuxtImg
              class="size-6 mr-1"
              src="/icons/google-play.svg"
              alt="Goolge Play"
          />

          <div class="text-left">
            <p class="leading-none text-[8px]">{{useTranslation("getItOn","KRIJG HET AA")}}</p>
            <p lass="leading-tight tracking-wider text-sm font-semibold">Google Play</p>
          </div>
        </NuxtLink>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import {twMerge} from "tailwind-merge";
import {onMounted, ref} from "vue";
import {useRandomUUID} from "../../composables/useRandomUUID";

type Props = {
  content?: string,
  appleStoreLink?: string,
  googlePlayStoreLink?: string,
  appendClass?: string,
  class?: string,
  dataTestid?: string,
}


const props = defineProps<Props>()


const randomId = ref("");

onMounted(() => {
  randomId.value = useRandomUUID();
})

</script>
