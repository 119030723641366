import { useSavelistStore } from '#imports';
import { defineStore } from 'pinia';
import type { APIState } from "~/types/api-state.type";
import type { Product } from "~/types/ecom/product/product.type";
import { TsProvidedFacetFilters, ProductSortByIndexMap } from '~/types/algolia/algolia.type';


interface StockItem {
  product_code: string;
  stock_qty: string;
  site_id: string;
  is_direct_ship: boolean;
}

interface StockResponse {
  data: StockItem[];
}

enum ProductListingLayout {
  grid = 'grid',
  list = 'list'
}


export const useSearchResultStore = defineStore({
  id: "search-result",
  state: () => ({
    searchResultState: <APIState>{
      status: "idle",
      message: "Page is Idle",
    },
    productState: <APIState>{
      status: "idle",
      message: "Page is Idle",
    },

    mobileFilter: <Boolean>false,

    productStockCount: <number>0,

    products: <Product[]>[],

    totalProductsFound: <number>0,

    // totalProductsWithStockStatus: <any>([]),
    totalProductsWithstockStatus: <{ productID: string; inStock: boolean }[]>[],

    selectedFilterStateMobile: <string[]>[],

    productListinglayout: <string>"grid",

    // selectedFilters: <string[][]>([]),
    selectedFilterState: <string[]>[],
    // selectedValues: <Record<string, string[]>>({}), // Reactive object to track selected values
  }),
  actions: {
    async onLoad(
      route,
      source = "direct-search",
      canUpdateFilter = true,
      options = {},
      is_brand = false
    ) {
      let q = (route.query.q as string) || ("" as string);
      let page = route.query.page ? route.query.page - 1 : 0;
      let per_page = route.query.perPage ? route.query.perPage : 24;
      let sort_by = route.query.sortBy ? route.query.sortBy : "default";
      let rating = route.query.rating ? route.query.rating : 0;

      const algoliaStore = useAlgoliaStore();

      algoliaStore.route = route;

      algoliaStore.searchItem = q;
      algoliaStore.selectedRating = parseInt(rating);
      algoliaStore.offset = per_page;
      algoliaStore.sortBy = sort_by;
      if (
        q &&
        algoliaStore.lastTypedKeyword &&
        algoliaStore.lastTypedKeyword.length <= 0
      ) {
        algoliaStore.lastTypedKeyword = q;
      }

      if (is_brand) {
        algoliaStore.currentActiveBrand = route.params.brandname;
      }

      this.selectedFilterState = this.extractFiltersFromQuery(route.query);
      this.selectedFilterStateMobile = this.extractFiltersFromQuery(
        route.query
      );

      const extendedQueries = algoliaStore.getFilterQueries(
        route.query,
        options.key ?? ""
      );

      await algoliaStore.setRatingNumericFilters();

      const transformed_filters = this.transformSelectedFilters();

      await algoliaStore.searchProducts(
        is_brand ? route.params.brandname ?? "" : q,
        source,
        canUpdateFilter,
        transformed_filters,
        page,
        options.key ?? "",
        options.name ?? "",
        options.id ?? "",
        extendedQueries
      );
    },
    async onLoadPlp(route, type = "direct-search", options = null) {
      this.searchResultState.status = "idle";

      const algoliaStore = useAlgoliaStore();

      let q = (route.query.q as string) || ("" as string);
      let page = route.query.page ? route.query.page - 1 : 0;
      let per_page = route.query.perPage ? route.query.perPage : 24;
      let sort_by = route.query.sortBy ? route.query.sortBy : "default";
      let rating = route.query.rating ? route.query.rating : 0;
      let layout = route.query.layout ? route.query.layout :
        useRootStore().device && useRootStore().device.isMobile ? 'list' : 'grid';

      this.productListinglayout = layout;
      algoliaStore.searchItem = q;
      algoliaStore.selectedRating = parseInt(rating);
      algoliaStore.offset = per_page;
      algoliaStore.sortBy = sort_by;
      if (
        q &&
        algoliaStore.lastTypedKeyword &&
        algoliaStore.lastTypedKeyword.length <= 0
      ) {
        algoliaStore.lastTypedKeyword = q;
      }

      this.selectedFilterState = this.extractFiltersFromQuery(route.query);

      this.selectedFilterStateMobile = useRootStore().clone(this.selectedFilterState);

      algoliaStore.setRatingNumericFilters();

      const transformed_filters = this.transformSelectedFilters();

      this.searchResultState.status = "success";

      let facetFilters = useRootStore().clone(TsProvidedFacetFilters);

      let extended_facet = [];
      let extended_filter = "";

      if (type === "brand-search") {
        let index = facetFilters.indexOf("brand");

        if (index !== -1) {
          facetFilters.splice(index, 1);
        }

        if (options && options.category_page_id) {
          extended_filter = `categoryPageId:${options.category_page_id}`
        }


        transformed_filters.push(["brand:" + route.params.brandname]);

        extended_facet = ["brand:" + route.params.brandname];
      } else if (type === "category-search" && options) {
        algoliaStore.setCurrentFlowParams(
          type,
          options.category_name,
          options.category_page_id
        );

        extended_filter = algoliaStore.getActiveCategoryFilter(
          options.bake_category
        );
      }

      const extendedQueries = algoliaStore.getFilterQueries(
        route.query,
        extended_filter,
        extended_facet
      );

      const algolia = useAlgoliaRef();

      let queries = [
        {
          indexName: ProductSortByIndexMap[algoliaStore.sortBy ?? "default"],
          query: q,
          params: {
            hitsPerPage: algoliaStore.offset,
            minWordSizefor1Typo: 3,
            facets: facetFilters,
            facetFilters: transformed_filters,
            page: page,
            filters: extended_filter,
            numericFilters: algoliaStore.numericFilters ?? [],
          },
        },
      ];

      const data = await algolia.multipleQueries(
        queries.concat(extendedQueries)
      );
      const results = data.results[0];

      data.results.forEach(function (item, key) {
        if (key !== 0) {
          for (const [f_key, f_value] of Object.entries(item.facets)) {
            results.facets[f_key] = f_value;
          }
        }
      });

      const productIDs = [];
      for (let index = 0; index < results.hits.length; index++) {
        productIDs.push(results.hits[index].objectID);
      }

      if (productIDs.length === 0) {
        let search_query = q;

        if (type === "brand-search") {
          search_query = route.params.brandname;
        } else if (type === "category-search") {
          search_query = options.category_name;
        }

        useStateModifier(this.searchResultState, "failed", search_query, {
          searchQuery: search_query,
          resultFound: true,
        });
        return;
      }

      algoliaStore.productIDs = productIDs;
      algoliaStore.totalNumOfResults = algoliaStore.totalNumOfFilteredResults =
        results.nbHits;

      algoliaStore.filterCategoryToUpdateValue = {};
      if (transformed_filters.length > 1) {
        algoliaStore.extractSelectedFiltersValues(transformed_filters);
      }

      algoliaStore.nbPages = results.nbPages;
      algoliaStore.currentPage = results.page;
      algoliaStore.filters = {};

      if (results.facets.reviewFilterValue) {
        algoliaStore.review_count = useRootStore().clone(
          results.facets.reviewFilterValue
        );
        delete results.facets.reviewFilterValue;
      }

      delete results.facets.review;
      delete results.facets.reviewFilterValue;
      algoliaStore.updateFilters(results.facets);

      algoliaStore.createCategoriesFromFilters();


      this.searchResultState.status = "success";

      this.totalProductsWithstockStatus = [];
      this.products = [];

      useStateModifier(this.productState, "loading", "Loading...");
      this.productStockCount = 0;

      return true;
    },



    async getAlgoliaProducts() {
      const algoliaStore = useAlgoliaStore();
      const stockStore = useStockStore();

      if (algoliaStore.productIDs.length === 0) {
        return;
      }

      stockStore.productResponse = await stockStore.getAllProducts(
        algoliaStore.productIDs
      );

      return true;
    },
    async fetchStockOfProduct() {
      const algoliaStore = useAlgoliaStore();
      const stockStore = useStockStore();

      if (algoliaStore.productIDs.length === 0 || stockStore.productResponse.length === 0) {
        return;
      }

      this.products = await stockStore.fetchStockProduct(
        algoliaStore.productIDs,
        stockStore.productResponse
      );

      this.products = algoliaStore.productIDs
        .map((id) => this.products.find((product) => product.code === id))
        .filter((product): product is Product => !!product);

      // this.validateSavedProducts(algoliaStore.productIDs);
      //
      this.totalProductsFound = this.products.length;
      useStateModifier(this.productState, "success", "Fetch Complete");

      return true;
    },
    async warmSearchResults(product_ids: string[]) {
      const stockStore = useStockStore();
      try {
        this.totalProductsWithstockStatus = [];
        this.products = [];

        useStateModifier(this.productState, "loading", "Loading...");
        this.productStockCount = 0;

        /**
         * This is the old logic ---------------------------------------------------
         */
        // const updatedStock: StockResponse = await algoliaECOM.getProductStock(product_ids);

        /**
         * Extract Product IDs from the response and push to productStock state.
         */
        // this.extractProductIdFromStockWithStatus();

        /**
         *   Fetch details of that product stock.
         */
        // this.validateStockStatus(updatedStock, product_ids);

        // const productDetails = await algoliaECOM.getProducts(this.extractProductIdFromStockWithStatus());
        // this.products = productDetails.data;

        // this.updateProductDetailsWithStockStatus(productDetails, updatedStock);
        /**
         * This is the old logic --------------------------------------------------------
         */

        this.products = await stockStore.getStockForProducts(product_ids);

        this.products = product_ids
          .map((id) => this.products.find((product) => product.code === id))
          .filter((product): product is Product => !!product);

        this.validateSavedProducts(product_ids);

        if (this.products && this.products.length > 0) {
          this.totalProductsFound = this.products.length;

          useStateModifier(this.productState, "success", "Fetch Complete");
        } else {
          throw Error("Something went wrong");
        }
      } catch (error: any) {
        useErrorHandler(error, "low");
        useStateModifier(this.productState, "failed", "No products found!");
      }
    },

    async validateSavedProducts(product_ids: string[]) {
      const savelistStore = useSavelistStore();
      const accountStore = useAccountStore();
      const productStore = useProductStore();

      if (!savelistStore.fetching_in_progress) {
        await savelistStore.getUserSaveLists();
      }

      const consolidatedList = savelistStore.consolidated_product_code_list;


      this.products.forEach(product => {
        if (product_ids.includes(product.code)) {
          product.isSaved = consolidatedList.some((item: any) => item.product_code === product.code);
        } else {
          product.isSaved = false;
        }
      });

      if (accountStore.products) {
        accountStore.products.forEach(product => {
          if (product_ids.includes(product.code)) {
            product.isSaved = consolidatedList.some((item: any) => item.product_code === product.code);
          } else {
            product.isSaved = false;
          }
        });
      }
      
      if (productStore.products_data) {
        productStore.products_data.forEach(product => {
            if (product_ids.includes(+product.code)) {
                product.isSaved = consolidatedList.some((item: any) => item.product_code === product.code);
            } else {
                product.isSaved = false;
            }
        });
      }
      if (productStore.subscribedProductDetails.length>0) {
        productStore.subscribedProductDetails.forEach(product => {
            if (product_ids.includes(product.product_data.code)) {
                product.product_data.isSaved = consolidatedList.some((item: any) => item.product_code === product.product_data.code);
            } else {
                product.product_data.isSaved = false;
            }
        });
    }

    },

    resetFilters() {
      this.selectedFilterState = [];
      this.selectedFilterStateMobile = [];

      // this.selectedValues = {}
    },

    transformSelectedFilters(): string[][] {
      const transformedFilters: Record<string, string[]> = {};
      const { isMobile } = useDevice();
      if (!isMobile) {
        this.selectedFilterState.forEach((filter) => {
          const [category, value] = filter.split(":"); // Split by ":"

          // If the category doesn't exist in the transformedFilters object, create it
          if (!transformedFilters[category]) {
            transformedFilters[category] = [];
          }

          // Add the filter value to the corresponding category array
          transformedFilters[category].push(`${category}:${value}`);
        });
      } else {
        this.selectedFilterStateMobile.forEach((filter) => {
          const [category, value] = filter.split(":"); // Split by ":"

          // If the category doesn't exist in the transformedFilters object, create it
          if (!transformedFilters[category]) {
            transformedFilters[category] = [];
          }
          // Add the filter value to the corresponding category array
          transformedFilters[category].push(`${category}:${value}`);
        });
      }

      // Convert the object into the required format (an array of arrays)
      return Object.values(transformedFilters);
    },

    handleProductListingLayout(layout: ProductListingLayout) {
      //const route = useRouter();
      const route = useRoute();
      this.productListinglayout = layout;
      //route.push({ query: { layout: layout } });
      this.handleNavigate(route, 1, null, null, null, route.query.q, layout);

    },

    validateStockStatus(
      productStock: StockResponse,
      productIDFromAlgolia: string[]
    ) {
      // Check stock status
      this.totalProductsWithstockStatus = [];

      productIDFromAlgolia.forEach((productID) => {
        const inStock = productStock.data.some(
          (item) => item.product_code === productID
        );
        this.totalProductsWithstockStatus.push({
          productID,
          inStock,
        });
      });
    },

    extractProductIdFromStockWithStatus() {
      return this.totalProductsWithstockStatus.map(
        (status) => status.productID
      );
    },

    updateProductDetailsWithStockStatus(
      productDetails: any,
      updatedStock: StockResponse
    ) {
      this.products = productDetails.data.map((product: any) => {
        const status = this.totalProductsWithstockStatus.find(
          (s) => s.productID === product.code
        );
        const stockInfo = updatedStock.data.find(
          (stock) => stock.product_code === product.code
        );

        return {
          ...product,

          inStock: status ? status.inStock : false,
          // add stock count as well
          stockDetails: {
            delivery: stockInfo ? parseInt(stockInfo.stock_qty, 10) : 0,
          },
        };
      });
    },

    updateIsSavedStatus(productCode: string, status: boolean) {
      const productStore = useProductStore();
      const savelistStore = useSavelistStore();
      const accountStore = useAccountStore();
      const consolidatedList = savelistStore.consolidated_product_code_list;

      const product = this.products.find(product => product.code === productCode);
      if (product) {
        product.isSaved = status;
      }

      if (productStore.product) {
        productStore.product.isSaved = consolidatedList.some((item: any) => item.product_code === productStore.product.code);
      }

      const isSavedProducts = productStore.products_data.find(product => product.code === productCode);

      if (isSavedProducts) {
        isSavedProducts.isSaved = consolidatedList.some((item: any) => item.product_code === isSavedProducts.code);
      }

      // My Accounts > Orders realted Product 
      // find out individual product from account store products
      const accountStoreProduct = accountStore.products.find(product => product.code === productCode);

      if (accountStoreProduct) {
        accountStoreProduct.isSaved = consolidatedList.some((item: any) => item.product_code === accountStoreProduct.code);
      }

      const subscribedProduct = productStore.subscribedProductDetails.find(product=>product.product_data.code === productCode);
      if(subscribedProduct)
      {
        subscribedProduct.product_data.isSaved = consolidatedList.some((item: any) => item.product_code === subscribedProduct.product_data.code);
      }
  },

    removeFilterChip(key: any, value: any, route = null) {
      this.selectedFilterState = this.selectedFilterState.filter(
        (item) => item !== value
      );
      this.selectedFilterStateMobile = this.selectedFilterStateMobile.filter(
        (item) => item !== value
      );

      if (route) {
        this.handleNavigate(route);
      }
    },

    removeRatingChip(route) {
      const algoliaStore = useAlgoliaStore();

      algoliaStore.selectedRating = 0;

      this.handleNavigate(route, null, null, null, algoliaStore.selectedRating);
    },

    getFilterKey(value: string) {
      return value.split(":")[0];
    },

    getFilterValue(value: string) {
      return value.split(":")[1];
    },

    extractFiltersFromQuery(query) {
      let filters = [];

      for (const [key, value] of Object.entries(query)) {

        if (
          key !== "q" &&
          key !== "page" &&
          key !== "perPage" &&
          key !== "sortBy" &&
          key !== "rating" &&
          key !== "layout"
        ) {
          let filter_array = value.split(",");
          filter_array.forEach(function (filter_value) {
            filters[filters.length] = `${key}:${filter_value}`;
          });
        }
      }

      return filters;
    },

    handleNavigate(
      route,
      page = null,
      perPage = null,
      sortBy = null,
      rating = null,
      q = null,
      layout = null
    ) {
      const algoliaStore = useAlgoliaStore();

      let qValue = q ?? route.query.q;
      let pageValue = page ?? route.query.page;
      let sortByValue = sortBy ?? route.query.sortBy;
      let ratingValue = rating ?? route.query.rating;
      let perPageValue = perPage ?? route.query.perPage;
      let layoutValue = layout ?? route.query.layout;

      navigateTo({
        path: route.path,
        query: {
          ...(qValue ? { q: qValue } : {}),
          ...(pageValue && pageValue > 1 ? { page: pageValue } : {}),
          ...(perPageValue && perPageValue !== 24
            ? { perPage: perPageValue }
            : {}),
          ...(ratingValue && ratingValue > 0 ? { rating: ratingValue } : {}),
          ...(sortByValue && sortByValue !== "default"
            ? { sortBy: sortByValue }
            : {}),
          ...(layoutValue && layoutValue !== 'grid' ? { layout: layoutValue } : {}),
          ...(() => {
            return algoliaStore.buildSearchResultQuery();
          })(),
        },
      });
    },
    onFilterChange(isForceRun = false) {
      
      const route = useRoute();
      const device = useDevice();

      let algoliaStore = useAlgoliaStore();
      if (!device.isMobile || isForceRun) {
        this.handleNavigate(route, 1, null, null, algoliaStore.selectedRating);

        this.selectedFilterStateMobile = this.selectedFilterState;
        if (algoliaStore.currentPage > 1) {
          algoliaStore.currentPage = 0;
        }
        this.mobileFilter = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Optional for smooth scrolling
        });
      }
    },

  },
  getters: {
    getProducts(state) {
      return state.products;
    },
    getProductState(state) {
      return state.productState;
    },
    getSearchResultState(state) {
      return state.searchResultState;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchResultStore, import.meta.hot))
}