import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import monetate_45global from "/vercel/path0/middleware/monetate.global.ts";
import redirect_45global from "/vercel/path0/middleware/redirect.global.ts";
import release_45version_45check_45global from "/vercel/path0/middleware/releaseVersionCheck.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  monetate_45global,
  redirect_45global,
  release_45version_45check_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "checkout-protect": () => import("/vercel/path0/middleware/checkout-protect.ts"),
  "is-logged-in": () => import("/vercel/path0/middleware/isLoggedIn.ts"),
  protected: () => import("/vercel/path0/middleware/protected.ts")
}