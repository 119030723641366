<script lang="ts" setup>
import { onMounted, nextTick, onUnmounted } from "vue";
import { initFlowbite } from "flowbite";

const { locale } = useI18n();

const rootStore = useRootStore();
rootStore.device = useDevice();

const builderStore = useBuilderStore();


function confirmTrolleyExists(){
  const trolleyStore = useTrolleyStore();
  if(!trolleyStore.total_products || trolleyStore.total_products === 0) return;
  const authStore = useAuthStore();
  const trolleyExists = trolleyStore.trolley_id && (authStore.is_authenticated || trolleyStore.trolley_session_token)
  if(!trolleyExists) trolleyStore.destroyTrolley();
}

// initialize all services
onBeforeMount(() => {
  const branchStore = useBranchStore();
  branchStore.initialize();
});

// initialize flowbite components based on data attribute selectors
onMounted(async () => {
  // await rootStore.initializeDeviceId();
  await rootStore.initializeSessionId();

  nextTick(() => {
    initFlowbite();
  });
  
  confirmTrolleyExists();
  
  rootStore.isAppHydrated = true;

  builderStore.init();
});

onUnmounted(() => {
  let interval_id = rootStore.access_token_interval_id;
  /* clear the set interval from plugin [refresh-access-token] */
  if (interval_id) clearInterval(interval_id);
});

useHead({
  htmlAttrs: {
    lang: locale.value
  }
});
</script>

<template>
  <div>
    <TsSeoMetaTags />
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <TsModalRelease />
      <TsModalEcomError />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
