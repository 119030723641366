<template>
  <nav
    class="lg:sticky top-0 z-30 bg-white border-b border-[#C7E4FF]"
    data-testid="header"
  >
    <div class="bg-primary rounded-b-xl sm:rounded-none">
      <div class="max-w-screen-xl mx-auto px-4 py-2">
        <TsRow class="bg-primary justify-between items-center" isMultiline>
          <TsColumn dataTestid="column-header" class="is-5 lg:is-2 shrink-0">
            <NuxtLink :to="localePath('/')" class="block max-w-max">
              <NuxtImg
                class="cursor-pointer"
                src="/images/logos/toolstation-logo-halo.svg"
                alt="logo"
                height="44"
                width="179"
                data-testid="header-logo"
              />
            </NuxtLink>
          </TsColumn>
          <TsColumn
            dataTestid="column-algolia"
            class="max-lg:is-12 lg:max-w-[50%] py-3 lg:py-0 order-3 lg:order-2"
          >
            <!-- <TsAlgoliaDebugger ></TsAlgoliaDebugger> -->
            <!-- history: Search History Options -->
            <!-- suggestions: Normal Search Suggestions -->
            <!-- popular: Popular Search Suggestions -->

            <TsAutoCompleteAlgolia
              data-testid="autocomplete-algolia"
              @clear="() => algoliaStore.handleClearSearchInput()"
              @input="(keyword : string) => algoliaStore.getAlgoliaSuggestions(keyword)"
              @enter="(keyword: string, dropdownVisible: boolean) =>
               algoliaStore.redirectOnEnterClick(keyword)"
              @delete="(option: options) => algoliaStore.delteSearchHistoryItem(option)"
              :last-searched-keyword="algoliaStore.lastTypedKeyword"
              :placeholder="
                useTranslation('searchPlaceholder', 'Waar bent u naar op zoek?')
              "
              v-model="algoliaStore.searchItem"
              :suggestions="algoliaStore.suggestions()"
            >
            </TsAutoCompleteAlgolia>
            <!-- <TsStoreDebugger :store-state="algoliaStore.$state"></TsStoreDebugger> -->
          </TsColumn>
          <TsColumn
            dataTestid="column-selectbox"
            class="max-sm:is-7 order-2 lg:order-3 grow-0 lg:py-0"
          >
            <TsRow
              append-class="items-center justify-end gap-2 sm:gap-6 *:grow-0 *:text-idle-white py-0"
            >
              <TsColumn v-if="!isMobile">
                <TsSelectBox
                  :options="locales"
                  v-model="selectedLocale"
                  option-label="name"
                  @select="onSelectLocale"
                  rootClass="bg-transparent whitespace-nowrap text-idle-white"
                  input-class="text-idle-white"
                  dropdown-class="w-[220px] !max-w-[250px]"
                >
                  <template #trigger>
                    <div
                      class="hover:text-natural-light-grey cursor-pointer flex flex-col items-end lg:items-center lg:space-y-2"
                    >
                      <TsIcon
                        :size="isDesktop ? '22' : '28'"
                        :name="countryFlagCode(locale)"
                      ></TsIcon>

                      <TsTypography
                        as="span"
                        variant="sm"
                        appendClass="leading-[12px] hidden lg:block whitespace-nowrap text-idle-white"
                        data-testid="header-language-default"
                      >
                        {{ countryCode(locale) }}
                      </TsTypography>
                    </div>
                  </template>

                  <template #option="{ option }">
                    <div class="flex items-center space-x-3">
                      <Icon :name="countryFlagCode(option.code)"></Icon>

                      <TsTypography
                        as="span"
                        variant="sm"
                        append-class="leading-[12px] text-idle-black mb-0"
                        :data-testid="`header-language-${option.name}`"
                        >{{ typeof option === "object" ? option.name : option }}
                      </TsTypography>
                    </div>
                  </template>
                </TsSelectBox>
              </TsColumn>
              <TsColumn dataTestid="column-saveList">
                <NuxtLink
                  :data-testid="
                    props.dataTestid
                      ? props.dataTestid + '_header-link-save-list'
                      : '_header-link-save-list'
                  "
                  :to="localePath('/account/save-list')"
                  class="hover:text-natural-light-grey cursor-pointer flex flex-col items-end lg:items-center lg:space-y-2"
                >
                  <TsIcon
                    name="gg:heart"
                    :size="isDesktop ? '22' : '28'"
                  ></TsIcon>
                  <TsTypography
                    as="span"
                    variant="sm"
                    appendClass="leading-[12px] text-idle-white hidden lg:block whitespace-nowrap"
                  >
                    {{ useTranslation("saveList", "Save List") }}
                  </TsTypography>
                </NuxtLink>
              </TsColumn>

              <TsColumn dataTestid="column-auth">
                <NuxtLink
                  v-if="authStore.is_authenticated"
                  :data-testid="props.dataTestid + '_header-link-logout'"
                  :to="localePath('/account')"
                  class="hover:text-natural-light-grey cursor-pointer flex flex-col items-end lg:items-center lg:space-y-2"
                >
                  <TsIcon
                    name="tdesign:user"
                    :size="isDesktop ? '22' : '28'"
                  ></TsIcon>
                  <TsTypography
                    as="span"
                    variant="sm"
                    appendClass="leading-[12px] text-idle-white hidden lg:block whitespace-nowrap"
                  >
                    {{ useTranslation("myAccount", "My Account") }}
                  </TsTypography>
                </NuxtLink>
                <NuxtLink
                  v-else
                  :data-testid="props.dataTestid + '_header-link-login'"
                  :to="localePath('/auth/signin')"
                  class="hover:text-natural-light-grey cursor-pointer flex flex-col items-end lg:items-center lg:space-y-2"
                >
                  <TsIcon
                    name="mdi:sign-in"
                    :size="isDesktop ? '22' : '28'"
                  ></TsIcon>
                  <TsTypography
                    as="span"
                    variant="sm"
                    appendClass="leading-[12px] text-idle-white hidden lg:block whitespace-nowrap"
                  >
                    {{ useTranslation("signIn", "Sign In") }}
                  </TsTypography>
                </NuxtLink>
              </TsColumn>

              <TsColumn dataTestid="column-cart">
                <NuxtLink
                  :data-testid="
                    props.dataTestid
                      ? props.dataTestid + '_header-cart'
                      : '_header-link-cart'
                  "
                  :to="localePath('/trolley')"
                  class="hover:text-natural-light-grey cursor-pointer flex relative flex-col items-end lg:items-center lg:space-y-2"
                >
                  <ClientOnly>
                    <!-- trolley item count -->
                    <span
                      v-if="trolleyStore.total_products"
                      class="absolute grid place-content-center -top-0.5 border border-white lg:right-1.5 bg-primary-hover rounded-full size-[16px] lg:size-[14px] p-0.5 leading-[8px] text-white text-[10px] font-semibold"
                    >
                      {{
                        trolleyStore.total_products > 9
                          ? "9+"
                          : trolleyStore.total_products
                      }}
                    </span>
                  </ClientOnly>
                  <TsIcon
                    name="i-ci-shopping-cart-02"
                    :size="isDesktop ? '22' : '28'"
                    class="!mt-0"
                  ></TsIcon>
                  <TsTypography
                    as="span"
                    variant="sm"
                    appendClass="leading-[12px] text-idle-white hidden lg:block"
                  >
                    {{ useTranslation("trolley", "Trolley") }}
                  </TsTypography>
                </NuxtLink>
              </TsColumn>
              <!-- <TsColumn
                dataTestid="column-hamburger"
                class="text-right lg:text-center block lg:hidden"
              >
                <TsIcon
                  class="hover:text-natural-light-grey cursor-pointer"
                  name="radix-icons:hamburger-menu"
                  :size="isDesktop ? '22' : '28'"
                  dataTestid="icon-hamburger"
                />
              </TsColumn> -->
              <TsColumn class="block lg:hidden">
                <div
                  data-testid="column-hamburger"
                  @click="openMobileMenu"
                  class="text-right lg:text-center block lg:hidden"
                >
                  <TsIcon
                    class="hover:text-natural-light-grey cursor-pointer"
                    name="radix-icons:hamburger-menu"
                    :size="isDesktop ? '22' : '28'"
                    dataTestid="icon-hamburger"
                  />
                </div>

                <TsDrawer
                  v-model:visible="menuStore.show_mobile_menu_drawer"
                  data-testid="previewDirectory"
                  position="right"
                  appendClass="w-[95%] h-full top-3 rounded-tl-lg rounded-bl-lg hide-scroll-bar px-0"
                  content-class="p-0"
                >
                  <template #content="{ hideDrawer }">
                    <TsDrawerMobileMenu @close="hideDrawer()" />
                  </template>
                </TsDrawer>
              </TsColumn>
            </TsRow>
          </TsColumn>
        </TsRow>
      </div>
    </div>
    <div class="shadow-[4px_3px_9.6px_0px_#8FBFEB1C]">
      <div class="max-w-screen-xl mx-auto px-4 py-0.5 md:py-1">
        <TsRow class="items-center" isMultiline>
          <TsColumn dataTestid="column-categories" class="is-7 hidden lg:block">
            <TsRow class="lg:space-x-5 px-2">
              <TsTooltip
                v-model:visible="menuStore.show_category_dropdown"
                position="bottom"
                mode="tooltip"
                noArrow
                closeIcon=""
                rootClass="!inset-0 translate-x-0 translate-y-0 max-h-[80vh] !top-0 z-100 max-w-screen-xl !mx-auto shadow-none border-0 !p-0 bg-transparent"
              >
                <TsTypography appendClass="text-black whitespace-nowrap" variant="sm">
                  <span data-testid="header-categories-text">
                    {{ useTranslation("categories", "Categories") }}
                  </span>
                  <Icon
                    v-if="menuStore.categories_state.status === 'success'"
                    name="mingcute:down-line"
                    :size="isDesktop ? '22' : '28'"
                  />
                  <Icon
                    v-if="menuStore.categories_state.status !== 'success'"
                    name="line-md:loading-twotone-loop"
                    :size="isDesktop ? '22' : '28'"
                  />
                </TsTypography>

                <template
                  #tooltip-content
                  v-if="menuStore.categories_state.status === 'success'"
                >
                  <!-- <div class="max-h-[70vh] py-0 overflow-y-auto overflow-x-hidden mx-auto"> -->
                  <TsMegaMenu :data="menuStore.categories.data" />

                  <!-- </div> -->
                </template>
              </TsTooltip>
              <TsTooltip
                ref="brandTooltip"
                position="bottom"
                mode="tooltip"
                noArrow
                v-model:visible="isBrandTooltipVisible"
                closeIcon=""
                rootClass="!inset-0 translate-x-0 translate-y-0 max-h-[80vh] !top-0 z-100 max-w-screen-xl !mx-auto shadow-none border-0 !p-0 bg-transparent"
              >
                <TsTypography appendClass="text-black whitespace-nowrap" variant="sm">
                  <span data-testid="header-brands-text">
                    {{ useTranslation("brands", "Brands") }}</span
                  >
                  <Icon
                    name="mingcute:down-line"
                    :size="isDesktop ? '22' : '28'"
                  />
                </TsTypography>

                <template #tooltip-content>
                  <!-- <div class="max-h-[70vh] py-0 overflow-y-auto overflow-x-hidden mx-auto"> -->
                  <TsTopBrandMenu @onClose="isBrandTooltipVisible = false" />
                  <!-- </div> -->
                </template>
              </TsTooltip>
              <span :to="localePath('/deals')" class="text-[#C7E4FF]"> | </span>
              <NuxtLink
                :to="localePath('/deals')"
                class="cursor-pointer hover:text-primary font-normal text-sm"
                data-testid="header-deals-link"
              >
                {{ useTranslation("deals", "Deals") }}
              </NuxtLink>
              <NuxtLink
                :to="localePath('/new-launch')"
                class="cursor-pointer hover:text-primary font-normal text-sm whitespace-nowrap"
                data-testid="header-new-launch-link"
              >
                {{ useTranslation("newLaunch", "New Launch") }}
              </NuxtLink>
              <NuxtLink
                :to="localePath('/pro-card')"
                class="cursor-pointer hover:text-primary font-normal text-sm whitespace-nowrap"
                data-testid="header-procard-link"
              >
                {{ useTranslation("proCard", "Pro Card") }}
              </NuxtLink>
              <!--              <NuxtLink :prefetch="false" :to="localePath('/ui')"-->
              <!--                        class="cursor-pointer hover:text-primary font-normal whitespace-nowrap" data-testid="header-style-guide-link">-->
              <!--                {{ useTranslation("styleGuide", "Style Guide") }}-->
              <!--              </NuxtLink>-->
            </TsRow>
          </TsColumn>
          <TsColumn
            class="is-12 lg:is-5 text-right md:py-0"
            dataTestid="column-vat"
          >
            <div
              class="flex justify-between items-center lg:justify-end space-x-6 max-md:py-0"
            >
              <TsTypography
                v-if="!isMobile"
                as="div"
                variant="sm"
                appendClass="flex text-primary items-center lg:justify-end space-x-2 mb-0"
              >
                <span class="font-bold" data-testid="header-vat-text"
                  >{{ useTranslation("vatex", "VAT") }}
                </span>
                <span
                  :class="!rootStore.isIncVat && 'font-bold'"
                  data-testid="header-exculded-text"
                  >EX</span
                >
                <TsToggle
                  variant="switchVat"
                  size="md"
                  v-model="rootStore.isIncVat"
                  :data-testid="
                    rootStore.isIncVat
                      ? props.dataTestid + '_header-vat-toggle-inc'
                      : props.dataTestid + '_header-vat-toggle-ex'
                  "
                />
                <span
                  :class="rootStore.isIncVat && 'font-bold'"
                  data-testid="header-includedvat-text"
                  >INC</span
                >
              </TsTypography>

              <!-- Branch Selector pop up -->
              <TsTag
                variant="info"
                @click="branchStore.branchSelectorModalVisible = true"
                rounded
                append-class="cursor-pointer select-none hover:bg-info/20 transition-colors font-semibold text-primary max-md:bg-transparent bg-[#065CAB1A] max-md:p-0"
              >
                  <TsIcon name="ep:location" size="20" />
                  <TsTypography weight="semibold" append-class="md:text-xs text-primary" data-testid="header-branch-text" as="span">
                  {{
                      (branchStore.lastSavedBranch && branchStore.lastSavedBranch.name) ?
                          branchStore.lastSavedBranch.name:
                      useTranslation("selectNearBranch","Select your nearest branch")
                    }}
                  </TsTypography>

              </TsTag>
            </div>
          </TsColumn>
        </TsRow>
      </div>
    </div>
  </nav>

  <ClientOnly>
    <TsModalBranchSelector
      v-if="rootStore.isAppHydrated"
      v-model:visible="branchStore.branchSelectorModalVisible"
      id="header-branch-selector"
      data-testid="header-branch-selector"
    />
  </ClientOnly>
</template>

<script setup lang="ts">
import { useRandomUUID } from "../composables/useRandomUUID";
import { ref } from "vue";
import type { options } from "~/types/algolia/algolia.type";

const { isMobile, isDesktop } = useDevice();

const { locales, locale, setLocale } = useI18n();
const localePath = useLocalePath();

const authStore = useAuthStore();

const isBrandTooltipVisible = ref(false);

const random_id = ref("");
const selectedLocale = ref("en");

const rootStore = useRootStore();
const builderStore = useBuilderStore();
const algoliaStore = useAlgoliaStore();
const branchStore = useBranchStore();
const menuStore = useMenuStore();
const trolleyStore = useTrolleyStore();

watch(selectedLocale, () => {
  setLocale(selectedLocale.value.code);
});

function openMobileMenu() {
  menuStore.show_mobile_menu_drawer = true;
  menuStore.current_menu = "main-menu";
}

const router = useRouter();

router.beforeEach(() => {
  menuStore.show_category_dropdown = false;
});

const countryFlagCode = (countryCode: string, index: number = 1) => {
  const base = "circle-flags:";

  if (countryCode) {
    if (countryCode === "en-US") {
      return base + "uk";
    }
    const countryCodeNew = countryCode.split("-")[index].toLowerCase();
    return base + countryCodeNew;
  }
};

const countryCode = (countryCode: string, index: number = 0) => {
  if (countryCode) {
    let locale_item = rootStore.findInArrayByKey(
      locales.value,
      "code",
      countryCode
    );

    return locale_item.display_name;
  }
};

type Props = {
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {});
const selectedProduct = ref();

onMounted(async () => {
  random_id.value = useRandomUUID();

  await getCategories();
  await builderStore.fetchTopBrands();
});

async function getCategories() {
  if (menuStore.categories && menuStore.categories.data) {
    menuStore.categories_state.status = "success";
    return false;
  }
  await menuStore.getCategories(true);
}

async function onSelectLocale(
  option,
  visibleOptions,
  isLoading,
  searchQuery,
  activeOptionIndex,
  dropdown
) {
  dropdown.hide();
}
</script>
